import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import { Modal, Form, Input } from 'antd';
import constants from 'commons/constants';
import { addOrUpdateNavisapo } from 'services';
import { toast } from 'react-toastify';
import useStateCallback from 'use-state-with-callback';
import { get } from 'lodash';
import { ImageUpload } from 'components';

const NavisapoAddOrEditModal = forwardRef((props, ref) => {
  const inputRef = useRef();
  const uploadRef = useRef();
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const { onUpdated } = props;
  const [visible, setVisible] = useStateCallback(false, (visible) => {
    if (!visible) setNavisapo(null);
  });

  const [navisapo, setNavisapo] = useStateCallback(null, (navisapo) => {
    const { id, image, name } = navisapo || {};
    form.setFieldsValue({
      id,
      name,
      image,
    });
  });

  const [loading, setLoading] = useState(false);
  const isAddNew = get(navisapo, 'id', null) === null;

  const show = (navisapo) => {
    setNavisapo(navisapo);
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
    form.resetFields(); // reset form
    uploadRef.current.resetStates();
  };

  const onOK = async () => {
    const { id, name } = form.getFieldsValue([
      'id',
      'name',
      'image'
    ]);

    form
      .validateFields()
      .then(() => uploadRef.current.upload(constants.UPLOAD_PATH.NAVISAPO))
      .then((url) => {
        setLoading(true);
        return addOrUpdateNavisapo({
          id,
          name,
          image: url,
          isDeleted: false,
        });
      })
      .then(() => {
        let msg = id ? '設定が更新されました！' : '設定が追加されました！';
        toast.success(msg);
        setLoading(false);
        onUpdated && onUpdated();
        hide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
        hide();
      });
  };

  return (
    <div ref={inputRef}>
      <Modal
        forceRender={true}
        title={isAddNew ? '設定を追加' : '編集の設定'}
        centered
        visible={visible}
        okText={constants.YES}
        cancelText={constants.CANCEL}
        okButtonProps={{ form: 'cpn-form', key: 'submit', htmlType: 'submit' }}
        confirmLoading={loading}
        onCancel={hide}
      >
        <Form
          form={form}
          name='cpn-form'
          onFinish={onOK}
          labelCol={{ span: 7 }}
          labelAlign='left'
        >
          <ImageUpload
            ref={uploadRef}
            url={navisapo?.image}
            wraperStyle={{
              borderRadius: 0,
            }}
          />
          <Form.Item
            name='name'
            label='名前'
            rules={[{ required: true, message: '名前は必須です。' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default NavisapoAddOrEditModal;
