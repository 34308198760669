import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Form,
  Input,
  Empty,
  Checkbox,
  Tag,
} from 'antd';
import {
  DeleteFilled,
  ExclamationCircleOutlined,
  SearchOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { get, uniq } from 'lodash';
import { CSVExportButton, Pagination, CSVImport } from 'components';
import { getThemes } from 'redux-store/themes/action';
import constants from 'commons/constants';
import ThemeAddOrEditModal from './ThemeAddOrEditModal';
import { toast } from 'react-toastify';
import {
  deleteThemes,
  getAllThemeByCondition,
  addThemeFromCSV,
} from 'services';
import './style.scss';
import moment from 'moment';

function ThemeManagement() {
  const today = moment(new Date()).format(constants.DATE_FILE_FORMAT);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const editModalRef = useRef();

  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowKeys, setSelectedRows] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [fetchedExportData, setFetchedExportData] = useState(false);
  const [page, setPage] = useState(1);

  const {
    data: themeData,
    loading: getThemeLoading,
    canLoadMore,
  } = useSelector((state) => get(state, 'theme.themes', {}));

  /**
   * Get users
   * @param {*} formValues
   */

  const getThemeData = (formValues) => {
    const { term = '' } = formValues;
    dispatch(getThemes({ page, themeText: term.trim() }));
  };

  useEffect(() => {
    form.submit();
  }, [page]);

  useEffect(() => {
    if (themeData.length <= 0 && !canLoadMore) setSelectAll(false);

    const selected = selectedRowKeys;
    const uids = themeData.map((u) => u.id);

    if (selectAll) {
      const selectedRowKeys = uniq([...selected, ...uids]);
      setSelectedRows(selectedRowKeys);
    }
    setSelectedRows([]);
  }, [themeData]);

  const onDeleteThemeCode = () => {
    deleteThemes(selectedRowKeys, selectAll)
      .then(() => {
        toast.success('会社は削除されました。');
        form.submit();
      })
      .catch((err) => {
        toast.error(err);
        console.log(err);
      });
  };

  const confirmDelete = () =>
    Modal.confirm({
      centered: true,
      title: constants.MODAL.CONFIRM,
      icon: <ExclamationCircleOutlined />,
      content: 'テーマを削除してもよろしいですか？',
      okText: constants.YES,
      cancelText: constants.CANCEL,
      onOk: onDeleteThemeCode,
    });

  const onSelectAllChanged = (e) => {
    const checked = e.target.checked;
    const rows = themeData.map((u) => u.id);

    setSelectAll(checked);
    if (checked) {
      setSelectedRows(rows); // Set checked all current records
      setExportData([]); // Reset to export all data
    } else setSelectedRows([]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (rows) => {
      const exportSelectedThemes = themeData.filter((x) => rows.includes(x.id));
      setExportData(exportSelectedThemes);
      setSelectedRows(rows);
    },
    hideSelectAll: true,
    onSelect: (_, selected) => {
      if (!selected && selectAll) setSelectAll(false);
    },
    preserveSelectedRowKeys: true,
    columnTitle: <Checkbox checked={selectAll} onChange={onSelectAllChanged} />,
  };

  const exportCSV = async () => {
    const { term = '' } = form.getFieldsValue(['term']);
    let themeText;
    themeText = term.trim();
    if (themeData.length <= 0) return; // No data to export

    if (selectAll || (selectedRowKeys.length === 0 && themeData.length >= 0)) {
      // Export all
      setFetchedExportData(false);
      getAllThemeByCondition({ themeText }).then((res) => {
        setExportData(res);
        setFetchedExportData(true);
      });
    }
  };

  return (
    <div style={{ background: '#FFF' }}>
      <div className='admin-container'>
        <h1>テーマの設定</h1>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 0]}>
          <Col xs={24} md={24} sm={24} lg={4}>
            <div className='left-container'>
              <div className='left-header'>
                <Space>
                  <Button
                    onClick={() => editModalRef.current.show()}
                    type='primary'
                    shape='circle'
                    icon={<PlusOutlined />}
                    size={40}
                  />

                  <CSVImport
                    headerMappings={[
                      { key: 'イベントコード', label: 'eventCode' },
                      { key: 'テーマコード', label: 'themeCode' },
                      { key: 'テーマテキスト', label: 'themeText' },
                    ]}
                    onUpload={(data) => addThemeFromCSV(data)}
                    onUploadFinish={() => form.submit()}
                  />

                  {selectedRowKeys.length > 0 && (
                    <Button
                      type='primary'
                      shape='circle'
                      danger
                      icon={<DeleteFilled />}
                      size={40}
                      onClick={confirmDelete}
                    />
                  )}
                </Space>
              </div>
              <Form
                form={form}
                layout='vertical'
                style={{
                  marginTop: 15,
                }}
                name='s-form'
                onFinish={getThemeData}
              >
                <Form.Item name='term'>
                  <Input placeholder='テーマテキスト' />
                </Form.Item>

                <Form.Item>
                  <div className='search-btn'>
                    <Space direction='vertical' align='center'>
                      <Button
                        type='primary'
                        htmlType='submit'
                        icon={<SearchOutlined />}
                        size={40}
                        onClick={() => setPage(1)}
                      >
                        検索する
                      </Button>
                      <CSVExportButton
                        data={exportData}
                        headers={[
                          { label: 'イベントコード', key: 'eventCode' },
                          { label: 'テーマコード', key: 'themeCode' },
                          { label: 'テーマテキスト', key: 'themeText' },
                          { label: '今日のテーマ', key: 'status' },
                        ]}
                        fileName={`themes_${today}.csv`}
                        fetchCSVData={exportCSV}
                        fetched={fetchedExportData}
                      />
                    </Space>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Col>

          <Col xs={24} md={24} sm={24} lg={20}>
            <Table
              rowKey='id'
              className='table-sticky-header'
              rowSelection={rowSelection}
              dataSource={themeData}
              pagination={false}
              loading={getThemeLoading}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description='データなし'
                  />
                ),
              }}
            >
              <Table.Column
                title='イベントコード'
                dataIndex='eventCode'
                key='eventCode'
                render={(eventCode, row) => (
                  <a onClick={() => editModalRef.current.show(row)}>
                    <Space>
                      <span>{eventCode}</span>
                    </Space>
                  </a>
                )}
              />

              <Table.Column
                title='テーマコード'
                key='themeCode'
                dataIndex='themeCode'
              />
              <Table.Column
                title='テーマテキスト'
                key='themeText'
                dataIndex='themeText'
              />
              <Table.Column
                title='今日のテーマ'
                key='status'
                dataIndex='status'
                render={(d) =>
                  d ? (
                    <Tag color={'green'} key={0}>
                      {'噫'}
                    </Tag>
                  ) : (
                    <Tag color={'red'} key={1}>
                      {'否'}
                    </Tag>
                  )
                }
              />
            </Table>
            <Pagination
              page={page}
              isBackDisabled={page === 1 || getThemeLoading}
              onClickBack={() => setPage(page - 1)}
              isNextDisabled={!canLoadMore || getThemeLoading}
              onClickNext={() => setPage(page + 1)}
            />
          </Col>
        </Row>
      </div>

      <ThemeAddOrEditModal ref={editModalRef} onUpdated={() => form.submit()} />
    </div>
  );
}

export default ThemeManagement;
