import * as Actions from '../actions';

let initState = {
  giveaway: {
    requesting: false,
    result: {
      data: [],
      lastDocs: [
        {
          page: 1,
          lastDoc: null,
        }
      ]
    },
    error: null,
    status: '',
  },
  savingGiveaway: {
    requesting: false,
    result: null,
    error: null,
    status: '',
  },
  giveawayHistory: {
    requesting: false,
    result: {
      data: [],
      lastDocs: [
        {
          page: 1,
          lastDoc: null,
        }
      ]
    },
    error: null,
    status: '',
  },
  savingGiveawayHistory: {
    requesting: false,
    result: null,
    error: null,
    status: '',
  },
};

export default function userReducers(state = initState, action) {
  switch (action.type) {
    case Actions.GET_GIVEAWAY_REQUEST:
      return {
        ...state,
        giveaway: {
          ...state.giveaway,
          requesting: true,
          status: '',
        },
      };

    case Actions.GET_GIVEAWAY_SUCCESS:
      return {
        ...state,
        giveaway: {
          ...state.giveaway,
          requesting: false,
          result: action.payload,
          status: 'success',
        },
      };

    case Actions.GET_GIVEAWAY_FAIL:
      return {
        ...state,
        giveaway: {
          ...state.giveaway,
          requesting: false,
          error: action.error,
          status: 'error',
        },
      };
    case Actions.SAVE_GIVEAWAY_REQUEST:
      return {
        ...state,
        savingGiveaway: {
          ...state.savingGiveaway,
          requesting: true,
          status: '',
        },
      };

    case Actions.SAVE_GIVEAWAY_SUCCESS:
      const giveawayData = state.giveaway.result.data;

      return {
        ...state,
        savingGiveaway: {
          ...state.savingGiveaway,
          requesting: false,
          result: action.payload,
          status: 'success',
        },
        giveaway: {
          ...state.giveaway,
          result: {
            ...state.giveaway.result,
            data: giveawayData.map(item => {
                if(item.id === action.payload.id) {
                  return {...item, ...action.payload}
                }
                return item
              })
          }
        },
      };

    case Actions.SAVE_GIVEAWAY_FAIL:
      return {
        ...state,
        savingGiveaway: {
          ...state.savingGiveaway,
          requesting: false,
          error: action.error,
          status: 'error',
        },
      };
      case Actions.GET_GIVEAWAY_HISTORY_REQUEST:
        return {
          ...state,
          giveawayHistory: {
            ...state.giveawayHistory,
            requesting: true,
            status: '',
          },
        };
  
      case Actions.GET_GIVEAWAY_HISTORY_SUCCESS:
        return {
          ...state,
          giveawayHistory: {
            ...state.giveawayHistory,
            requesting: false,
            result: action.payload,
            status: 'success',
          },
        };
  
      case Actions.GET_GIVEAWAY_HISTORY_FAIL:
        return {
          ...state,
          giveawayHistory: {
            ...state.giveawayHistory,
            requesting: false,
            error: action.error,
            status: 'error',
          },
        };
        case Actions.SAVE_GIVEAWAY_HISTORY_REQUEST:
          return {
            ...state,
            savingGiveawayHistory: {
              ...state.savingGiveawayHistory,
              requesting: true,
              status: '',
            },
          };
    
        case Actions.SAVE_GIVEAWAY_HISTORY_SUCCESS:
          const giveawayHistoryData = state.giveawayHistory.result.data;

          return {
            ...state,
            savingGiveawayHistory: {
              ...state.savingGiveawayHistory,
              requesting: false,
              result: action.payload,
              status: 'success',
            },

            giveawayHistory: {
              ...state.giveawayHistory,
              result: {
                ...state.giveawayHistory.result,
                data: giveawayHistoryData.map(item => {
                    if(item.id === action.payload.id) {
                      return {...item, ...action.payload}
                    }
                    return item
                  })
              }
            },
          };
    
        case Actions.SAVE_GIVEAWAY_HISTORY_FAIL:
          return {
            ...state,
            savingGiveawayHistory: {
              ...state.savingGiveawayHistory,
              requesting: false,
              error: action.error,
              status: 'error',
            },
          };
    default:
      return state;
  }
}
