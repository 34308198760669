import * as Actions from '../actions';

let initState = {
  login: {
    status: '',
    result: null,
    error: null,
    requesting: false,
  },
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case Actions.LOGIN_REQUEST:
      return {
        ...state,
        login: {
          requesting: true,
          status: '',
        },
      };

    case Actions.LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          requesting: false,
          result: action.data,
          status: 'success',
        },
      };

    case Actions.LOGIN_FAIL:
      return {
        ...state,
        login: {
          requesting: false,
          error: action.error,
          status: 'error',
        },
      };

    case Actions.LOGOUT:
      return {
        ...state,
        login: {
          result: null,
        },
      };
    case Actions.UPDATE_EMAIL:
      return {
        ...state,
        login: {
          ...state.login,
          result: {
            ...state.login.result,
            email: action.email,
          },
        },
      };

    case Actions.UPDATE_DISPLAYNAME:
      return {
        ...state,
        login: {
          ...state.login,
          result: {
            ...state.login.result,
            displayUserName: action.displayName,
          },
        },
      };

    default:
      return state;
  }
}
