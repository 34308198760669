let initState = {
  blocking: false,
};

export default function blockUIReducers(state = initState, action) {
  switch (action.type) {
    case 'BLOCK_UI':
      return {
        ...state,
        blocking: true,
      };

    case 'UNBLOCK_UI':
      return {
        ...state,
        blocking: false,
      };

    default:
      return state;
  }
}
