import React, { useState, useEffect, useRef } from 'react';
import { Table, Row, Col, Button, Space, Modal, Form, Input, Empty, Checkbox, Typography, Avatar } from 'antd';
import {
  DeleteFilled,
  VerticalAlignTopOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getMedalTransmissionHistory } from 'redux-store/medal-transmission/action';
import { get, uniq } from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import numeral from 'numeral';

import { blockUI, unblockUI } from 'redux-store/blocking-ui/action';
import constants from 'commons/constants';
import { CSVExportButton, Pagination, CSVImport } from 'components';
import { deleteMedalTransmissionHistorys, getAllMedalTransmissionHistoryByCondition } from 'services';
import { ic_default_user, ic_default_group_avatar } from 'assets/images';
import { flatMedalTransmissionHistoryDataForExport } from 'utils/common';
import MedalTransmissionEditorModal from './MedalTransmissionEditorModal';
import './style.scss';

function MedalTransmissionHistory() {
  const today = moment().format(constants.DATE_FILE_FORMAT);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const editorModalRef = useRef();

  const manager = useSelector((state) => get(state, 'auth.login.result'));
  const companyCode = get(manager, 'companyCode');
  /**
   * States
   */
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowKeys, setSelectedRows] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [fetchedExportData, setFetchedExportData] = useState(false);
  const [page, setPage] = useState(1);
  const medalTransmission = useSelector((state) => get(state, 'medalTransmission.medalTransmission'));

  const requesting = get(medalTransmission, 'requesting', false);
  const canLoadMore = get(medalTransmission, 'result.canLoadMore', false);

  const data = get(medalTransmission, 'result.data', []);

  const getMedalTransmissionHistoryData = ({ searchText }) => {
    dispatch(getMedalTransmissionHistory({ page, searchText, companyCode }));
  };

  const handleDeleteGiveawayHistory = () => {
    dispatch(blockUI());

    deleteMedalTransmissionHistorys(selectedRowKeys, selectAll, companyCode)
      .then(() => {
        toast.success('メダルの送信履歴は削除されました。');
        form.submit();
      })
      .catch((err) => {
        toast.error('何かがうまくいかなかった。');
        console.log(err);
      })
      .finally(() => {
        dispatch(unblockUI());
      });
  };

  const exportCSV = async () => {
    const { searchText } = form.getFieldsValue(['searchText']);
    console.log(selectAll)
    if (data.length <= 0) return; // No data to export
    console.log(selectedRowKeys)
    if (selectAll || (selectedRowKeys.length === 0 && data.length >= 0)) {
      // Export all
      setFetchedExportData(false);
      dispatch(blockUI());
      getAllMedalTransmissionHistoryByCondition({ searchText, companyCode })
        .then((res) => {
          dispatch(unblockUI());
          setExportData(res);
          setFetchedExportData(true);
        })
        .catch(() => dispatch(unblockUI()));
    }
  };

  /**
   * Show confirm delete modal
   */
  const confirmDelete = () => {
    Modal.confirm({
      centered: true,
      title: constants.MODAL.CONFIRM,
      icon: <ExclamationCircleOutlined />,
      content: 'プレゼントの履歴を削除してもよろしいですか？',
      okText: constants.YES,
      cancelText: constants.CANCEL,
      onOk: handleDeleteGiveawayHistory,
    });
  };

  /**
   * Custom select all
   * @param {*} e
   */
  const onSelectAllChanged = (e) => {
    const checked = e.target.checked;
    const rows = data.map((u) => u.id);

    setSelectAll(checked);
    if (checked) {
      setSelectedRows(rows); // Set checked all current records
      setExportData([]); // Reset to export all data
    } else setSelectedRows([]);
  };

  /**
   * Excute search when page has changed
   */
  useEffect(() => {
    form.submit();
  }, [page]);

  /**
   * Selected all when giveaway appended
   */
  useEffect(() => {
    const selected = selectedRowKeys;
    const uids = data.map((u) => u.id);

    if (selectAll) {
      const selectedRowKeys = uniq([...selected, ...uids]);
      setSelectedRows(selectedRowKeys);
    }
  }, [data]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (rows) => {
      const exportSelectedEmoca = data.filter((x) => rows.includes(x.id));
      setExportData(exportSelectedEmoca);
      setSelectedRows(rows);
    },
    hideSelectAll: true,
    onSelect: (_, selected) => {
      if (!selected && selectAll) setSelectAll(false);
    },
    preserveSelectedRowKeys: true,
    columnTitle: <Checkbox checked={selectAll} onChange={onSelectAllChanged} />,
  };

  return (
    <div style={{ background: '#fff' }}>
      <div className="admin-container">
        <h1>メダルの送信履歴</h1>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 0]}>
          <Col xs={24} md={24} sm={24} lg={4}>
            <div className="left-container">
              <div className="left-header">
                <Space>
                  {selectedRowKeys.length > 0 && (
                    <Button
                      type="primary"
                      shape="circle"
                      danger
                      icon={<DeleteFilled />}
                      size={40}
                      onClick={confirmDelete}
                    />
                  )}
                </Space>
              </div>
              <Form
                form={form}
                layout="vertical"
                style={{
                  marginTop: 15,
                }}
                name="s-form"
                initialValues={{ searchText: '' }}
                onFinish={getMedalTransmissionHistoryData}
              >
                <Form.Item name="searchText" label="タイプで検索">
                  <Input placeholder="タイプ" />
                </Form.Item>
                <Form.Item>
                  <div className="search-btn">
                    <Space direction="vertical" align="center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SearchOutlined />}
                        size={40}
                        onClick={() => setPage(1)}
                      >
                        検索する
                      </Button>

                      <CSVExportButton
                        data={flatMedalTransmissionHistoryDataForExport(exportData)}
                        headers={[
                          { label: 'id', key: 'id' },
                          { label: 'タイプ', key: 'type' },
                          { label: 'メダル数', key: 'amount' },
                          { label: '解説', key: 'description' },
                          { label: 'ユーザー名', key: 'username' },
                          { label: 'ユーザーID', key: 'userId' },
                        ]}
                        fileName={`Medal-Transmission_${today}.csv`}
                        fetchCSVData={exportCSV}
                        fetched={fetchedExportData}
                      />
                    </Space>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Col>

          <Col xs={24} md={24} sm={24} lg={20}>
            <Table
              rowKey="id"
              className="table-sticky-header"
              rowSelection={rowSelection}
              dataSource={data}
              pagination={false}
              loading={requesting}
              locale={{
                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="データなし" />,
              }}
              // onRow={(data) => ({
              //   onClick: () => openEditModal(data),
              // })}
            >
              <Table.Column
                title="タイプ"
                dataIndex="type"
                key="type"
              />

              <Table.Column
                title="メダル数"
                key="amount"
                dataIndex="amount"
                render={(image, row) => (
                  <a href onClick={() => editorModalRef.current.show(row)}>
                    {numeral(get(row, 'amount', 0)).format( '0,0')}
                  </a>
                )}
              />
              <Table.Column title="解説" key="description" dataIndex="description" />
              <Table.Column
                title="ユーザー"
                key="user"
                render={(image, row) => (
                  <Space>
                    <Avatar src={get(row, 'user.avatar') || ic_default_user} />
                    <span>{get(row, 'user.displayUserName')}</span>
                  </Space>
                )}
              />
            </Table>
            <Pagination
              page={page}
              isBackDisabled={page === 1 || requesting}
              onClickBack={() => setPage(page - 1)}
              isNextDisabled={!canLoadMore || requesting}
              onClickNext={() => setPage(page + 1)}
            />
          </Col>
        </Row>
      </div>
      <MedalTransmissionEditorModal ref={editorModalRef} />
    </div>
  );
}

export default MedalTransmissionHistory;
