import * as Actions from '../actions';
import * as Services from 'services';

import { get } from 'lodash';
import moment from 'moment';

export const getReportByDates = (params = {}) => async (dispatch, getState) => {
  dispatch({ type: Actions.GET_REPORT_FOR_DATES_REQUEST });
  try {
    const state = getState();
    const isLoadMore = get(params, 'isLoadMore', true);

    let systemInitTime = get(
      state,
      'report.reportByDates.result.systemInitTime'
    );
    let canLoadMore = true;

    if (!systemInitTime && !isLoadMore) {
      systemInitTime = await Services.getSystemInitTime();
    }

    let endTime = moment().add(1, 'd').toDate();
    let startTime = moment().subtract(6, 'd').startOf('d').toDate();

    if (isLoadMore) {
      const lastTime = get(state, 'report.reportByDates.result.lastTime');
      endTime = moment(lastTime).toDate();
      startTime = moment(lastTime).subtract(7, 'd').toDate();
    }

    if (systemInitTime) {
      const mSystemInitTime = moment(systemInitTime).startOf('d');
      if (mSystemInitTime.isAfter(moment(startTime))) {
        startTime = mSystemInitTime.toDate();
        canLoadMore = false;
      }
    }

    const data = await Services.getReportByDates({
      ...params,
      endTime,
      startTime,
    });

    dispatch({
      type: Actions.GET_REPORT_FOR_DATES_SUCCESS,
      data: {
        data,
        lastTime: startTime,
        isLoadMore,
        systemInitTime,
        canLoadMore,
      },
    });
  } catch (error) {
    dispatch({ type: Actions.GET_REPORT_FOR_DATES_FAIL, error });
  }
};
// export const getReportByDates = (params = {}) => async (dispatch, getState) => {
//   dispatch({ type: Actions.GET_REPORT_FOR_DATES_REQUEST });
//   try {
//     const state = getState();
//     const isLoadMore = get(params, 'isLoadMore', true);
//     let systemInitTime = get(state, 'report.reportByDates.result.systemInitTime');
//     let canLoadMore = true;

//     if (!systemInitTime && !isLoadMore) {
//       systemInitTime = await Services.getSystemInitTime();
//     }

//     let endTime = moment().toDate();
//     let startTime = moment().subtract(6, 'd').startOf('d').toDate();

//     if (isLoadMore) {
//       const lastTime = get(state, 'report.reportByDates.result.lastTime');
//       endTime = moment(lastTime).subtract(1, 'd').toDate();
//       startTime = moment(lastTime).subtract(7, 'd').toDate();
//     }

//     if (systemInitTime) {
//       const mSystemInitTime = moment(systemInitTime).startOf('d');
//       if (mSystemInitTime.isAfter(moment(startTime))) {
//         startTime = mSystemInitTime.toDate();
//         canLoadMore = false;
//       }
//     }

//     const data = await Services.getReportByDates({ ...params, endTime, startTime });
//     dispatch({
//       type: Actions.GET_REPORT_FOR_DATES_SUCCESS,
//       data: {
//         data,
//         lastTime: startTime,
//         isLoadMore,
//         systemInitTime,
//         canLoadMore,
//       },
//     });
//   } catch (error) {
//     dispatch({ type: Actions.GET_REPORT_FOR_DATES_FAIL, error });
//   }
// };

export const getGeneralReport = (params) => async (dispatch) => {
  dispatch({ type: Actions.GET_GENERAL_REPORT_REQUEST });
  try {
    const data = await Services.getGeneralReport(params);
    dispatch({ type: Actions.GET_GENERAL_REPORT_SUCCESS, data });
  } catch (error) {
    console.log(error);
    dispatch({ type: Actions.GET_GENERAL_REPORT_FAIL, error });
  }
};

export const getAverageReport = ({ companyCode }) => async (dispatch) => {
  dispatch({ type: Actions.GET_AVERAGE_REPORT_REQUEST });
  try {
    const data = await Services.getAverageDashboard({ companyCode });
    dispatch({ type: Actions.GET_AVERAGE_REPORT_SUCCESS, data });
  } catch (error) {
    console.log(error);
    dispatch({ type: Actions.GET_AVERAGE_REPORT_FAIL });
  }
};
