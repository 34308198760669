export const ic_history = require('assets/images/ic_history.png');
export const ic_settings = require('assets/images/ic_settings.png');
export const logo = require('assets/images/logo2.png');
export const default_img = require('assets/images/default_img.png');


export const ic_good = require('assets/images/ic_good.png');
export const ic_nogood = require('assets/images/ic_nogood.png');
export const ic_verygood = require('assets/images/ic_verygood.png');
export const ic_default_user = require('assets/images/ic_default_user.png');
export const ic_default_group_avatar = require('assets/images/group_avatar_default.png');
export const ic_more = require('assets/images/ic_more.png');
export const spinner = require('assets/images/spinner.gif');

