import { firebase } from 'commons/configs';
import constants from 'commons/constants';
import { last, merge } from 'lodash';

import { getUserById, getGroupById } from 'services';

const firestore = firebase.firestore();

export const getGiveaway = async ({ searchText, lastDoc } = {}) => {
  try {
    let query = firestore.collection(constants.COLLECTION_GIVEAWAY).where('isDeleted', '==', false);
    if (searchText) query = query.where('text', '==', searchText);

    query = query.orderBy('createdAt', 'desc');

    if (lastDoc) query = query.startAfter(lastDoc);

    const { docs } = await query.limit(constants.PAGE_LIMIT).get();

    const data = docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    return {
      data,
      lastDoc: last(docs),
      canLoadMore: docs.length === constants.PAGE_LIMIT,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAllGiveawayByCondition = async ({ searchText }) => {
  try {
    let query = firestore.collection(constants.COLLECTION_GIVEAWAY).where('isDeleted', '==', false);

    if (searchText) query = query.where('text', '==', searchText);
    const { docs } = await query.get();

    return docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  } catch (error) {
    console.log(error);
  }
};

export const saveGiveaway = async (params) => {
  try {
    const cloneParams = { ...params };
    const { id } = cloneParams;
    let ref = firestore.collection(constants.COLLECTION_GIVEAWAY).doc();

    if (id) {
      ref = firestore.collection(constants.COLLECTION_GIVEAWAY).doc(id);
    } else {
      cloneParams.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      cloneParams.isDeleted = false;
    }

    delete cloneParams.id;

    await ref.set(cloneParams, { merge: true });
  } catch (error) {
    console.log(error);
  }
};

export const deleteGiveaways = async (ids, isDeleteAll) => {
  const giveawayCollection = firestore.collection(constants.COLLECTION_GIVEAWAY);
  if (isDeleteAll) {
    const { docs } = await giveawayCollection.where('isDeleted', '==', false).get();
    await Promise.all(docs.map((doc) => doc.ref.update({ isDeleted: true })));
  } else {
    await Promise.all(ids.map((id) => giveawayCollection.doc(id).update({ isDeleted: true })));
  }
};

export const saveGiveawayFromCSV = (companies) => {
  const promises = companies.map((company) => saveGiveaway(company));
  return Promise.all(promises);
};

export const getGiveawayHistory = async ({ searchText, companyCode, lastDoc } = {}) => {
  try {
    let query = firestore
      .collection(constants.COLLECTION_EXCHANGE)
      .where('isDeleted', '==', false)
      .where('type', '==', constants.WALLET_HISTORY_TYPES.giveaway);

    if (companyCode) query = query.where('companyCode', '==', companyCode);
    if (searchText) query = query.where('giveaway.text', '==', searchText);

    query = query.orderBy('createdAt', 'desc');

    if (lastDoc) query = query.startAfter(lastDoc);

    const { docs } = await query.limit(constants.PAGE_LIMIT).get();
    const data = await getGiveawayHistorySideData(docs);

    return {
      data,
      lastDoc: last(docs),
      canLoadMore: docs.length === constants.PAGE_LIMIT,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getGiveawayHistorySideData = async (docs) => {
  return await Promise.all(
    docs.map(async (doc) => {
      const giveawayHistory = { ...doc.data(), id: doc.id };
      const { userId } = giveawayHistory;
      const user = await getUserById(userId);

      return {
        ...giveawayHistory,
        user,
      };
    })
  );
};

export const getAllGiveawayHistoryByCondition = async ({ searchText, companyCode } = {}) => {
  try {
    let query = firestore
      .collection(constants.COLLECTION_EXCHANGE)
      .where('isDeleted', '==', false)
      .where('type', '==', constants.WALLET_HISTORY_TYPES.giveaway);

    if (companyCode) query = query.where('companyCode', '==', companyCode);
    if (searchText) query = query.where('giveaway.text', '==', searchText);

    query = query.orderBy('createdAt', 'desc');

    const { docs } = await query.get();
    const data = await getGiveawayHistorySideData(docs);

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteGiveawayHistorys = async (ids, isDeleteAll, companyCode) => {
  const giveawayCollection = firestore.collection(constants.COLLECTION_EXCHANGE);
  if (isDeleteAll) {
    let query = giveawayCollection.where('isDeleted', '==', false);

    if (companyCode) query = query.where('companyCode', '==', companyCode);

    const { docs } = await query.get();

    await Promise.all(
      docs.map(async (doc) => {
        await doc.ref.update({ isDeleted: true });
        await revertMedalForDeletedGiveawayHistory(doc.id);
      })
    );
  } else {
    await Promise.all(
      ids.map(async (id) => {
        await giveawayCollection.doc(id).update({ isDeleted: true });
        await revertMedalForDeletedGiveawayHistory(id);
      })
    );
  }
};

const revertMedalForDeletedGiveawayHistory = async (id) => {
  const giveawayHistoryDoc = await firestore.collection(constants.COLLECTION_EXCHANGE).doc(id).get();
  const giveawayHistory = giveawayHistoryDoc.data();
  const { userId, amount } = giveawayHistory;

  await firestore
    .collection(constants.COLLECTION_MEDAL_WALLET)
    .doc(userId)
    .update({
      medalBalance: firebase.firestore.FieldValue.increment(amount),
    });
};

export const saveGiveawayHistory = async (params) => {
  try {
    const { id, amount: newAmount, description } = params;

    const giveawayHistoryRef = firestore.collection(constants.COLLECTION_EXCHANGE).doc(id);
    const giveawayHistory = (await giveawayHistoryRef.get()).data();
    const { userId, amount } = giveawayHistory;

    await giveawayHistoryRef.update({ amount: newAmount, description });
    await firestore
      .collection(constants.COLLECTION_MEDAL_WALLET)
      .doc(userId)
      .update({
        medalBalance: firebase.firestore.FieldValue.increment(amount - newAmount),
      });
  } catch (error) {
    console.log(error);
    throw error
  }
};
