import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { default_img } from 'assets/images';
import './style.scss';
import { get } from 'lodash';
import constants from 'commons/constants';
import { uploadImage } from 'services';

const ImageUpload = React.forwardRef((props, ref) => {
  const { url, wraperStyle, imageStyle } = props;
  const imgUploadRef = React.useRef();
  const inputRef = React.useRef();

  const [image, setImage] = React.useState({
    file: null,
    url: null,
    loading: false,
  });

  React.useImperativeHandle(ref, () => ({ upload, resetStates }));

  React.useEffect(() => {
    setImage({ ...image, url });
  }, [url]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = (e) => {
    const files = e.target.files;

    if (files && files[0]) {
      getBase64(files[0], (url) =>
        setImage({
          file: files[0],
          loading: false,
          url,
        })
      );
    }
  };

  const upload = (storagePath) => {
    return validateFile()
      .then(() => {
        const fileName = get(image, 'file.name');
        if (image.file) return uploadImage(`${storagePath}${new Date().getTime()}_${fileName}`, image.url);
        else return url;
      })
      .then((url) => url);
  };

  const resetStates = () => {
    inputRef.current.value = '';
    setImage({ file: null, url: null, loading: false });
  };

  const validateFile = () => {
    const file = get(image, 'file');

    if (file && file.size > constants.FILE_LIMIT)
      return Promise.reject({
        message: constants.FILE_LIMIT_MSG,
      });
    return Promise.resolve();
  };

  return (
    <div ref={imgUploadRef} className="avatar-wrapper" style={wraperStyle}>
      <img className="profile-pic" src={image.url || default_img} style={imageStyle}/>
      <div className="upload-button" onClick={() => inputRef.current.click()}>
        <UploadOutlined className="upload-icon" />
      </div>
      <input
        ref={inputRef}
        className="file-upload"
        type="file"
        accept="image/*"
        onChange={handleChange}
      />
    </div>
  );
});

export default ImageUpload;
