import { combineReducers } from 'redux';
import auth from './auth/reducer';
import report from './report/reducer';
import user from './users/reducer';
import company from './company/reducer';
import theme from './themes/reducer';
import giveaway from './giveaways/reducer';
import navisapo from './navisapo/reducer';
import event from './event/reducer';
import emoca from './emoca/reducer';
import blockui from './blocking-ui/reducer';
import eventHistory from './event-history/reducer';
import themeHistory from './theme-history/reducer';
import group from './group/reducer';
import medalTransmission from './medal-transmission/reducer';
import role from './roles/reducer';
import layer from './layer-master/reducer';

export default combineReducers({
  auth,
  report,
  user,
  company,
  theme,
  giveaway,
  navisapo,
  event,
  emoca,
  blockui,
  eventHistory,
  themeHistory,
  group,
  medalTransmission,
  role,
  layer,
});
