import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Descriptions } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { getAllCompanyByCondition } from 'services';
import UpdateModal from '../admin/company-management/CompanyAddOrEditModal';
import { default_img } from 'assets/images';
import { get, head } from 'lodash';

import './CompanyManager.scss';

function CompanyManager() {
  const updateModalRef = React.useRef();

  const [company, setCompany] = React.useState(null);
  const companyCode = useSelector((state) =>
    get(state, 'auth.login.result.companyCode')
  );
  const getCompanyInfo = () => {
    getAllCompanyByCondition({ code: companyCode }).then((res) =>
      setCompany(head(res))
    );
  };

  React.useEffect(() => getCompanyInfo(), []);

  return (
    <div
      className="admin-container"
      style={{
        background: '#fff',
      }}
    >
      <div className="logo-wraper">
        <b>会社のロゴ:</b>
        <img src={company?.companyLogo || default_img} />
      </div>

      <Descriptions column={1} title="企業設定" bordered>
        <Descriptions.Item label="会社名">
          {company?.companyName}
        </Descriptions.Item>
        <Descriptions.Item label="会社コード">
          {company?.companyCode}
        </Descriptions.Item>
        <Descriptions.Item label="初期バランス">
          {company?.initialBalance}
        </Descriptions.Item>
        <Descriptions.Item label="創造の時">
          {company?.createdAt}
        </Descriptions.Item>
      </Descriptions>

      <Button
        onClick={() => updateModalRef.current.show(company)}
        style={{ marginTop: 15 }}
        type="primary"
        icon={<EditOutlined />}
      >
        編集
      </Button>

      <UpdateModal ref={updateModalRef} onUpdated={getCompanyInfo} />
    </div>
  );
}

export default CompanyManager;
