import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Form,
  DatePicker,
  Empty,
  Checkbox,
  Input,
} from 'antd';
import {
  DeleteFilled,
  ExclamationCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { actGetEventHistories } from 'redux-store/event-history/action';
import { blockUI, unblockUI } from 'redux-store/blocking-ui/action';
import { get, uniq } from 'lodash';
import constants from 'commons/constants';
import { CSVExportButton, Pagination } from 'components';
import numeral from 'numeral';
import locale from 'antd/lib/date-picker/locale/ja_JP';
import moment from 'moment';
import { deleteEventHistories, getAllEventHistories } from 'services';
import AdjustMedalModal from './AdjustMedalModal';
import { toast } from 'react-toastify';
const { RangePicker } = DatePicker;

function EventHistory() {
  const today = moment(new Date()).format(constants.DATE_FILE_FORMAT);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const adjustMedalModalRef = useRef();

  const [selectedRowKeys, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [fetchedExportData, setFetchedExportData] = useState(false);
  const [page, setPage] = useState(1);
  const auth = useSelector((state) => get(state, 'auth.login.result', {}));
  const cpnCode = get(auth, 'companyCode');
  const isAdmin = get(auth, 'role') === constants.ROLES.admin;

  const {
    data: events = [],
    loading: getCpnLoading,
    canLoadMore,
  } = useSelector((state) => get(state, 'eventHistory.events', {}));

  /**
   * Get users
   * @param {*} formValues
   */
  const getEventData = (formValues) => {
    const {
      companyCode = cpnCode,
      eventCode = '',
      rangePicker,
    } = formValues;
    
    const [startDate, endDate] = rangePicker || [];

    dispatch(
      actGetEventHistories({ page, companyCode, eventCode, startDate, endDate })
    );
  };

  /**
   * Delete selected events
   */
  const onDeleteEvents = () => {
    dispatch(blockUI());
    deleteEventHistories(selectedRowKeys, selectAll)
      .then(() => {
        dispatch(unblockUI());
        setSelectedRows([]);
        toast.success('イベントは削除されました。');
        form.submit();
      })
      .catch((err) => {
        dispatch(unblockUI());
        toast.error(err);
      });
  };

  /**
   * Show confirm delete modal
   */
  const confirmDelete = () => {
    Modal.confirm({
      centered: true,
      title: constants.MODAL.CONFIRM,
      icon: <ExclamationCircleOutlined />,
      content: '本当にイベントを外しているのでしょうか？',
      okText: constants.YES,
      cancelText: constants.CANCEL,
      onOk: onDeleteEvents,
    });
  };

  /**
   * Excute search when page has changed
   */
  useEffect(() => {
    form.submit();
  }, [page]);

  useEffect(() => {
    if (events.length <= 0 && !canLoadMore) setSelectAll(false);

    const selected = selectedRowKeys;
    const uids = events.map((u) => u.id);

    if (selectAll) {
      const selectedRowKeys = uniq([...selected, ...uids]);
      setSelectedRows(selectedRowKeys);
    }
  }, [events]);

  /**
   * Custom select all
   * @param {*} e
   */
  const onSelectAllChanged = (e) => {
    const checked = e.target.checked;
    const rows = events.map((u) => u.id);

    setSelectAll(checked);
    if (checked) {
      setSelectedRows(rows); // Set checked all current records
      setExportData([]); // Reset to export all data
    } else setSelectedRows([]);
  };

  /**
   * Export CSV file
   */
  const exportCSV = async () => {
    const {
      companyCode = cpnCode,
      eventCode = '',
      rangePicker: [startDate, endDate] = [],
    } = form.getFieldsValue();

    if (events.length <= 0) return; // No data to export

    if (selectAll || (selectedRowKeys.length === 0 && events.length >= 0)) {
      // Export all
      setFetchedExportData(false);
      dispatch(blockUI());
      getAllEventHistories({ companyCode, eventCode, startDate, endDate })
        .then((res = []) => {
          setExportData(res);
          setFetchedExportData(true);
        })
        .catch(() => dispatch(unblockUI()));
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (rows) => {
      const exportSelectedEvents = events.filter((x) => rows.includes(x.id));
      setExportData(exportSelectedEvents);
      setSelectedRows(rows);
    },
    hideSelectAll: true,
    onSelect: (_, selected) => {
      if (!selected && selectAll) setSelectAll(false);
    },
    preserveSelectedRowKeys: true,
    columnTitle: <Checkbox checked={selectAll} onChange={onSelectAllChanged} />,
  };

  return (
    <div style={{ background: '#fff' }}>
      <div className="admin-container">
        <h1>イベントの発動履歴</h1>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 0]}>
          <Col xs={24} md={24} sm={24} lg={4}>
            <div className="left-container">
              <div className="left-header">
                <Space>
                  <Button
                    type="primary"
                    danger
                    disabled={selectedRowKeys.length <= 0}
                    icon={<DeleteFilled />}
                    size={40}
                    onClick={confirmDelete}
                  >
                    Delete
                  </Button>
                </Space>
              </div>
              <Form
                form={form}
                layout="vertical"
                style={{
                  marginTop: 15,
                }}
                name="s-form"
                initialValues={{
                  searchby: 'companyName',
                }}
                onFinish={getEventData}
              >
                {isAdmin && (
                  <Form.Item name="companyCode" label="会社を選ぶ">
                    <Input />
                  </Form.Item>
                )}

                <Form.Item name="eventCode" label="イベントコード">
                  <Input />
                </Form.Item>

                <Form.Item
                  name="rangePicker"
                  label="日付の範囲を選択します。"
                  rules={[{ type: 'array' }]}
                >
                  <RangePicker locale={locale} />
                </Form.Item>

                <Form.Item>
                  <div className="search-btn">
                    <Space direction="vertical" align="center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SearchOutlined />}
                        size={40}
                        onClick={() => setPage(1)}
                      >
                        検索する
                      </Button>

                      <CSVExportButton
                        data={exportData}
                        headers={[
                          { label: 'イベントコード', key: 'eventCodeId' },
                          { label: '会社コード', key: 'companyCode' },
                          { label: '送信機ID', key: 'senderId' },
                          { label: '受信者ID', key: 'receiverId' },
                          { label: 'メダル数', key: 'medalCount' },
                          { label: 'エモカID', key: 'emocaId' },
                          { label: 'テーマID', key: 'themeId' },
                          { label: '作成時間', key: 'createdAt' },
                        ]}
                        fileName={`EventHistories_${today}.csv`}
                        fetchCSVData={exportCSV}
                        fetched={fetchedExportData}
                      />
                    </Space>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Col>

          <Col xs={24} md={24} sm={24} lg={20}>
            <Table
              rowKey="id"
              rowClassName="cursor-pointer"
              className="table-sticky-header"
              rowSelection={rowSelection}
              dataSource={events}
              pagination={false}
              loading={getCpnLoading}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="データなし"
                  />
                ),
              }}
              onRow={(record) => ({
                onClick: () => adjustMedalModalRef.current.show(record),
              })}
            >
              <Table.Column
                title="イベントコード"
                dataIndex="eventCodeId"
                key="eventCodeId"
              />

              <Table.Column
                title="会社コード"
                key="companyCode"
                dataIndex="companyCode"
              />

              <Table.Column
                title="送信機ID"
                key="senderId"
                dataIndex="senderId"
              />

              <Table.Column
                title="受信者ID"
                key="receiverId"
                dataIndex="receiverId"
              />

              <Table.Column
                title="メダル数"
                key="medalCount"
                dataIndex="medalCount"
                render={(d) => numeral(d).format('0,0')}
              />

              <Table.Column
                title="エモカID"
                key="emocaId"
                dataIndex="emocaId"
              />

              <Table.Column
                title="テーマID"
                key="themeId"
                dataIndex="themeId"
              />

              <Table.Column
                title="作成時間"
                key="createdAt"
                dataIndex="createdAt"
              />
            </Table>

            <Pagination
              page={page}
              isBackDisabled={page === 1 || getCpnLoading}
              onClickBack={() => setPage(page - 1)}
              isNextDisabled={!canLoadMore || getCpnLoading}
              onClickNext={() => setPage(page + 1)}
            />
          </Col>
        </Row>
      </div>

      <AdjustMedalModal
        ref={adjustMedalModalRef}
        onUpdated={() => form.submit()}
      />
    </div>
  );
}

export default EventHistory;
