import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { Modal, Form, Input, Col, Row, InputNumber } from 'antd';
import useStateCallback from 'use-state-with-callback';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { saveEmoca } from 'redux-store/emoca/actions';
import constants from 'commons/constants';
import { getEmocaImageList } from 'services';
import { has, get } from 'lodash';
import './style.scss';
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';

const EmocaEditorModal = ({ onSaved }, ref) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  /**
   * Emoca Image Modals
   */
  const [emcImgs, setEmcImgs] = useState([]);
  const [modalEmocaVisible, setModalEmoca] = useState(false);
  const [emocaLoading, setEmocaLoading] = useState(false);

  const showEmocaImage = async () => {
    setModalEmoca(true);
    setEmocaLoading(true);
    const emocaImageId = get(emoca, 'emocaImage.id');
    const data = await getEmocaImageList(emocaImageId);
    setEmcImgs(data || []);
    setEmocaLoading(false);
  };

  const onSelectEmocaImage = (index, id) => {
    const emocaImages = [...emcImgs];
    const currentSelectedIdx = emocaImages.findIndex((x) => x.selected);
    const newSelectedIdx = emocaImages.findIndex((x) => x.id === id);

    if (currentSelectedIdx === index) return;
    else {
      emocaImages[currentSelectedIdx].selected = false;
      emocaImages[newSelectedIdx].selected = true;
      setEmcImgs(emocaImages);
    }
  };

  const updateEmocaImage = () => {
    const emocaImage = emcImgs.find((x) => x.selected);
    if (emocaImage) {
      const newEmoca = { ...emoca, emocaImage };
      setEmoca(newEmoca);
      setModalEmoca(false);
    }
  };
  /**
   * End Emoca Image Modals
   */

  const [modalVisible, setModalVisible] = useStateCallback(false, (val) => {
    if (!val) {
      setEmoca(null);
    }
  });

  const setFormValues = (emoca) => {
    if (emoca) {
      const {
        id,
        emocaMessage,
        empathyClap = 0,
        numberOfAttachedMedal = 0,
      } = emoca;

      form.setFieldsValue({
        id,
        emocaMessage,
        empathyClap,
        numberOfAttachedMedal,
      });
    } else {
      form.resetFields();
    }
  };

  const [emoca, setEmoca] = useStateCallback(null, setFormValues);
  const [requesting, setRequesting] = useState();
  const savingEmoca = useSelector((state) => get(state, 'emoca.savingEmoca'));

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const hide = () => {
    setModalVisible(false);
  };

  const show = (emoca) => {
    setModalVisible(true);
    setEmoca(emoca);
  };

  useEffect(() => {
    const { status } = savingEmoca;
    if (status === 'success') {
      toast.success('Emocaを正常に保存します。');
    } else if (status === 'error') {
      toast.error('何かがうまくいかなかった。');
    }
  }, [savingEmoca]);

  const submitEmocaForm = async () => {
    try {
      setRequesting(true);

      const values = form.getFieldsValue([
        'id',
        'emocaMessage',
        'empathyClap',
        'numberOfAttachedMedal',
      ]);

      const body = { ...values, emocaImage: emoca.emocaImage };

      await form.validateFields();
      await dispatch(saveEmoca(body));
      hide();
    } catch (error) {
      console.log(error);
      toast.error('何かがうまくいかなかった。');
    } finally {
      setRequesting(false);
    }
  };

  return (
    <>
      <Modal
        title={'emoca情報を編集する'}
        centered
        width={700}
        visible={modalVisible}
        forceRender={true}
        okText={constants.YES}
        cancelText={constants.CANCEL}
        onOk={submitEmocaForm}
        okButtonProps={{
          disabled: requesting,
        }}
        cancelButtonProps={{
          disabled: requesting,
        }}
        confirmLoading={requesting}
        onCancel={hide}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          form={form}
          labelAlign="left"
        >
          <Row style={{ marginBottom: 15 }}>
            <Col span={6}>テーマ</Col>
            <Col span={18}>
              <div className="emoca-selection" onClick={showEmocaImage}>
                <img
                  className="emoca-img"
                  src={get(emoca, 'emocaImage.image')}
                />

                <img
                  className="emoca-icon"
                  src={get(emoca, 'emocaImage.emocaIcon')}
                />
              </div>
            </Col>
          </Row>

          <Form.Item
            name="emocaMessage"
            label="メッセージ"
            rules={[{ required: true, message: 'メッセージは必須です。' }]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>

          <Form.Item
            name="empathyClap"
            label="共感拍手"
            rules={[
              { required: true, message: 'empathyClapが必要です。' },
              {
                validator: (_, v) => {
                  if (v >= 0) return Promise.resolve();
                  else return Promise.reject('0以上でなければなりません。!');
                },
              },
            ]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            name="numberOfAttachedMedal"
            label="メダル数"
            rules={[
              { required: true, message: 'メダルが必要です。' },
              {
                validator: (_, v) => {
                  if (v >= 0) return Promise.resolve();
                  else return Promise.reject('0以上でなければなりません。!');
                },
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={'エモカを選ぶ'}
        centered
        visible={modalEmocaVisible}
        forceRender={true}
        okText={constants.YES}
        cancelText={constants.CANCEL}
        onOk={updateEmocaImage}
        onCancel={() => setModalEmoca(false)}
        bodyStyle={{
          height: 400,
          overflowY: 'scroll',
        }}
      >
        {emocaLoading ? (
          <div
            style={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LoadingOutlined />
          </div>
        ) : (
          <Row gutter={[12, 12]}>
            {emcImgs.map((emoca, index) => (
              <Col
                span={6}
                key={index}
                onClick={() => onSelectEmocaImage(index, emoca.id)}
                className="cursor-pointer"
              >
                <img style={{ flex: 1 }} width="100%" src={emoca.image} />
                <img src={emoca.emocaIcon} className="emoca-detail-icon" />
                {emoca.selected && (
                  <CheckCircleOutlined
                    color="green"
                    style={{
                      position: 'absolute',
                      top: 15,
                      left: 15,
                    }}
                  />
                )}
              </Col>
            ))}
          </Row>
        )}
      </Modal>
    </>
  );
};

export default forwardRef(EmocaEditorModal);
