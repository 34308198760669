import { firebase } from 'commons/configs';
import constants from 'commons/constants';
import { last, get } from 'lodash';
import moment from 'moment-timezone';
const firestore = firebase.firestore();

const deleteEvent = async (
  { id, receiverId, senderId, medalCount } = {},
  fullObj
) => {
  const batch = firestore.batch();
  const ref = firestore.collection(constants.COLLECTION_EVENT).doc(id);
  const walletRef = firestore.collection(constants.COLLECTION_MEDAL_WALLET);

  if (!fullObj) {
    const { receiverId: rcId, senderId: seId, medalCount: mdCount } = await (
      await ref.get()
    ).data();

    receiverId = rcId;
    senderId = seId;
    medalCount = mdCount;
  }

  batch.update(ref, { isDeleted: true });
  if (medalCount > 0) {
    if (receiverId) {
      const receiverWalletRef = walletRef.doc(receiverId);
      const receiverWallet = await (await receiverWalletRef.get()).data();
      const currentBalance = get(receiverWallet, 'medalBalance', 0);
      batch.update(receiverWalletRef, {
        medalBalance: currentBalance - medalCount,
      });
    }

    if (senderId) {
      const senderWalletRef = walletRef.doc(senderId);
      const senderWallet = await (await senderWalletRef.get()).data();
      const currentBalance = get(senderWallet, 'medalBalance', 0);

      // Plus back for sender
      batch.update(senderWalletRef, {
        medalBalance: currentBalance + medalCount,
      });
    }
  }

  return batch.commit();
};

export const getEventHistories = async ({
  companyCode,
  eventCode,
  startDate,
  endDate,
  lastDoc,
} = {}) => {
  const eventRef = firestore.collection(constants.COLLECTION_EVENT);
  try {
    let query = eventRef;
    if (eventCode) query = query.where('eventCodeId', '==', eventCode);
    if (companyCode) query = query.where('companyCode', '==', companyCode);

    query = query.where('isDeleted', '==', false).orderBy('createdAt', 'desc');

    if (endDate) {
      query = query.startAt(
        moment
          .tz(endDate.format('YYYY-MM-DD'), 'Asia/Tokyo')
          .add(1, 'd')
          .toDate()
      );
    }

    if (startDate) {
      query = query.endAt(
        moment.tz(startDate.format('YYYY-MM-DD'), 'Asia/Tokyo').toDate()
      );
    }

    if (lastDoc) query = query.startAfter(lastDoc);
    const { docs } = await query.limit(constants.PAGE_LIMIT).get();
    const data = docs.map((e) => ({
      id: e.id,
      ...e.data(),
      createdAt: moment
        .tz(e.data().createdAt.toDate().getTime(), 'Asia/Tokyo')
        .format('YYYY-MM-DD HH:mm:ss'),
    }));

    return Promise.resolve({
      data,
      lastDoc: last(docs),
      canLoadMore: docs.length === constants.PAGE_LIMIT,
    });
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
};

export const getAllEventHistories = async ({
  companyCode,
  eventCode,
  startDate,
  endDate,
} = {}) => {
  const eventRef = firestore.collection(constants.COLLECTION_EVENT);
  try {
    let query = eventRef;
    if (eventCode) query = query.where('eventCodeId', '==', eventCode);
    if (companyCode) query = query.where('companyCode', '==', companyCode);

    query = query.where('isDeleted', '==', false).orderBy('createdAt', 'desc');

    if (endDate) {
      query = query.startAt(
        moment
          .tz(endDate.format('YYYY-MM-DD'), 'Asia/Tokyo')
          .add(1, 'd')
          .toDate()
      );
    }

    if (startDate) {
      query = query.endAt(
        moment.tz(startDate.format('YYYY-MM-DD'), 'Asia/Tokyo').toDate()
      );
    }

    const { docs } = await query.get();

    const data = docs.map((e) => ({
      id: e.id,
      ...e.data(),
      createdAt: moment
        .tz(e.data().createdAt.toDate().getTime(), 'Asia/Tokyo')
        .format('YYYY-MM-DD HH:mm:ss'),
    }));

    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteEventHistories = async (eventHistoryIds, isDeleteAll) => {
  const eventHistoryRef = firestore.collection(constants.COLLECTION_EVENT);
  if (isDeleteAll) {
    const { docs } = await eventHistoryRef
      .where('isDeleted', '==', false)
      .get();
    const promises = docs.map((doc) => deleteEvent(doc.data(), true));
    return Promise.all(promises);
  } else {
    const promises = eventHistoryIds.map((id) => deleteEvent({ id }, false));
    return Promise.all(promises);
  }
};

export const updateEventHistory = async ({
  id,
  medalCount,
  changedMedalCount,
  receiverId,
  senderId,
}) => {
  if (!id) return;
  const batch = firestore.batch();
  const eventRef = firestore.collection(constants.COLLECTION_EVENT).doc(id);
  const walletRef = firestore.collection(constants.COLLECTION_MEDAL_WALLET);

  batch.update(eventRef, { medalCount }); // Update for event history record

  if (receiverId) {
    const receiverWalletRef = walletRef.doc(receiverId);
    const receiverWallet = await (await receiverWalletRef.get()).data();
    const currentBalance = get(receiverWallet, 'medalBalance', 0);
    batch.update(receiverWalletRef, {
      medalBalance: currentBalance + changedMedalCount,
    });
  }

  if (senderId) {
    const senderWalletRef = walletRef.doc(senderId);
    const senderWallet = await (await senderWalletRef.get()).data();
    const currentBalance = get(senderWallet, 'medalBalance', 0);
    // Plus back for sender
    batch.update(senderWalletRef, {
      medalBalance: currentBalance - changedMedalCount,
    });
  }

  return batch.commit();
};
