import { firebase } from 'commons/configs';
import constants from 'commons/constants';
import { last, merge } from 'lodash';

import { getUserById, getGroupById } from 'services';

const firestore = firebase.firestore();

export const getMedalTransmissionHistory = async ({ searchText, lastDoc, companyCode } = {}) => {
  try {
    let query = firestore.collection(constants.COLLECTION_WALLET_HISTORY).where('isDeleted', '==', false);

    if (companyCode) query = query.where('companyCode', '==', companyCode);
    if (searchText) query = query.where('type', '==', searchText);

    query = query.orderBy('createdAt', 'desc');

    if (lastDoc) query = query.startAfter(lastDoc);

    const { docs } = await query.limit(constants.PAGE_LIMIT).get();
    const data = await getMedalTransmissionHistorySideData(docs);

    return {
      data,
      lastDoc: last(docs),
      canLoadMore: docs.length === constants.PAGE_LIMIT,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAllMedalTransmissionHistoryByCondition = async ({ searchText, companyCode } = {}) => {
  try {
    let query = firestore.collection(constants.COLLECTION_WALLET_HISTORY).where('isDeleted', '==', false);

    if (companyCode) query = query.where('companyCode', '==', companyCode);
    if (searchText) query = query.where('type', '==', searchText);

    query = query.orderBy('createdAt', 'desc');

    const { docs } = await query.get();
    const data = await getMedalTransmissionHistorySideData(docs);

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getMedalTransmissionHistorySideData = async (docs) => {
  return await Promise.all(
    docs.map(async (doc) => {
      const transmissionHistory = { ...doc.data(), id: doc.id };
      const { userId } = transmissionHistory;
      const user = await getUserById(userId);

      return {
        ...transmissionHistory,
        user,
      };
    })
  );
};

export const saveMedalTransmissionHistory = async (params) => {
  try {
    const { id, amount: newAmount, description, type } = params;

    const medalTransmissionRef = firestore.collection(constants.COLLECTION_WALLET_HISTORY).doc(id);
    if (type === constants.WALLET_HISTORY_TYPES.medalExchange) {
      const medalTransmission = (await medalTransmissionRef.get()).data();
      const { userId, amount } = medalTransmission;

      await firestore
        .collection(constants.COLLECTION_MEDAL_WALLET)
        .doc(userId)
        .update({
          medalBalance: firebase.firestore.FieldValue.increment(newAmount - amount),
        });
    }

    await medalTransmissionRef.update({ amount: newAmount, description });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteMedalTransmissionHistorys = async (ids, isDeleteAll, companyCode) => {
  const waletHistoryCollection = firestore.collection(constants.COLLECTION_WALLET_HISTORY);
  if (isDeleteAll) {
    let query = waletHistoryCollection.where('isDeleted', '==', false);

    if (companyCode) query = query.where('companyCode', '==', companyCode);

    const { docs } = await query.get();

    await Promise.all(
      docs.map(async (doc) => {
        await doc.ref.update({ isDeleted: true });
        await revertMedalForDeletedMedalTransmissionHistory(doc.id);
      })
    );
  } else {
    await Promise.all(
      ids.map(async (id) => {
        await waletHistoryCollection.doc(id).update({ isDeleted: true });
        await revertMedalForDeletedMedalTransmissionHistory(id);
      })
    );
  }
};

const revertMedalForDeletedMedalTransmissionHistory = async (id) => {
  const medalTransmissionDoc = await firestore.collection(constants.COLLECTION_WALLET_HISTORY).doc(id).get();
  const medalTransmission = medalTransmissionDoc.data();
  const { userId, type, amount } = medalTransmission;
  if (type === constants.WALLET_HISTORY_TYPES.medalExchange) {
    await firestore
      .collection(constants.COLLECTION_MEDAL_WALLET)
      .doc(userId)
      .update({
        medalBalance: firebase.firestore.FieldValue.increment(-amount),
      });
  }
};
