import React, { useState } from "react";
import { Form, Input, Button, Space } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { logo } from "assets/images";
import { sendEmailToResetPassword } from "services";
import { toast } from "react-toastify";
import { useHistory, Link } from "react-router-dom";
import "./style.scss";

function RequestResetPassword() {
	const history = useHistory();
	const [loading, setLoading] = useState();
	const onFinish = (formData) => {
		const { email } = formData;
		setLoading(true);
		sendEmailToResetPassword(email)
			.then(() => {
				toast.success(
					"パスワードのリセットメールがお客様のメールアドレスに送信されました。メールをご確認の上、パスワードを更新してください。ありがとうございました。"
				);
				setTimeout(() => history.push("/login"), 2000);
			})
			.catch((err) => toast.error(err.message))
			.finally(() => setLoading(false));
	};

	return (
		<div className="f-login-container">
			<Form
				name="emoca_resetpass"
				className="f-resetpass"
				onFinish={onFinish}
			>
				<Space direction="vertical" size="middle">
					<div className="logo">
						<img src={logo} />
					</div>
					<h2 className="f-title">パスワードを忘れた</h2>
					<Form.Item
						name="email"
						rules={[
							{
								required: true,
								message: "メールを入力してください。",
							},
							{
								type: "email",
								message:
									"有効なメールアドレスを入力してください。",
							},
						]}
					>
						<Input
							prefix={
								<MailOutlined className="site-form-item-icon" />
							}
							placeholder="電子メール"
						/>
					</Form.Item>

					<Form.Item>
						<Link to="/login">
                        アカウントを持っているのか？
						</Link>
					</Form.Item>

					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							className="login-form-button"
							loading={loading}
						>
							メールを送る
						</Button>
					</Form.Item>
				</Space>
			</Form>
		</div>
	);
}

export default RequestResetPassword;
