import * as Actions from '../actions';

import * as Services from 'services';
import { get, has } from 'lodash';

export const getGroup = ({ page, searchText, companyCode }) => async (dispatch, getState) => {
  dispatch({ type: Actions.GET_GROUP_REQUEST });
  try {
    const state = getState();
    const lastDocs = get(state, 'group.group.result.lastDocs');
    const nextPage = page + 1;

    const cursor = lastDocs.find((item) => item.page === page).lastDoc;

    const result = await Services.getGroup({ searchText, lastDoc: cursor, companyCode });
    const { data, lastDoc, canLoadMore } = result;

    dispatch({
      type: Actions.GET_GROUP_SUCCESS,
      payload: {
        data,
        lastDocs: [...lastDocs.filter((item) => item.page !== nextPage), { page: nextPage, lastDoc }],
        canLoadMore,
        page,
      },
    });
  } catch (error) {
    console.log(error)
    dispatch({ type: Actions.GET_GROUP_FAIL, error });
  }
};

export const saveGroup = (params) => async (dispatch, getState) => {
  dispatch({ type: Actions.SAVE_GROUP_REQUEST });
  try {
    await Services.saveGroup(params);

    dispatch({
      type: Actions.SAVE_GROUP_SUCCESS,
      payload: params
    });
  } catch (error) {
    dispatch({ type: Actions.SAVE_GROUP_SUCCESS, error });
  }
};
