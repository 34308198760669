import { firebase } from "commons/configs";
import constants from "commons/constants";
import { get, last, omit } from "lodash";
import moment from "moment-timezone";
import { rmUndefinedProperties } from "utils/common";

const firestore = firebase.firestore();
const addUser = firebase.functions().httpsCallable("addUser");

const getUserWithRole = async (userDoc) => {
	const roleRef = firestore.collection(constants.COLLECTION_ROLE);
	const user = userDoc.data();
	const userRole = get(user, "role", 1);
	const { docs: roles } = await roleRef
		.where("role", "==", userRole)
		.limit(1)
		.get();
	const role = roles.length > 0 ? roles[0].data() : {};

	return {
		id: userDoc.id,
		...user,
		createdAt: moment(user.createdAt.toDate()).format(
			constants.DATE_TIME_FORMAT
		),
		roleName: get(role, "name", "Default"),
	};
};

export const getUsers = async (
	companyCode,
	name,
	email,
	lastDoc,
	currentUserId
) => {
	return new Promise(async (resolve, reject) => {
		try {
			let userRef = firestore.collection(constants.COLLECTION_USER);

			if (companyCode)
				userRef = userRef.where("companyCode", "==", companyCode);

			if (name) userRef = userRef.where("name", "==", name);

			if (email) userRef = userRef.where("email", "==", email);

			userRef = userRef
				.where("isDeleted", "==", false)
				.orderBy("createdAt", "desc");

			if (lastDoc) {
				userRef = userRef.startAfter(lastDoc);
			}

			const { docs } = await userRef.limit(constants.PAGE_LIMIT).get();
			const users = await Promise.all(
				docs.map(async (d) => getUserWithRole(d))
      );
      const data = users.filter(d => d.id !== currentUserId);
			resolve({
				data,
				lastDoc: last(docs),
				canLoadMore: docs.length === constants.PAGE_LIMIT,
			});
		} catch (error) {
			console.log(error);
			reject(error);
		}
	});
};

export const getAllUserByCondition = async ({
	companyCode,
	name,
	email,
	uid,
} = {}) => {
	try {
		console.log(companyCode)
		let query = firestore.collection(constants.COLLECTION_USER);
		if (name) query = query.where("name", "==", name);
		if (email) query = query.where("email", "==", email);
		if (companyCode) query = query.where("companyCode", "==", companyCode);

		const { docs } = await query
			.where("isDeleted", "==", false)
			.orderBy("createdAt", 'desc')
			.get();

		return docs.map((doc) => ({
			...doc.data(),
			id: doc.id,
			createdAt: moment(doc.data().createdAt.toDate()).format(
				constants.DATE_TIME_FORMAT
			),
		}));
	} catch (error) {
		console.log(error);
	}
};

export const deleteUsers = async (userIds, isDeleteAll) => {
	const userRef = firestore.collection(constants.COLLECTION_USER);
	if (isDeleteAll) {
		const { docs } = await userRef.where("isDeleteAll", "==", false).get();
		const promises = docs.map((doc) => doc.ref.update({ isDeleted: true }));
		return Promise.all(promises);
	} else {
		const promise = userIds.map((id) => {
			userRef.doc(id).update({ isDeleted: true });
		});
		return Promise.all(promise);
	}
};

export const getUserById = async (id) => {
	const doc = await firestore
		.collection(constants.COLLECTION_USER)
		.doc(id)
		.get();
	return {
		...doc.data(),
		id: doc.id,
	};
};

export const addOrUpdateUser = async (user) => {
	const id = get(user, "id");
	const {
		avatar,
		companyCode,
		displayUserName,
		email,
		message,
		role,
		password = constants.DEFAULT_USER_PWD,
		layerId
	} = user;

	/**
	 * Update if id has existed
	 */

	if (!id) {
		const duplicateUser = await checkDuplicateByEmail(email);
		if (duplicateUser)
			return Promise.reject({ message: "Userは既に存在しています。" });

		const profileInfo = omit(rmUndefinedProperties({
			...user,
			isDeleted: false,
			role: role || 1,
			isCompleted: true,
			notification: true
		}), ['password']);

		return addUser({ email, password, profileInfo });
	}

	/**
	 * Otherwise add new theme
	 */
	return firestore.collection(constants.COLLECTION_USER).doc(id).update({
		avatar,
		companyCode,
		displayUserName,
		email,
		message,
		isDeleted: false,
		role,
		layerId
	});
};

export const checkDuplicateByEmail = async (email) => {
	const { docs } = await firestore
		.collection(constants.COLLECTION_USER)
		.where("email", "==", email)
		.where("isDeleted", "==", false)
		.limit(1)
		.get();

	return docs.length > 0;
};

export const addUserFromCSV = (users) => {
	const promises = users.map((user) => addOrUpdateUser(user));
	return Promise.all(promises);
};

export const getRoles = async ({isAdmin}) => {
	try {
		const { docs } = await firestore
			.collection(constants.COLLECTION_ROLE)
			.where("isDeleted", "==", false)
			.orderBy("role")
			.get();
		if(!isAdmin) return docs.map((role) => ({ id: role.id, ...role.data() })).filter(x => x.role !== constants.ROLES.admin);
		return docs.map((role) => ({ id: role.id, ...role.data() }));
	} catch (err) {
		console.log(err);
	}
};

export const getDLUsers = async ({ companyCode }) => {
	try {
		let query = firestore.collection(constants.COLLECTION_USER);
		if (companyCode) query = query.where("companyCode", "==", companyCode);

		const { docs } = await query
			.where("isDeleted", "==", false)
			.orderBy("createdAt", "desc")
			.get();

		const d = docs.map((doc) => ({ id: doc.id, ...doc.data() }));
		return d.filter(
			(x) =>
				![constants.ROLES.admin, constants.ROLES.company].includes(
					x.role
				)
		);
	} catch (error) {
		console.log(error);
	}
};
