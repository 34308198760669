import { firebase } from 'commons/configs';
import constants from 'commons/constants';
import { get, last } from 'lodash';
import moment from 'moment';
import { rmUndefinedProperties } from 'utils/common';
const firestore = firebase.firestore();

export const getCompanies = (name, code, lastDoc) => {
  return new Promise(async (resolve, reject) => {
    try {
      let query = firestore.collection(constants.COLLECTION_COMPANY);
      if (name) query = query.where('companyName', '==', name);
      if (code) query = query.where('companyCode', '==', code);

      query = query
        .where('isDeleted', '==', false)
        .orderBy('createdAt', 'desc');

      if (lastDoc) query = query.startAfter(lastDoc);

      const { docs } = await query.limit(constants.PAGE_LIMIT).get();

      const data = docs.map((cpn) => ({
        id: cpn.id,
        ...cpn.data(),
        createdAt: moment(cpn.data().createdAt.toDate()).format(
          constants.DATE_TIME_FORMAT
        ),
      }));

      resolve({
        data,
        lastDoc: last(docs),
        canLoadMore: docs.length === constants.PAGE_LIMIT,
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getAllCompanyByCondition = async ({ name, code } = {}) => {
  try {
    let query = firestore.collection(constants.COLLECTION_COMPANY);

    if (name) query = query.where('companyName', '==', name);
    if (code) query = query.where('companyCode', '==', code);

    const { docs } = await query
      .where('isDeleted', '==', false)
      .orderBy('createdAt', 'desc')
      .get();

    return docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      createdAt: moment(doc.data().createdAt.toDate()).format(
        constants.DATE_TIME_FORMAT
      ),
    }));
  } catch (error) {
    console.log(error);
  }
};

export const addOrUpdateCompany = async (company) => {
  const id = get(company, 'id');
  const {
    companyName = '',
    chargeLimit = 0,
    companyLogo = '',
    companyCode = '',
    initialBalance = 0,
  } = company;

  /**
   * Update if id has existed
   */
  if (!id) {
    const duplicateCompany = await checkDuplicateByCompanyCode(companyCode);
    if (duplicateCompany)
      return Promise.reject({ message: 'CompanyCodeは既に存在しています。' });

    // pickBy funtion used to remove underfine properties
    return firestore.collection(constants.COLLECTION_COMPANY).add(
      rmUndefinedProperties({
        ...company,
        isDeleted: false,
        createdAt: new Date(),
      })
    );
  }

  /**
   * Otherwise add new company
   */
  return firestore.collection(constants.COLLECTION_COMPANY).doc(id).update({
    companyName,
    chargeLimit,
    companyLogo,
    initialBalance,
  });
};

export const checkDuplicateByCompanyCode = async (companyCode) => {
  const { docs } = await firestore
    .collection(constants.COLLECTION_COMPANY)
    .where('companyCode', '==', companyCode)
    .where('isDeleted', '==', false)
    .limit(1)
    .get();

  return docs.length > 0;
};

export const deleteCompanies = async (companyIds, isDeleteAll) => {
  const companyRef = firestore.collection(constants.COLLECTION_COMPANY);
  if (isDeleteAll) {
    const { docs } = await companyRef.where('isDeleted', '==', false).get();
    const promises = docs.map((doc) => doc.ref.update({ isDeleted: true }));
    return Promise.all(promises);
  } else {
    const promises = companyIds.map((id) =>
      companyRef.doc(id).update({ isDeleted: true })
    );
    return Promise.all(promises);
  }
};

export const addCompanyFromCSV = (companies) => {
  const promises = companies.map((company) => addOrUpdateCompany(company));
  return Promise.all(promises);
};

export const getDLCompany = async () => {
  try {
    let query = firestore.collection(constants.COLLECTION_COMPANY);
    const { docs } = await query
      .where('isDeleted', '==', false)
      .orderBy('createdAt', 'desc')
      .get();

    return docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        companyCode: data.companyCode,
        name: data.companyName,
      };
    });
  } catch (error) {
    console.log(error);
  }
};
