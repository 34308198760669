import React from "react";
import { Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { login } from "redux-store/auth/actions";
import { logo } from "assets/images";
import { Link } from "react-router-dom";
import { get } from "lodash";
import "./Login.scss";

const Login = () => {
	const dispatch = useDispatch();
	const loading = useSelector((state) =>
		get(state, "auth.login.requesting", false)
	);
	const onFinish = (formData) => dispatch(login(formData));

	return (
		<div className="f-login-container">
			<Form name="emoca_login" className="f-login" onFinish={onFinish}>
				<div className="logo">
					<img src={logo} />
				</div>
				<Form.Item
					name="email"
					rules={[
						{
							required: true,
							message: "メールを入力してください。",
						},
						{
							type: "email",
							message: "有効なメールアドレスを入力してください。",
						},
					]}
				>
					<Input
						prefix={
							<UserOutlined className="site-form-item-icon" />
						}
						placeholder="電子メール"
					/>
				</Form.Item>
				<Form.Item
					name="password"
					rules={[
						{
							required: true,
							message: "パスワードを入力してください。",
						},
					]}
				>
					<Input
						prefix={
							<LockOutlined className="site-form-item-icon" />
						}
						type="password"
						placeholder="パスワード"
					/>
				</Form.Item>

				<Form.Item>
					<Link className="login-form-forgot" to='/forgot-password'>
						パスワードをお忘れですか？
					</Link>
				</Form.Item>

				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						className="login-form-button"
						loading={loading}
					>
						ログインする
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default Login;
