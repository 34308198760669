import * as Actions from '../actions';

import { getEventHistories } from 'services';
import { get } from 'lodash';

export const actGetEventHistories = ({
  page,
  companyCode,
  eventCode,
  startDate,
  endDate,
} = {}) => (dispatch, getState) => {
  dispatch({ type: Actions.GET_EVENT_HISTORIES });
  const state = getState();
  const lastDocs = get(state, 'eventHistory.events.lastDocs', []);
  const nextPage = page + 1;
  const lastDoc = lastDocs.find((item) => item.page === page).lastDoc;

  getEventHistories({ companyCode, eventCode, startDate, endDate, lastDoc })
    .then(({ data, lastDoc, canLoadMore }) => {
      dispatch({
        type: Actions.GET_EVENT_HISTORIES_SUCCESS,
        payload: {
          data,
          lastDocs: [
            ...lastDocs.filter((item) => item.page !== nextPage),
            { page: nextPage, lastDoc },
          ],
          canLoadMore,
          page,
        },
      });
    })
    .catch((error) =>
      dispatch({ type: Actions.GET_EVENT_HISTORIES_FAIL, error })
    );
};
