import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { get } from 'lodash';
import BlockUi from 'react-block-ui';
import { ToastContainer } from 'react-toastify';
import { DefaultLayout } from 'layouts';
import { Login, RequestResetPassword } from 'pages/shared';
import Constants from 'commons/constants';
import 'react-block-ui/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/scss/styles.scss';

function App() {
  const userLogin = useSelector((state) => get(state, 'auth.login.result'));
  const blocking = useSelector((state) => get(state, 'blockui.blocking'));
  const role = get(userLogin, 'role');

  const routePath = () => {
    if (role === Constants.ROLES.admin) return '/admin';
    else if (role === Constants.ROLES.company) return '/company';
    else return '/application';
  };

  return (
    <BrowserRouter>
      <BlockUi tag="div" blocking={blocking}>
        {userLogin ? (
          <Switch>
            <Route
              path={routePath()}
              render={(props) => (
                <DefaultLayout {...props} rolePath={routePath()} />
              )}
            />
            <Redirect from="/" to={routePath()} />
          </Switch>
        ) : (
          <Switch>
            <Route
              path="/forgot-password"
              render={(props) => <RequestResetPassword {...props} />}
            />
            <Redirect from="/" exact to={'/admin'} />
            <Redirect from="/admin" to="/login" />
            <Redirect from="/company" to="/login" />
            <Redirect from="/application" to="/login" />
            <Route path="/login" render={(props) => <Login {...props} />} />
          </Switch>
        )}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </BlockUi>
    </BrowserRouter>
  );
}

export default App;
