export default {
  DATE_FORMAT: 'YYYY/MM/DD',
  DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm',
  DATE_FILE_FORMAT: 'YYYY_MM_DD',
  COLLECTION_USER: 'User',
  COLLECTION_NAVISAPO: 'Navisapo',
  COLLECTION_NAVISAPO_IMAGE: 'NavisapoImage',
  COLLECTION_GROUP: 'Group',
  COLLECTION_EMOCA_IMAGE: 'EmocaImage',
  COLLECTION_THEME_CODE: 'Theme_Code',
  COLLECTION_THEME: 'Theme',
  COLLECTION_EMOCA: 'Emoca',
  COLLECTION_EXCHANGE: 'Exchange',
  COLLECTION_EVENT: 'Event',
  COLLECTION_EVENT_CODE: 'Event_Code',
  COLLECTION_STICKY_NOTE: 'StickyNote',
  COLLECTION_COMPANY: 'Company',
  COLLECTION_EMOCA_RECIPIENT: 'EmocaRecipient',
  COLLECTION_MEDAL_WALLET: 'Medal_Wallet',
  COLLECTION_WALLET_HISTORY: 'WalletHistory',
  COLLECTION_HISTORY: 'History',
  COLLECTION_GIVEAWAY: 'Giveaway',
  COLLECTION_EMOCA_RANKING: 'EmocaRanking',
  COLLECTION_ROLE: 'Roles',
  COLLECTION_REPORT: 'Report',
  COLLECTION_DAILY_REPORT: 'DailyReport',
  COLLECTION_LAYER_MASTER: 'LayerMaster',
  COLLECTION_ADMIN_SETTING: 'AdminSettings',
  ROLES: {
    admin: 6,
    company: 5,
  },
  PAGE_LIMIT: 100,
  FILE_LIMIT: 524288000, // bytes
  FILE_LIMIT_MSG: '最大500MBの制約があるので、',
  YES: 'はい',
  CANCEL: 'キャンセル',
  MODAL: {
    CONFIRM: '確認',
  },
  UPLOAD_PATH: {
    COMPANY: 'companies/',
    GIVEAWAY: 'giveaway-images/',
    GROUP: 'photos/avatar/',
    USER_AVATAR: 'photos/avatar/',
    EMOCA_ICON: 'emoca-icons/',
    EMOCA_IMAGE: 'emoca-images/',
    NAVISAPO: 'navigators/',
  },
  WALLET_HISTORY_TYPES: {
    giveaway: 'GIVE_AWAY',
    medalExchange: 'MEDAL_EXCHANGE',
    emoca: 'EMOCA',
  },
  DEFAULT_USER_PWD: '000000',
  TIME_ZONE: 'Asia/Tokyo',
  DISABLED_ROLES: [1, 5, 6],
  DISABLED_EVENTPRODUCTION: ["3", "5", "13", "14", "15", "16"]
};
