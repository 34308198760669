import * as Actions from '../actions';
import {
  getNavisapoData as getNavisapoService,
  deleteNavisapo as deleteNavisapoService,
} from 'services';
import { get } from 'lodash';

export const getNavisapos = ({ page, name } = {}) => (dispatch, getState) => {
  dispatch({ type: Actions.GET_NAVISAPO });
  const state = getState();
  const lastDocs = get(state, 'navisapo.navisapos.lastDocs', []);
  const nextPage = page + 1;
  const lastDoc = lastDocs.find((item) => item.page === page).lastDoc;
  return getNavisapoService(name, lastDoc)
    .then(({ data, lastDoc, canLoadMore }) => {
      dispatch({
        type: Actions.GET_NAVISAPO_SUCCESS,
        payload: {
          data,
          lastDocs: [
            ...lastDocs.filter((item) => item.page !== nextPage),
            { page: nextPage, lastDoc },
          ],
          canLoadMore,
          page,
        },
      });
    })
    .catch((error) => dispatch({ type: Actions.GET_NAVISAPO_FAIL, error }));
};

export const deteleNavisapos = (idArr) => (dispatch) => {
  dispatch({ type: Actions.DELETE_NAVISAPO });
  return deleteNavisapoService(idArr)
    .then((payload) => {
      dispatch({ type: Actions.DELETE_NAVISAPO_SUCCESS, payload });
    })
    .catch((err) => {
      console.log(err);
    });
};
