import * as Actions from '../actions';
import * as Services from 'services';
import { toast } from 'react-toastify';

export const login = (params) => async (dispatch) => {
  dispatch({ type: Actions.LOGIN_REQUEST });
  try {
    const data = await Services.login(params);
    dispatch({ type: Actions.LOGIN_SUCCESS, data });
  } catch (error) {
    let msg;
    switch (error.code) {
      case 'auth/invalid-email':
        msg = 'メールアドレスまたはパスワードが無効です。';
        break;
      default:
        msg = 'メールアドレスまたはパスワードが無効です。';
        break;
    }
    toast.error(msg);
    dispatch({ type: Actions.LOGIN_FAIL, error });
  }
};

export const logout = () => (dispatch) => {
  Services.logout();
  dispatch({ type: Actions.LOGOUT });
};

export const updateEmail = (email) => (dispatch) =>
  dispatch({ type: Actions.UPDATE_EMAIL, email });

export const updateDisplayName = (displayName) => (dispatch) =>
  dispatch({ type: Actions.UPDATE_DISPLAYNAME, displayName });
