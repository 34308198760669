import * as Actions from '../actions';

let initState = {
  emocaImages: {
    page: 1,
    data: [],
    lastDocs: [
      {
        page: 1,
        lastDoc: null,
      },
    ],
    loading: false,
  },
  emoca: {
    requesting: false,
    result: {
      data: [],
      lastDocs: [
        {
          page: 1,
          lastDoc: null,
        }
      ]
    },
    error: null,
    status: '',
  },
  savingEmoca: {
    requesting: false,
    result: null,
    error: null,
    status: '',
  }
};

export default function emocaReducers(state = initState, action) {
  switch (action.type) {
    case Actions.GET_EMOCA_IMAGES:
      return {
        ...state,
        emocaImages: {
          ...state.emocaImages,
          loading: true,
        },
      };

    case Actions.GET_EMOCA_IMAGES_SUCCESS:
      return {
        ...state,
        emocaImages: {
          loading: false,
          data: action.payload.data,
          lastDocs: action.payload.lastDocs,
          canLoadMore: action.payload.canLoadMore,
          page: action.payload.page,
        },
      };

    case Actions.GET_EMOCA_IMAGES_FAIL:
      return {
        ...state,
        emoca: {
          loading: false,
        },
      };

    case Actions.GET_EMOCA_REQUEST:
      return {
        ...state,
        emoca: {
          ...state.emoca,
          requesting: true,
          status: '',
        },
      };

    case Actions.GET_EMOCA_SUCCESS:
      return {
        ...state,
        emoca: {
          ...state.emoca,
          requesting: false,
          result: action.payload,
          status: 'success',
        },
      };

    case Actions.GET_EMOCA_FAIL:
      return {
        ...state,
        emoca: {
          ...state.emoca,
          requesting: false,
          error: action.error,
          status: 'error',
        },
      };
      case Actions.SAVE_EMOCA_REQUEST:
        return {
          ...state,
          savingEmoca: {
            ...state.savingEmoca,
            requesting: true,
            status: '',
          },
        };
  
      case Actions.SAVE_EMOCA_SUCCESS:
        const emocaData = state.emoca.result.data;
  
        return {
          ...state,
          savingEmoca: {
            ...state.savingEmoca,
            requesting: false,
            result: action.payload,
            status: 'success',
          },
          emoca: {
            ...state.emoca,
            result: {
              ...state.emoca.result,
              data: emocaData.map(item => {
                  if(item.id === action.payload.id) {
                    return {...item, ...action.payload}
                  }
                  return item
                })
            }
          },
        };
  
      case Actions.SAVE_EMOCA_FAIL:
        return {
          ...state,
          savingEmoca: {
            ...state.savingEmoca,
            requesting: false,
            error: action.error,
            status: 'error',
          },
        };

    default:
      return state;
  }
}
