import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, saveAdminSetting } from 'services';
import { get, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { EditOutlined, LoadingOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Row, Col, Descriptions, Button, Space, Radio, Form, DatePicker } from 'antd';
import { default_img } from 'assets/images';
import constants from 'commons/constants';
import moment from 'moment';
import ModalChangeEmail from './ModalChangeEmail';
import ModalChangePassword from './ModalChangePassword';
import ModalUpdateProfile from './ModalUpdateProfile';
import locale from 'antd/lib/date-picker/locale/ja_JP';
import { getAdminSetting } from 'services';
import {updateDisplayName} from 'redux-store/auth/actions';

function MyProfile() {
  const mdChangeEmail = React.useRef();
  const mdChangePassword = React.useRef();
  const mdUpdateProfile = React.useRef();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [profile, setProfile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [showBaseDate, setShowBaseDate] = React.useState(false);
  const [setting, setSetting] = React.useState({});
  const [loadingSetting, setLoadingSetting] = React.useState(false);

  const { id, role, companyCode } = useSelector((state) => get(state, 'auth.login.result', {}));

  const getRoleText = () => {
    switch (role) {
      case 5:
        return `${companyCode}の管理者`;
      case 6:
        return '管理者';
    }
  };

  const isAdmin = role === constants.ROLES.admin;

  useEffect(() => {
    if (isAdmin)
      getAdminSetting().then((setting) => {
        setSetting(setting);
        if (setting?.resetScoreTiming && !isEmpty(setting?.resetScoreTiming))
          setShowBaseDate(setting?.resetScoreTiming !== 'monthly');

        form.setFieldsValue({
          ...setting,
          baseDate: setting?.baseDate
            ? moment(setting.baseDate, constants.DATE_FORMAT)
            : moment(new Date(), constants.DATE_FORMAT),
        });
      });
  }, [isAdmin]);

  const getInfo = () => {
    setLoading(true);
    getProfile(id)
      .then((profile) => {
        setProfile(profile);
        dispatch(updateDisplayName(profile?.displayUserName));
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getInfo();
  }, []);

  const onTimeChanged = ({ target: { value = 'monthly' } = {} } = {}) => setShowBaseDate(value !== 'monthly');

  const onSettingSubmit = ({ resetScoreTiming, baseDate }) => {
    setLoadingSetting(true);
    saveAdminSetting(id, {
      resetScoreTiming,
      baseDate: baseDate ? baseDate.format(constants.DATE_FORMAT) : '',
    })
      .then(() => toast.success('セーブ設定成功!'))
      .catch(() => toast.error('設定の保存エラー!'))
      .finally(() => setLoadingSetting(false));
  };

  return (
    <div className="admin-container">
      <h1>私のプロフィール</h1>
      <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 20 }, 0]}>
        <Col xs={24} md={24} sm={24} lg={4}>
          <div>
            <img width="100%" src={profile?.avatar || default_img} />
          </div>
        </Col>
        <Col xs={24} md={24} sm={24} lg={20}>
          <h1>{profile?.displayUserName}</h1>
          {loading ? (
            <LoadingOutlined size={35} />
          ) : (
            <>
              <Descriptions column={1}>
                <Descriptions.Item label="電子メール">{profile?.email}</Descriptions.Item>
                {role !== constants.ROLES.admin && (
                  <Descriptions.Item label="Company Code">{profile?.companyCode}</Descriptions.Item>
                )}

                <Descriptions.Item label="役割">{getRoleText()}</Descriptions.Item>
                <Descriptions.Item label="創造の時">
                  {moment(profile?.createdAt.toDate()).format(constants.DATE_TIME_FORMAT)}
                </Descriptions.Item>
              </Descriptions>

              <Space direction="vertical" size={2}>
                <Button
                  onClick={() => mdChangeEmail.current.show()}
                  type="link"
                  style={{ marginTop: 15 }}
                  icon={<MailOutlined />}
                >
                  メールの変更
                </Button>

                <Button
                  onClick={() => mdChangePassword.current.show()}
                  type="link"
                  style={{ marginTop: 15 }}
                  icon={<LockOutlined />}
                >
                  パスワードのリセット
                </Button>

                <Button
                  onClick={() => mdUpdateProfile.current.show({ ...profile })}
                  style={{ marginTop: 15 }}
                  type="primary"
                  icon={<EditOutlined />}
                >
                  プロフィールの編集
                </Button>
              </Space>
            </>
          )}
        </Col>
      </Row>
      
      <>
        <h1 style={{ marginTop: 15 }}>スコア設定</h1>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 20 }, 0]}>
          <Col xs={24} md={24} sm={24} lg={4} />
          <Col xs={24} md={24} sm={24} lg={20}>
            <Form
              form={form}
              onFinish={onSettingSubmit}
              name="f-settings"
              layout="vertical"
              initialValues={{
                resetScoreTiming: 'monthly',
                baseDate: moment(new Date(), constants.DATE_FORMAT),
              }}
            >
              <Form.Item label="リセットスコアのタイミング" name="resetScoreTiming">
                <Radio.Group onChange={onTimeChanged}>
                  <Radio value={'monthly'}>月間</Radio>
                  <Radio value={'quarterly'}>四半期</Radio>
                  <Radio value={'half-yearly'}>半期</Radio>
                  <Radio value={'yearly'}>まいとし</Radio>
                </Radio.Group>
              </Form.Item>
              {showBaseDate && (
                <Form.Item
                  label="基準日"
                  name="baseDate"
                  required
                  rules={[{ required: true, message: '基準日が必要です' }]}
                >
                  <DatePicker locale={locale} />
                </Form.Item>
              )}
              <Form.Item>
                <Button htmlType="submit" style={{ marginTop: 15 }} type="primary" loading={loadingSetting}>
                  保存
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
      
      <ModalChangeEmail ref={mdChangeEmail} onUpdateFinish={getInfo} />
      <ModalChangePassword ref={mdChangePassword} />
      <ModalUpdateProfile ref={mdUpdateProfile} onUpdateFinish={getInfo} />
    </div>
  );
}

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

export default MyProfile;
