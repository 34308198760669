import React from 'react';
import { Modal, Form, Input } from 'antd';
import constants from 'commons/constants';
import { useDispatch } from 'react-redux';
import { changeEmail } from 'services';
import { updateEmail } from 'redux-store/auth/actions';
import { toast } from 'react-toastify';

const ModalChangeEmail = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const modalRef = React.useRef();
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const setEmail = () => {
    const email = form.getFieldValue('email');
    setLoading(true);
    changeEmail(email)
      .then((email) => {
        setLoading(false);
        dispatch(updateEmail(email));
        toast.success('Email has been updated succesfully!');
        props.onUpdateFinish && props.onUpdateFinish();
        hide();
      })
      .catch((err) => {
        setLoading(false);

        switch (err.code) {
          case 'auth/requires-recent-login':
            toast.error('Required Re-Login to change your email.');
            return;
          default:
            toast.error(err.message);
            return;
        }
      });
  };

  React.useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  return (
    <div ref={modalRef}>
      <Modal
        forceRender={true}
        title={'メールの変更'}
        centered
        visible={visible}
        okText={constants.YES}
        cancelText={constants.CANCEL}
        okButtonProps={{
          form: 'fchange-email',
          key: 'submit',
          htmlType: 'submit',
        }}
        confirmLoading={loading}
        onCancel={hide}
      >
        <Form form={form} onFinish={setEmail} name="fchange-email">
          <Form.Item
            label="新しいメールを入力してください"
            name="email"
            rules={[
              { required: true, message: 'メールは必須です。' },
              { type: 'email', message: '電子メールは無効です。' },
            ]}
          >
            <Input placeholder="電子メール" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default ModalChangeEmail;
