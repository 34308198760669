import { firebase } from 'commons/configs';
import constants from 'commons/constants';
import { last, get } from 'lodash';
import moment from 'moment-timezone';
const firestore = firebase.firestore();

export const getThemeHistories = async ({
  companyCode,
  eventCode,
  themeCode,
  userId,
  startDate,
  endDate,
  lastDoc,
}) => {
  const ref = firestore.collection(constants.COLLECTION_THEME);
  try {
    let query = ref;
    if (eventCode) query = query.where('eventCode', '==', eventCode);
    if (companyCode) query = query.where('companyCode', '==', companyCode);
    if (themeCode) query = query.where('themeCode', '==', themeCode);
    if (userId) query = query.where('userId', '==', userId);

    query = query.where('isDeleted', '==', false).orderBy('createdAt', 'desc');

    if (endDate) {
      query = query.startAt(
        moment
          .tz(endDate.format('YYYY-MM-DD'), 'Asia/Tokyo')
          .add(1, 'd')
          .toDate()
      );
    }

    if (startDate) {
      query = query.endAt(
        moment.tz(startDate.format('YYYY-MM-DD'), 'Asia/Tokyo').toDate()
      );
    }

    if (lastDoc) query = query.startAfter(lastDoc);
    const { docs } = await query.limit(constants.PAGE_LIMIT).get();
    const data = docs.map((e) => ({
      id: e.id,
      ...e.data(),
      createdAt: moment(e.data().createdAt.toDate()).format(
        'YYYY-MM-DD HH:mm:ss'
      ),
    }));

    return Promise.resolve({
      data,
      lastDoc: last(docs),
      canLoadMore: docs.length === constants.PAGE_LIMIT,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getThemeHistoriesExport = async ({
  companyCode,
  eventCode,
  themeCode,
  userId,
  startDate,
  endDate,
}) => {
  const ref = firestore.collection(constants.COLLECTION_THEME);
  try {
    let query = ref;
    if (eventCode) query = query.where('eventCode', '==', eventCode);
    if (companyCode) query = query.where('companyCode', '==', companyCode);
    if (themeCode) query = query.where('themeCode', '==', themeCode);
    if (userId) query = query.where('userId', '==', userId);

    query = query.where('isDeleted', '==', false).orderBy('createdAt', 'desc');

    if (endDate) {
      query = query.startAt(
        moment
          .tz(endDate.format('YYYY-MM-DD'), 'Asia/Tokyo')
          .add(1, 'd')
          .toDate()
      );
    }

    if (startDate) {
      query = query.endAt(
        moment.tz(startDate.format('YYYY-MM-DD'), 'Asia/Tokyo').toDate()
      );
    }

    const { docs } = await query.get();
    const data = docs.map((e) => ({
      id: e.id,
      ...e.data(),
      createdAt: moment(e.data().createdAt.toDate()).format(
        'YYYY-MM-DD HH:mm:ss'
      ),
    }));

    return Promise.resolve(data);
  } catch (err) {
    console.log(err);
  }
};

export const deleteThemeHistories = async (eventHistoryIds, isDeleteAll) => {
  const ref = firestore.collection(constants.COLLECTION_THEME);
  if (isDeleteAll) {
    const { docs } = await ref.where('isDeleted', '==', false).get();
    const promises = docs.map((doc) =>
      ref.doc(doc.id).update({ isDeleted: true })
    );
    return Promise.all(promises);
  } else {
    const promises = eventHistoryIds.map((id) =>
      ref.doc(id).update({ isDeleted: true })
    );
    return Promise.all(promises);
  }
};

export const updateThemeHistory = ({
  id,
  companyCode,
  eventCode,
  themeCode,
  userId,
}) => {
  return firestore.collection(constants.COLLECTION_THEME).doc(id).update({
    companyCode,
    eventCode,
    themeCode,
    userId,
  });
};
