import { firebase } from 'commons/configs';
import Constants from 'commons/constants';
const Auth = firebase.auth();
const Firestore = firebase.firestore();

const updateEmailForUser = (id, email) => {
  return Firestore.collection(Constants.COLLECTION_USER)
    .doc(id)
    .update({ email });
};

export const login = async ({ email, password }) => {
  const userCredential = await Auth.signInWithEmailAndPassword(email, password);
  const { user } = userCredential;

  const doc = await Firestore.collection(Constants.COLLECTION_USER)
    .doc(user.uid)
    .get();
  const profile = doc.data();

  return {
    ...profile,
    uid: user.uid,
    email: user.email,
  };
};

export const logout = () => {
  firebase.auth().signOut();
};

export const getProfile = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const profile = await Firestore.collection(Constants.COLLECTION_USER)
        .doc(userId)
        .get();
      resolve(profile.data());
    } catch (err) {
      reject(err);
    }
  });
};

export const changeEmail = (email) => {
  const user = Auth.currentUser;
  return user
    .updateEmail(email)
    .then(() => updateEmailForUser(user.uid, email))
    .then(() => Promise.resolve(email));
};

export const changePassword = async (currentPassword, newPassword) => {
  const currentUser = Auth.currentUser;
  const { user } = await Auth.signInWithEmailAndPassword(
    currentUser.email,
    currentPassword
  );
  if (user) return currentUser.updatePassword(newPassword);
  else throw 404;
};

export const updateProfile = ({
  id,
  notification = false,
  message,
  displayUserName,
  avatar,
}) => {
  return Firestore.collection(Constants.COLLECTION_USER).doc(id).update({
    notification,
    message,
    displayUserName,
    avatar,
  });
};

export const sendEmailToResetPassword = (email) => {
  return Auth.sendPasswordResetEmail(email);
}
