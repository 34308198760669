import * as Actions from '../actions';

let initState = {
  themes: {
    page: 1,
    data: [],
    lastDocs: [
      {
        page: 1,
        lastDoc: null,
      },
    ],
    loading: false,
  },
};

export default function themeHistoryReducers(state = initState, action) {
  switch (action.type) {
    case Actions.GET_THEME_HISTORIES:
      return {
        ...state,
        themes: {
          ...state.themes,
          loading: true,
        },
      };

    case Actions.GET_THEME_HISTORIES_SUCCESS:
      return {
        ...state,
        themes: {
          loading: false,
          data: action.payload.data,
          lastDocs: action.payload.lastDocs,
          canLoadMore: action.payload.canLoadMore,
          page: action.payload.page,
        },
      };

    case Actions.GET_THEME_HISTORIES_FAIL:
      return {
        ...state,
        themes: {
          loading: false,
        },
      };

    default:
      return state;
  }
}
