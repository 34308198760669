import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useState,
} from 'react';
import { Modal, Form, InputNumber } from 'antd';
import useStateCallback from 'use-state-with-callback';
import constants from 'commons/constants';
import { toast } from 'react-toastify';
import { updateEventHistory } from 'services';
import { get } from 'lodash';

const AdjustMedalModal = forwardRef((props, ref) => {
  const modalRef = useRef();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [visible, setVisible] = useStateCallback(false, (visible) => {
    if (!visible) setEventHistory(null);
  });

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const [eventHistory, setEventHistory] = useStateCallback(
    null,
    (eventHistory) => {
      const { id, medalCount } = eventHistory || {};
      form.setFieldsValue({
        id,
        medalCount,
      });
    }
  );

  const show = (company) => {
    setEventHistory(company);
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
    form.resetFields(); // reset form
  };

  const onOK = async () => {
    const { id, senderId, receiverId } = eventHistory || {};
    const oldMedalCount = get(eventHistory, 'medalCount', 0);
    const medalCount = form.getFieldValue('medalCount');
    if (medalCount !== oldMedalCount) {
      // Update medal count and history
      const changedMedalCount = medalCount - oldMedalCount;
      setLoading(true);
      updateEventHistory({
        id,
        receiverId,
        senderId,
        medalCount,
        changedMedalCount,
      })
        .then(() => {
          setLoading(false);
          toast.success('メダル数を調整しました。');
          props.onUpdated && props.onUpdated();
          hide();
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.message);
        });
    } else hide();
  };

  return (
    <div ref={modalRef}>
      <Modal
        forceRender={true}
        title={'メダルの数を調整する'}
        centered
        visible={visible}
        okText={constants.YES}
        cancelText={constants.CANCEL}
        okButtonProps={{
          form: 'fevent-history',
          key: 'submit',
          htmlType: 'submit',
        }}
        confirmLoading={loading}
        onCancel={hide}
      >
        <Form
          form={form}
          name="fevent-history"
          onFinish={onOK}
          labelCol={{ span: 7 }}
          labelAlign="left"
        >
          <Form.Item
            name="medalCount"
            label="メダル数"
            rules={[
              { required: true, message: 'メダルカウントが必要です。' },
              {
                validator: (_, v) => {
                  if (v >= 0) return Promise.resolve();
                  else
                    return Promise.reject(
                      'チャージの上限は0以上でなければならない!'
                    );
                },
              },
            ]}
          >
            <InputNumber type="number" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default AdjustMedalModal;
