import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { Modal, Form, Input, Select } from 'antd';
import constants from 'commons/constants';
import { addOrUpdateUser } from 'services';
import { toast } from 'react-toastify';
import useStateCallback from 'use-state-with-callback';
import { ImageUpload } from 'components';
import { get } from 'lodash';
import { getRoles } from 'redux-store/users/action';
import { getLayerDropdown } from 'redux-store/layer-master/action';
import { useDispatch, useSelector } from 'react-redux';

const UserAddOrEditModal = forwardRef((props, ref) => {
  const inputRef = useRef();
  const uploadRef = useRef();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { data = [] } = useSelector((state) => get(state, 'user.roles'));
  const { data: layers = [] } = useSelector((state) =>
    get(state, 'layer.dropdown')
  );
  const auth = useSelector((state) => get(state, 'auth.login.result', {}));
  const isAdmin = get(auth, 'role', 1) === constants.ROLES.admin;

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const { onUpdated } = props;
  const [visible, setVisible] = useStateCallback(false, (visible) => {
    if (!visible) setUser(null);
  });

  const [user, setUser] = useStateCallback(null, (user) => {
    const {
      id,
      avatar,
      companyCode,
      displayUserName,
      email,
      message,
      role,
      layerId,
    } = user || {};
    form.setFieldsValue({
      id,
      avatar,
      companyCode,
      displayUserName,
      email,
      message,
      role: (role && '' + role) || '1',
      layerId,
    });
  });

  const [loading, setLoading] = useState(false);
  const isAddNew = get(user, 'id', null) === null;

  const show = (user) => {
    dispatch(getLayerDropdown());
    setUser(user);
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
    form.resetFields(); // reset form
    uploadRef.current.resetStates();
  };

  useEffect(() => {
    dispatch(getRoles({ isAdmin }));
  }, []);

  const onOK = async () => {
    const id = get(user, 'id');
    const {
      companyCode,
      displayUserName,
      email,
      message,
      role,
      password,
      layerId,
    } = form.getFieldsValue();

    form
      .validateFields()
      .then(() => uploadRef.current.upload(constants.UPLOAD_PATH.USER_AVATAR))
      .then((url) => {
        setLoading(true);
        return addOrUpdateUser({
          id: get(user, 'id'),
          companyCode,
          displayUserName,
          email,
          avatar: url,
          message,
          role: +role,
          password,
          layerId,
        });
      })
      .then(() => {
        let msg = id
          ? 'ユーザーは正常に更新されました！'
          : 'ユーザーが追加されました！';

        toast.success(msg);
        setLoading(false);
        onUpdated && onUpdated();
        hide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
        hide();
      });
  };

  return (
    <div ref={inputRef}>
      <Modal
        width={700}
        forceRender={true}
        title={isAddNew ? '会社を追加' : 'エディットカンパニー'}
        centered
        visible={visible}
        okText={constants.YES}
        cancelText={constants.CANCEL}
        okButtonProps={{ form: 'cpn-form', key: 'submit', htmlType: 'submit' }}
        confirmLoading={loading}
        onCancel={hide}
      >
        <Form
          form={form}
          name="cpn-form"
          onFinish={onOK}
          labelCol={{ span: 7 }}
          labelAlign="left"
        >
          <ImageUpload
            ref={uploadRef}
            url={user?.avatar}
            wraperStyle={{
              borderRadius: '50%',
            }}
            imageStyle={{
              width: '100%',
            }}
          />

          <Form.Item
            name="companyCode"
            label="会社コード"
            rules={[{ required: true, message: '会社コードが必要です。' }]}
          >
            <Input disabled={!isAddNew} />
          </Form.Item>

          <Form.Item
            name="email"
            label="Eメール"
            rules={[{ required: true, message: 'メールアドレスが必要です。' }]}
          >
            <Input disabled={!isAddNew} />
          </Form.Item>

          {isAddNew && (
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: 'メールアドレスが必要です。' },
              ]}
            >
              <Input.Password />
            </Form.Item>
          )}

          <Form.Item
            name="displayUserName"
            label="ユーザー名"
            rules={[{ required: true, message: 'ユーザー名は必須です。' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="message"
            label="メッセージ"
            rules={[{ required: true, message: 'メッセージが必要です。' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="role"
            label="役割"
            rules={[{ required: true, message: '役割が必要です。' }]}
          >
            <Select placeholder="役割を選択する" style={{ width: '100%' }}>
              {data.length > 0 &&
                data.map((role) => (
                  <Select.Option key={role.role}>{role.name}</Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="layerId"
            label="レイヤーを選択"
            rules={[{ required: true, message: '必須です。' }]}
          >
            <Select placeholder="レイヤーを選択" style={{ width: '100%' }}>
              {layers.length > 0 &&
                layers.map((layer) => (
                  <Select.Option key={layer.id}>{layer.label}</Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default UserAddOrEditModal;
