import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createEncryptor from 'redux-persist-transform-encrypt'

import rootReducer from './reducers';

const encryptor = createEncryptor({
  secretKey: 'emoca-secret-key',
  onError: function(error) {
    console.log('Encryptor error: ', error)
  }
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
  transforms: [encryptor]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunkMiddleware];

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware)));
const persistor = persistStore(store);

export { store, persistor };
