import * as Actions from '../actions';

let initialState = {
  layers: {
    page: 1,
    data: [],
    lastDocs: [
      {
        page: 1,
        lastDoc: null,
      },
    ],
    loading: false,
  },
  dropdown: {
    data: [],
    loading: false,
  },
};

export default function layerReducers(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_LAYERS:
      return {
        ...state,
        layers: {
          ...state.layers,
          loading: true,
        },
      };
    case Actions.GET_LAYERS_SUCCESS:
      return {
        ...state,
        layers: {
          ...state.layers,
          loading: false,
          data: action.payload.data,
          lastDocs: action.payload.lastDocs,
          canLoadMore: action.payload.canLoadMore,
          page: action.payload.page,
        },
      };
    case Actions.GET_LAYERS_FAIL:
      return {
        ...state,
        layers: {
          loading: false,
        },
      };

    case Actions.GET_ALL_LAYERS:
      return {
        ...state,
        dropdown: {
          ...state.dropdown,
          loading: true,
        },
      };
    case Actions.GET_ALL_LAYERS_SUCCESS:
      return {
        ...state,
        dropdown: {
          ...state.dropdown,
          loading: false,
          data: action.data,
        },
      };
    case Actions.GET_ALL_LAYERS_FAIL:
      return {
        ...state,
        dropdown: {
          loading: false,
        },
      };

    default:
      return state;
  }
}
