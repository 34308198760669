import * as Actions from '../actions';
import { get, uniqBy } from 'lodash';

let initState = {
  reportByDates: {
    status: '',
    result: {
      data: [],
    },
    error: null,
    requesting: false,
  },
  generalReport: {
    status: '',
    result: null,
    error: null,
    requesting: false,
  },
  averageReport: {
    result: {},
    requesting: false,
  },
};

export default function reportReducer(state = initState, action) {
  switch (action.type) {
    case Actions.GET_REPORT_FOR_DATES_REQUEST:
      return {
        ...state,
        reportByDates: {
          ...state.reportByDates,
          requesting: true,
          status: '',
        },
      };

    case Actions.GET_REPORT_FOR_DATES_SUCCESS:
      return {
        ...state,
        reportByDates: {
          ...state.reportByDates,
          requesting: false,
          result: action.data.isLoadMore
            ? {
                ...action.data,
                data: uniqBy([
                  ...get(state, 'reportByDates.result.data', []),
                  ...action.data.data,
                ], 'id'),
              }
            : action.data,
          status: 'success',
        },
      };

    case Actions.GET_REPORT_FOR_DATES_FAIL:
      return {
        ...state,
        reportByDates: {
          ...state.reportByDates,
          requesting: false,
          error: action.error,
          status: 'error',
        },
      };

    case Actions.GET_GENERAL_REPORT_REQUEST:
      return {
        ...state,
        generalReport: {
          ...state.generalReport,
          requesting: true,
          status: '',
        },
      };

    case Actions.GET_GENERAL_REPORT_SUCCESS:
      return {
        ...state,
        generalReport: {
          ...state.generalReport,
          requesting: false,
          result: action.data,
          status: 'success',
        },
      };

    case Actions.GET_GENERAL_REPORT_FAIL:
      return {
        ...state,
        generalReport: {
          ...state.generalReport,
          requesting: false,
          error: action.error,
          status: 'error',
        },
      };

    case Actions.GET_AVERAGE_REPORT_REQUEST:
      return {
        ...state,
        averageReport: {
          ...state.averageReport,
          requesting: true,
        },
      };
    case Actions.GET_AVERAGE_REPORT_SUCCESS:
      return {
        ...state,
        averageReport: {
          ...state.averageReport,
          requesting: false,
          result: action.data,
        },
      };

    case Actions.GET_AVERAGE_REPORT_FAIL:
      return {
        ...state,
        averageReport: {
          ...state.averageReport,
          requesting: false,
        },
      };
    default:
      return state;
  }
}
