import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import { get } from 'lodash';
import { logout } from 'redux-store/auth/actions';
import { QuestionCircleOutlined } from '@ant-design/icons';
import './style.scss';

function AuthenticatedHeader(props) {
  const dispatch = useDispatch();

  const displayName = useSelector((state) =>
    get(state, 'auth.login.result.displayUserName')
  );

  const signOut = () => dispatch(logout());

  return (
    <div className="header-container auth-header">
      <Link className="display-name" to={`${props.rolePath}/profile`}>{displayName}</Link>

      <Popconfirm
        icon={<QuestionCircleOutlined />}
        placement="bottom"
        title={'必ずログアウトしていますか？'}
        onConfirm={signOut}
        okText="はい"
        cancelText="キャンセル"
      >
        <Button type="link" danger>
          ログアウト
        </Button>
      </Popconfirm>
    </div>
  );
}

export default AuthenticatedHeader;
