import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import { Modal, Form, Input, Select } from 'antd';
import constants from 'commons/constants';
import { addOrUpdateLayer } from 'services';
import { toast } from 'react-toastify';
import useStateCallback from 'use-state-with-callback';
import { get } from 'lodash';

const AddOrEditDepartmentModal = forwardRef((props, ref) => {
  const inputRef = useRef();
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const { onUpdated, companies = [] } = props;
  const [visible, setVisible] = useStateCallback(false, (visible) => {
    if (!visible) setLayer(null);
  });

  const [layer, setLayer] = useStateCallback(null, (layer = {}) => {
    const { id, companyCode, layer1, layer2, layer3 } = layer || {};
    form.setFieldsValue({ id, layer1, layer2, layer3, companyCode });
  });

  const [loading, setLoading] = useState(false);
  const isAddNew = get(layer, 'id', null) === null;

  const show = (layer) => {
    setLayer(layer);
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
    form.resetFields();
  };

  const onOK = async () => {
    const { id, layer1, layer2, layer3, companyCode } = form.getFieldsValue();
    setLoading(true);
    return addOrUpdateLayer({ id, layer1, layer2, layer3, companyCode })
      .then(() => {
        let msg = id ? '更新成功!' : '成功の追加!';

        toast.success(msg);
        setLoading(false);
        onUpdated && onUpdated();
        hide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
        hide();
      });
  };

  return (
    <div ref={inputRef}>
      <Modal
        forceRender={true}
        title={isAddNew ? '新規追加' : '編集'}
        centered
        visible={visible}
        okText={constants.YES}
        cancelText={constants.CANCEL}
        okButtonProps={{ form: 'dptm-form', key: 'submit', htmlType: 'submit' }}
        confirmLoading={loading}
        onCancel={hide}
      >
        <Form
          form={form}
          name="dptm-form"
          onFinish={onOK}
          labelCol={{ span: 7 }}
          labelAlign="left"
        >
          <Form.Item
            name="id"
            style={{
              display: 'none',
            }}
          >
            <Input hidden={true} />
          </Form.Item>

          <Form.Item
            name="companyCode"
            label="会社コード"
            rules={[{ required: true, message: '必須です。' }]}
          >
            <Select placeholder="会社コード" style={{ width: '100%' }}>
              {companies.length > 0 &&
                companies.map((cpn) => (
                  <Select.Option key={cpn.companyCode}>
                    {cpn.name} ({cpn.companyCode})
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="layer1"
            label="レイヤ1"
            rules={[{ required: true, message: '必須です。' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="layer2"
            label="レイヤ2"
            rules={[{ required: true, message: '必須です。' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="layer3" label="レイヤ3">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default AddOrEditDepartmentModal;
