import { firebase } from 'commons/configs';
import constants from 'commons/constants';
import { get, pickBy, last } from 'lodash';
import moment from 'moment';
import { rmUndefinedProperties } from 'utils/common';

const firestore = firebase.firestore();

export const getThemeData = async (themeText, lastDoc) => {
  return new Promise(async (resolve, reject) => {
    try {
      let themeRef = firestore.collection(constants.COLLECTION_THEME_CODE);

      if (themeText) {
        themeRef = themeRef.where('themeText', '==', themeText);
      }
      themeRef = themeRef.where('isDeleted', '==', false);

      if (lastDoc) themeRef = themeRef.startAfter(lastDoc);

      const { docs } = await themeRef.limit(constants.PAGE_LIMIT).get();
      const data = docs.map((theme) => ({
        id: theme.id,
        ...theme.data(),
      }));
      resolve({
        data,
        lastDoc: last(docs),
        canLoadMore: docs.length === constants.PAGE_LIMIT,
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getAllThemeByCondition = async ({ themeText } = {}) => {
  try {
    let query = firestore.collection(constants.COLLECTION_THEME_CODE);

    if (themeText) query = query.where('themeText', '==', themeText);
    const { docs } = await query
      .where('isDeleted', '==', false)
      .orderBy('createdAt', 'desc')
      .get();
    const data = docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      createdAt: moment(doc.data().createdAt.toDate()).format(
        constants.DATE_TIME_FORMAT
      ),
    }));
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteThemes = async (themeIds, isDeleteAll) => {
  const themeRef = firestore.collection(constants.COLLECTION_THEME_CODE);
  if (isDeleteAll) {
    const { docs } = await themeRef.where('isDeleted', '==', false).get();
    const promises = docs.map((doc) => doc.ref.update({ isDeleted: true }));
    return Promise.all(promises);
  } else {
    const promises = themeIds.map((id) =>
      themeRef.doc(id).update({ isDeleted: true })
    );
    return Promise.all(promises);
  }
};

export const addOrUpdateTheme = async (theme) => {
  const id = get(theme, 'id');
  const { eventCode, themeCode, themeText } = theme;

  /**
   * Update if id has existed
   */

  if (!id) {
    const duplicateTheme = await checkDuplicateByThemeCode(themeCode);
    if (duplicateTheme)
      return Promise.reject({ message: 'ThemeCodeは既に存在しています。' });

    // pickBy funtion used to remove underfine properties
    return firestore.collection(constants.COLLECTION_THEME_CODE).add(
      rmUndefinedProperties(
        {
          ...theme,
          isDeleted: false,
          createdAt: new Date(),
        }
        // (v) => v !== undefined
      )
    );
  }

  /**
   * Otherwise add new theme
   */
  return firestore.collection(constants.COLLECTION_THEME_CODE).doc(id).update({
    eventCode,
    themeCode,
    themeText,
    isDeleted: false,
  });
};

export const checkDuplicateByThemeCode = async (themeCode) => {
  const { docs } = await firestore
    .collection(constants.COLLECTION_THEME_CODE)
    .where('themeCode', '==', themeCode)
    .where('isDeleted', '==', false)
    .limit(1)
    .get();

  return docs.length > 0;
};

export const addThemeFromCSV = (themes) => {
  const promises = themes.map((theme) => addOrUpdateTheme(theme));
  return Promise.all(promises);
};
