import React, { useEffect } from 'react';
import { ic_good, ic_nogood, ic_verygood, spinner } from 'assets/images';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { getAverageReport } from 'redux-store/report/actions';
import {
  LoadingOutlined
} from '@ant-design/icons';
import './styles.scss';

function DashboardRanking() {
  const dispatch = useDispatch();
  const manager = useSelector((state) => get(state, 'auth.login.result'));
  const companyCode = get(manager, 'companyCode');

  const { result = {}, requesting = false } = useSelector((state) =>
    get(state, 'report.averageReport')
  );

  const getStatus = () => {
    const point = get(result, 'percentage', 0);
    if (point <= 95)
      return {
        icon: ic_nogood,
        text: 'No Good',
      };

    if (point <= 110)
      return {
        icon: ic_good,
        text: 'Good',
      };

    if (point > 110)
      return {
        icon: ic_verygood,
        text: 'Very Good',
      };
  };

  const { icon, text } = getStatus();

  useEffect(() => {
    dispatch(getAverageReport({ companyCode }));
  }, []);

  return (
    <>
      {
        <div className="ranking">
          <div>
            <img src={requesting ? spinner: icon} alt="" />
          </div>
          <div className="status">
            <div>本日のスコア診断</div>
            <div className="st-text">{requesting ? <LoadingOutlined spin style={{
              fontSize: 23,
              color: '#d8aa73'
            }}/> : <span>{text}</span>}</div>
            <div>
            {requesting ? <LoadingOutlined spin style={{
              fontSize: 16,
              color: '#d8aa73'
            }}/> : <span>{result?.percentage || 0}%</span>}

            </div>
          </div>
        </div>
      }
    </>
  );
}

export default DashboardRanking;
