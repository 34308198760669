import React, { useRef, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { Space, Button } from 'antd';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';

function CSVExportButton(props) {
  let linkRef = useRef(null);
  const { data, headers, fetchCSVData, fetched } = props;
  const readyToExport = data && data.length > 0;

  useEffect(() => {
    if (fetched && linkRef.link) linkRef.link.click();
    // Auto clicked download when loaded data
  }, [fetched]);

  if (!headers && !data) return null;

  return (
    <>
      <CSVLink
        ref={(ref) => (linkRef = ref)}
        style={{
          display: readyToExport ? 'block' : 'none',
        }}
        data={data}
        headers={props.headers}
        filename={props.fileName}
        className="ant-btn ant-btn-primary"
        target="_blank"
      >
        <Space>
          <VerticalAlignBottomOutlined size={40} />
          csv出力する
        </Space>
      </CSVLink>
      <Button
        style={{
          display: !readyToExport ? 'block' : 'none',
        }}
        type="primary"
        onClick={fetchCSVData}
      >
        <Space>
          <VerticalAlignBottomOutlined size={40} />
          csv出力する
        </Space>
      </Button>
    </>
  );
}

export default CSVExportButton;
