import * as Actions from '../actions';
import { getThemeHistories } from 'services';
import { get } from 'lodash';

export const actGetThemeHistories = ({
  page,
  companyCode,
  eventCode,
  themeCode,
  userId,
  startDate,
  endDate,
} = {}) => (dispatch, getState) => {
  dispatch({ type: Actions.GET_THEME_HISTORIES });
  const state = getState();
  const lastDocs = get(state, 'themeHistory.themes.lastDocs', []);
  const nextPage = page + 1;
  const lastDoc = lastDocs.find((item) => item.page === page).lastDoc;

  getThemeHistories({
    companyCode,
    eventCode,
    themeCode,
    userId,
    startDate,
    endDate,
    lastDoc,
  })
    .then(({ data, lastDoc, canLoadMore }) => {
      dispatch({
        type: Actions.GET_THEME_HISTORIES_SUCCESS,
        payload: {
          data,
          lastDocs: [
            ...lastDocs.filter((item) => item.page !== nextPage),
            { page: nextPage, lastDoc },
          ],
          canLoadMore,
          page,
        },
      });
    })
    .catch((error) =>
      dispatch({ type: Actions.GET_THEME_HISTORIES_FAIL, error })
    );
};
