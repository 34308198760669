import * as Actions from '../actions';

let initialState = {
  roles: {
    page: 1,
    data: [],
    lastDocs: [
      {
        page: 1,
        lastDoc: null,
      },
    ],
    loading: false,
  },
};

export default function roleReducers(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ROLES:
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: true,
        },
      };
    case Actions.GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: false,
          data: action.payload.data,
          lastDocs: action.payload.lastDocs,
          canLoadMore: action.payload.canLoadMore,
          page: action.payload.page,
        },
      };
    case Actions.GET_ROLES_FAIL:
      return {
        ...state,
        roles: {
          loading: false,
        },
      };
    default:
      return state;
  }
}
