import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import { Modal, Form, Input, InputNumber } from 'antd';
import useStateCallback from 'use-state-with-callback';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { saveGiveawayHistory } from 'redux-store/giveaways/action';
import constants from 'commons/constants';
import { has, get } from 'lodash';

const GiveawayHistoryEditorModal = ({ onSaved }, ref) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useStateCallback(false, (val) => {
    if (!val) {
      setGiveawayHistory(null);
    }
  });

  const setFormValues = (giveawayHistory) => {
    if (giveawayHistory) {
      const { id, amount, description } = giveawayHistory;
      form.setFieldsValue({ id, amount, description });
    } else {
      form.resetFields();
    }
  };

  const [giveawayHistory, setGiveawayHistory] = useStateCallback(null, setFormValues);
  const [requesting, setRequesting] = useState();
  const savingGiveawayHistory = useSelector((state) => get(state, 'giveaway.savingGiveawayHistory'));

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const hide = () => {
    setModalVisible(false);
  };

  const show = (giveawayHistory) => {
    setModalVisible(true);
    setGiveawayHistory(giveawayHistory);
  };

  useEffect(() => {
    const { status } = savingGiveawayHistory;
    if (status === 'success') {
      toast.success('景品の履歴を正常に保存します。');
    } else if (status === 'error') {
      toast.error('何かがうまくいかなかった。');
    }
  }, [savingGiveawayHistory]);

  const submitEmocaForm = async () => {
    try {
      setRequesting(true);
      const values = form.getFieldsValue(['id', 'amount', 'description']);
      await form.validateFields();
      await dispatch(saveGiveawayHistory(values));
      hide();
    } catch (error) {
      console.log(error);
      toast.error('何かがうまくいかなかった。');
    } finally {
      setRequesting(false);
    }
  };

  return (
    <Modal
      title={'景品の履歴を編集する'}
      centered
      visible={modalVisible}
      forceRender={true}
      okText={constants.YES}
      cancelText={constants.CANCEL}
      onOk={submitEmocaForm}
      okButtonProps={{
        disabled: requesting,
      }}
      cancelButtonProps={{
        disabled: requesting,
      }}
      // confirmLoading={confirmLoading}
      onCancel={hide}
    >
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} form={form} labelAlign="left">
        <Form.Item name="amount" label="	量" rules={[{ required: true, message: '量は必須です。' }]}>
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item name="description" label="解説" rules={[{ required: true, message: '解説は必須です。' }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default forwardRef(GiveawayHistoryEditorModal);
