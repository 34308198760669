// Auth
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_DISPLAYNAME = 'UPDATE_DISPLAYNAME';

export const GET_REPORT_FOR_DATES_REQUEST = 'GET_REPORT_FOR_DATES_REQUEST';
export const GET_REPORT_FOR_DATES_SUCCESS = 'GET_REPORT_FOR_DATES_SUCCESS';
export const GET_REPORT_FOR_DATES_FAIL = 'GET_REPORT_FOR_DATES_FAIL';

export const GET_GENERAL_REPORT_REQUEST = 'GET_GENERAL_REPORT_REQUEST';
export const GET_GENERAL_REPORT_SUCCESS = 'GET_GENERAL_REPORT_SUCCESS';
export const GET_GENERAL_REPORT_FAIL = 'GET_GENERAL_REPORT_FAIL';

export const GET_AVERAGE_REPORT_REQUEST = 'GET_AVERAGE_REPORT_REQUEST';
export const GET_AVERAGE_REPORT_SUCCESS = 'GET_AVERAGE_REPORT_SUCCESS';
export const GET_AVERAGE_REPORT_FAIL = 'GET_AVERAGE_REPORT_FAIL';

// Users
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const GET_DROPDOWNLIST_USERS = 'GET_DROPDOWNLIST_USERS';
export const GET_DROPDOWNLIST_USERS_SUCCESS = 'GET_DROPDOWNLIST_USERS_SUCCESS';
export const GET_DROPDOWNLIST_USERS_FAIL = 'GET_DROPDOWNLIST_USERS_FAIL';

// Company
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAIL = 'GET_COMPANIES_FAIL';

export const GET_DROPDOWNLIST_COMPANIES = 'GET_DROPDOWNLIST_COMPANIES';
export const GET_DROPDOWNLIST_COMPANIES_SUCCESS = 'GET_DROPDOWNLIST_COMPANIES_SUCCESS';
export const GET_DROPDOWNLIST_COMPANIES_FAIL = 'GET_DROPDOWNLIST_COMPANIES_FAIL';

// Themes
export const GET_THEME_CODE = 'GET_THEME_CODE';
export const GET_THEME_CODE_SUCCESS = 'GET_THEME_CODE_SUCCESS';
export const GET_THEME_CODE_FAIL = 'GET_THEME_CODE_FAIL';

export const DELETE_THEME_CODE = 'DELETE_THEME_CODE';
export const DELETE_THEME_CODE_SUCCESS = 'DELETE_THEME_CODE_SUCCESS';
export const DELETE_THEME_CODE_FAIL = 'DELETE_THEME_CODE_FAIL';

export const GET_GIVEAWAY_REQUEST = 'GET_GIVEAWAY_REQUEST';
export const GET_GIVEAWAY_SUCCESS = 'GET_GIVEAWAY_SUCCESS';
export const GET_GIVEAWAY_FAIL = 'GET_GIVEAWAY_FAIL';

export const GET_GIVEAWAY_HISTORY_REQUEST = 'GET_GIVEAWAY_HISTORY_REQUEST';
export const GET_GIVEAWAY_HISTORY_SUCCESS = 'GET_GIVEAWAY_HISTORY_SUCCESS';
export const GET_GIVEAWAY_HISTORY_FAIL = 'GET_GIVEAWAY_HISTORY_FAIL';

export const GET_MEDAL_TRANSMISSION_HISTORY_REQUEST = 'GET_MEDAL_TRANSMISSION_HISTORY_REQUEST';
export const GET_MEDAL_TRANSMISSION_HISTORY_SUCCESS = 'GET_MEDAL_TRANSMISSION_HISTORY_SUCCESS';
export const GET_MEDAL_TRANSMISSION_HISTORY_FAIL = 'GET_MEDAL_TRANSMISSION_HISTORY_FAIL';

export const SAVE_MEDAL_TRANSMISSION_HISTORY_REQUEST = 'SAVE_MEDAL_TRANSMISSION_HISTORY_REQUEST';
export const SAVE_MEDAL_TRANSMISSION_HISTORY_SUCCESS = 'SAVE_MEDAL_TRANSMISSION_HISTORY_SUCCESS';
export const SAVE_MEDAL_TRANSMISSION_HISTORY_FAIL = 'SAVE_MEDAL_TRANSMISSION_HISTORY_FAIL';

// Navisapo
export const GET_NAVISAPO = 'GET_NAVISAPO';
export const GET_NAVISAPO_SUCCESS = 'GET_NAVISAPO_SUCCESS';
export const GET_NAVISAPO_FAIL = 'GET_NAVISAPO_FAIL';

export const DELETE_NAVISAPO = 'DELETE_NAVISAPO';
export const DELETE_NAVISAPO_SUCCESS = 'DELETE_NAVISAPO_SUCCESS';
export const DELETE_NAVISAPO_FAIL = 'DELETE_NAVISAPO_FAIL';
export const SAVE_GIVEAWAY_REQUEST = 'SAVE_GIVEAWAY_REQUEST';
export const SAVE_GIVEAWAY_SUCCESS = 'SAVE_GIVEAWAY_SUCCESS';
export const SAVE_GIVEAWAY_FAIL = 'SAVE_GIVEAWAY_FAIL';

export const SAVE_GIVEAWAY_HISTORY_REQUEST = 'SAVE_GIVEAWAY_HISTORY_REQUEST';
export const SAVE_GIVEAWAY_HISTORY_SUCCESS = 'SAVE_GIVEAWAY_HISTORY_SUCCESS';
export const SAVE_GIVEAWAY_HISTORY_FAIL = 'SAVE_GIVEAWAY_HISTORY_FAIL';

// Event Code
export const GET_EVENT_CODES = 'GET_EVENT_CODES';
export const GET_EVENT_CODES_SUCCESS = 'GET_EVENT_CODES_SUCCESS';
export const GET_EVENT_CODES_FAIL = 'GET_EVENT_CODES_FAIL';

// Company
export const GET_EMOCA_IMAGES = 'GET_EMOCA_IMAGES';
export const GET_EMOCA_IMAGES_SUCCESS = 'GET_EMOCA_IMAGES_SUCCESS';
export const GET_EMOCA_IMAGES_FAIL = 'GET_EMOCA_IMAGES_FAIL';

// Emoca
export const GET_EMOCA_REQUEST = 'GET_EMOCA_REQUEST';
export const GET_EMOCA_SUCCESS = 'GET_EMOCA_SUCCESS';
export const GET_EMOCA_FAIL = 'GET_EMOCA_FAIL';

export const SAVE_EMOCA_REQUEST = 'SAVE_EMOCA_REQUEST';
export const SAVE_EMOCA_SUCCESS = 'SAVE_EMOCA_SUCCESS';
export const SAVE_EMOCA_FAIL = 'SAVE_EMOCA_FAIL';

// Event History
export const GET_EVENT_HISTORIES = 'GET_EVENT_HISTORIES';
export const GET_EVENT_HISTORIES_SUCCESS = 'GET_EVENT_HISTORIES_SUCCESS';
export const GET_EVENT_HISTORIES_FAIL = 'GET_EVENT_HISTORIES_FAIL';

// Theme History
export const GET_THEME_HISTORIES = 'GET_THEME_HISTORIES';
export const GET_THEME_HISTORIES_SUCCESS = 'GET_THEME_HISTORIES_SUCCESS';
export const GET_THEME_HISTORIES_FAIL = 'GET_EVENT_HISTORIES_FAIL';

// Role
export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAIL = 'GET_ROLES_FAIL';
//Group
export const GET_GROUP_REQUEST = 'GET_GROUP_REQUEST';
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export const GET_GROUP_FAIL = 'GET_GROUP_FAIL';

export const SAVE_GROUP_REQUEST = 'SAVE_GROUP_REQUEST';
export const SAVE_GROUP_SUCCESS = 'SAVE_GROUP_SUCCESS';
export const SAVE_GROUP_FAIL = 'SAVE_GROUP_FAIL';

// Layer Master
export const GET_LAYERS = 'GET_LAYERS';
export const GET_LAYERS_SUCCESS = 'GET_LAYERS_SUCCESS';
export const GET_LAYERS_FAIL = 'GET_LAYERS_FAIL';

export const GET_ALL_LAYERS = 'GET_ALL_LAYERS';
export const GET_ALL_LAYERS_SUCCESS = 'GET_ALL_LAYERS_SUCCESS';
export const GET_ALL_LAYERS_FAIL = 'GET_ALL_LAYERS_FAIL';
