import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import { Modal, Form, Input } from 'antd';
import constants from 'commons/constants';
import { addOrUpdateTheme } from 'services';
import { toast } from 'react-toastify';
import useStateCallback from 'use-state-with-callback';
import { get } from 'lodash';

const ThemeAddOrEditModal = forwardRef((props, ref) => {
  const inputRef = useRef();
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const { onUpdated } = props;
  const [visible, setVisible] = useStateCallback(false, (visible) => {
    if (!visible) setTheme(null);
  });

  const [theme, setTheme] = useStateCallback(null, (theme) => {
    const { id, eventCode, themeCode, themeText } = theme || {};
    form.setFieldsValue({
      id,
      eventCode,
      themeCode,
      themeText,
    });
  });

  const [loading, setLoading] = useState(false);
  const isAddNew = get(theme, 'id', null) === null;

  const show = (theme) => {
    setTheme(theme);
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
    form.resetFields(); // reset form
  };

  const onOK = async () => {
    const { id, eventCode, themeCode, themeText } = form.getFieldsValue([
      'id',
      'eventCode',
      'themeCode',
      'themeText',
      'isDeleted',
    ]);
    form
      .validateFields()
      .then(() => {
        setLoading(true);
        return addOrUpdateTheme({
          id,
          eventCode,
          themeCode,
          themeText,
          isDeleted: false,
        });
      })
      .then(() => {
        let msg = id ? 'テーマが更新されました！' : 'テーマが追加されました！';

        toast.success(msg);
        setLoading(false);
        onUpdated && onUpdated();
        hide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
        hide();
      });
  };

  return (
    <div ref={inputRef}>
      <Modal
        forceRender={true}
        title={isAddNew ? 'テーマを追加' : 'テーマを編集する'}
        centered
        visible={visible}
        okText={constants.YES}
        cancelText={constants.CANCEL}
        okButtonProps={{ form: 'cpn-form', key: 'submit', htmlType: 'submit' }}
        confirmLoading={loading}
        onCancel={hide}
      >
        <Form
          form={form}
          name="cpn-form"
          onFinish={onOK}
          labelCol={{ span: 7 }}
          labelAlign="left"
        >
          <Form.Item
            name="eventCode"
            label="イベントコード"
            rules={[{ required: true, message: 'イベントコードが必要です。' }]}
          >
            <Input disabled={!isAddNew} />
          </Form.Item>

          <Form.Item
            name="themeCode"
            label="テーマコード"
            rules={[{ required: true, message: 'テーマコードが必要です。' }]}
          >
            <Input disabled={!isAddNew} />
          </Form.Item>
          <Form.Item
            name="themeText"
            label="テーマテキスト"
            rules={[{ required: true, message: 'テーマテキストは必須です。' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default ThemeAddOrEditModal;
