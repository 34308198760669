import React from "react";
import {
	UserManagement,
	GroupManagement,
	GiveawayManagement,
	ThemeManagement,
	AdminCompanyManagement,
	NavisapoManagement,
	CompanyManager,
	EventCodeManagement,
	EmocaImageManagement,
	EmocaHistory,
	EventHistory,
	CompleteThemeHistory,
	GiveawayHistory,
	MedalTransmissionHistory,
	RoleManagement,
	DepartmentLayer
} from "pages";

import { RequestResetPassword } from "pages/shared";

// ============= Shared pages ====================
const Login = React.lazy(() => import("pages/shared/login/Login"));

// ============= Admin pages ====================
const Admin = React.lazy(() => import("pages/admin/dashboard"));
const MyProfile = React.lazy(() => import("pages/shared/profile/MyProfile"));
// ============= Employee pages ====================

export const adminRoutes = [
	{
		path: "/admin",
		name: "Dashboard",
		component: Admin,
		exact: true,
	},
	{
		path: "/admin/profile",
		name: "Profile",
		component: MyProfile,
		exact: true,
	},
	{
		path: "/admin/users",
		name: "Users",
		component: UserManagement,
		exact: true,
	},
	{
		path: "/admin/groups",
		name: "Users",
		component: GroupManagement,
		exact: true,
	},
	{
		path: "/admin/company",
		name: "AdminCompany",
		component: AdminCompanyManagement,
		exact: true,
	},
	{
		path: "/admin/themes",
		name: "Users",
		component: ThemeManagement,
		exact: true,
	},
	{
		path: "/admin/giveaways",
		name: "Users",
		component: GiveawayManagement,
		exact: true,
	},
	{
		path: "/admin/navisapo-settings",
		name: "Users",
		component: NavisapoManagement,
		exact: true,
	},
	{
		path: "/admin/events",
		name: "EventCodeManagement",
		component: EventCodeManagement,
		exact: true,
	},
	{
		path: "/admin/emolcas",
		name: "EmocaImageManagement",
		component: EmocaImageManagement,
		exact: true,
	},
	{
		path: "/admin/emolca-history",
		name: "EmocaHistory",
		component: EmocaHistory,
		exact: true,
	},
	{
		path: "/admin/event-history",
		name: "EventHistory",
		component: EventHistory,
		exact: true,
	},
	{
		path: "/admin/theme-history",
		name: "ThemeHistory",
		component: CompleteThemeHistory,
		exact: true,
	},
	{
		path: "/admin/giveaway-history",
		name: "GiveawayHistory",
		component: GiveawayHistory,
		exact: true,
	},
	{
		path: "/admin/medal-transmission-history",
		name: "MedalTransmissionHistory",
		component: MedalTransmissionHistory,
		exact: true,
	},
	{
		path: "/admin/roles",
		name: "RoleManagement",
		component: RoleManagement,
		exact: true,
	},
	{
		path: '/admin/department-layers',
		component: DepartmentLayer,
		exact: true
	}
];

export const companyRotes = [
	{
		path: "/company",
		name: "Dashboard",
		component: Admin,
		exact: true,
	},
	{
		path: "/company/profile",
		name: "Profile",
		component: MyProfile,
		exact: true,
	},
	{
		path: "/company/users",
		name: "Users",
		component: UserManagement,
		exact: true,
	},
	{
		path: "/company/groups",
		name: "Groups",
		component: GroupManagement,
		exact: true,
	},
	{
		path: "/company/themes",
		name: "Themes",
		component: ThemeManagement,
		exact: true,
	},
	{
		path: "/company/navisapo-settings",
		name: "Users",
		component: NavisapoManagement,
		exact: true,
	},
	{
		path: "/company/company",
		name: "Company",
		component: CompanyManager,
		exact: true,
	},
	{
		path: "/company/emolca-history",
		name: "EmocaHistory",
		component: EmocaHistory,
		exact: true,
	},
	{
		path: "/company/event-history",
		name: "EventHistory",
		component: EventHistory,
		exact: true,
	},
	{
		path: "/company/giveaway-history",
		name: "GiveawayHistory",
		component: GiveawayHistory,
		exact: true,
	},
	{
		path: "/company/events",
		name: "EventCodeManagement",
		component: EventCodeManagement,
		exact: true,
	},
	{
		path: "/company/medal-transmission-history",
		name: "MedalTransmissionHistory",
		component: MedalTransmissionHistory,
		exact: true,
	},
	{
		path: "/company/roles",
		name: "RoleManagement",
		component: RoleManagement,
		exact: true,
	},
	{
		path: "/company/emolcas",
		name: "EmocaImageManagement",
		component: EmocaImageManagement,
		exact: true,
	},
	{
		path: '/company/department-layers',
		component: DepartmentLayer,
		exact: true
	}
];

export const applicationRoutes = [
	{
		path: "/application/emolcas",
		name: "EmocaImageManagement",
		component: EmocaImageManagement,
		exact: true,
	},
	{
		path: "/application/giveaways",
		name: "Giveaway",
		component: GiveawayManagement,
		exact: true,
	},
];

export const publicRoutes = [
	{
		path: "/login",
		name: "Login",
		component: Login,
		layout: "/auth",
	},
	{
		path: "/forgot-password",
		name: "ForgotPassword",
		component: RequestResetPassword,
		layout: "/auth",
	},
];

const routes = [
	...adminRoutes,
	...companyRotes,
	...applicationRoutes,
	...publicRoutes,
];

export default routes;
