import { firebase } from 'commons/configs';
import constants from 'commons/constants';
import { get, last } from 'lodash';
import moment from 'moment';
import { rmUndefinedProperties } from 'utils/common';

const firestore = firebase.firestore();
const roleRef = firestore.collection(constants.COLLECTION_ROLE);

export const getRoleData = async (roleName, lastDoc) => {
  return new Promise(async (resolve, reject) => {
    try {
      let query = roleRef;
      if (roleName) query = query.where('name', '==', roleName);

      query = query
        .where('isDeleted', '==', false)
        .orderBy('createdAt', 'desc');

      if (lastDoc) query = query.startAfter(lastDoc);
      const { docs } = await query.limit(constants.PAGE_LIMIT).get();
      const data = docs.map((roles) => ({
        id: roles.id,
        ...roles.data(),
      }));

      resolve({
        data,
        lastDoc: last(docs),
        canLoadMore: docs.length === constants.PAGE_LIMIT,
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllRoleByCondition = async ({ roleName } = {}) => {
  try {
    let query = roleRef;
    if (roleName) query = query.where('name', '==', roleName);

    const { docs } = await query
      .where('isDeleted', '==', false)
      .orderBy('createdAt', 'desc')
      .get();

    const data = docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      createdAt: moment(doc.data().createdAt.toDate()).format(
        constants.DATE_TIME_FORMAT
      ),
    }));

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteRoles = async (roleIds, isDeleteAll) => {
  if (isDeleteAll) {
    const { docs } = await roleRef.where('isDeleted', '==', false).get();
    const roles = docs.filter(
      (x) => constants.DISABLED_ROLES.indexOf(get(x.data(), 'role', 1)) < 0
    );
    const promises = roles.map((doc) => doc.ref.update({ isDeleted: true }));
    return Promise.all(promises);
  } else {
    const promises = roleIds.map((id) =>
      roleRef.doc(id).update({ isDeleted: true })
    );
    return Promise.all(promises);
  }
};

export const addOrUpdateRole = async (roles) => {
  const id = get(roles, 'id');
  const { name, role, text } = roles;

  /**
   * Add new role
   */
  if (!id) {
    const duplicateRole = await checkDuplicateByRole(role);
    if (duplicateRole)
      return Promise.reject({
        message: '役割はすでに存在しています。',
      });

    // pickBy funtion used to remove underfine properties
    return roleRef.add(
      rmUndefinedProperties({
        ...roles,
        isDeleted: false,
        createdAt: new Date(),
      })
    );
  }

  /**
   * Update if id has existed
   */
  return roleRef.doc(id).update({
    name,
    role,
    text,
    isDeleted: false,
  });
};

export const checkDuplicateByRole = async (role) => {
  try {
    const { docs } = await roleRef
      .where('role', '==', role)
      .where('isDeleted', '==', false)
      .limit(1)
      .get();
    return docs.length > 0;
  } catch (err) {
    return false;
  }
};

export const addRoleFromCSV = (roles) => {
  const promises = roles.map((role) => addOrUpdateRole(role));
  return Promise.all(promises);
};
