import * as Actions from '../actions';

let initState = {
  events: {
    page: 1,
    data: [],
    lastDocs: [
      {
        page: 1,
        lastDoc: null,
      },
    ],
    loading: false,
  },
};

export default function eventHistoryReducers(state = initState, action) {
  switch (action.type) {
    case Actions.GET_EVENT_HISTORIES:
      return {
        ...state,
        events: {
          ...state.events,
          loading: true,
        },
      };

    case Actions.GET_EVENT_HISTORIES_SUCCESS:
      return {
        ...state,
        events: {
          loading: false,
          data: action.payload.data,
          lastDocs: action.payload.lastDocs,
          canLoadMore: action.payload.canLoadMore,
          page: action.payload.page,
        },
      };

    case Actions.GET_EVENT_HISTORIES_FAIL:
      return {
        ...state,
        events: {
          loading: false,
        },
      };

    default:
      return state;
  }
}
