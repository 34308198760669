import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  Space,
  Avatar,
  Modal,
  Form,
  Radio,
  Input,
  Empty,
  Checkbox,
} from 'antd';
import {
  DeleteFilled,
  ExclamationCircleOutlined,
  SearchOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { ic_default_user } from 'assets/images';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from 'redux-store/users/action';
import { get, uniq } from 'lodash';
import constants from 'commons/constants';
import { CSVExportButton, Pagination, CSVImport } from 'components';
import { getAllUserByCondition, addUserFromCSV, deleteUsers } from 'services';
import moment from 'moment';
import { toast } from 'react-toastify';
import UserAddOrEditModal from './UserAddOrEditModal';
import { blockUI, unblockUI } from 'redux-store/blocking-ui/action';
import './style.scss';

function UserManagement() {
  const today = moment(new Date()).format(constants.DATE_FILE_FORMAT);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const editModalRef = useRef();

  /**
   * States
   */
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowKeys, setSelectedRows] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [fetchedExportData, setFetchedExportData] = useState(false);
  const [page, setPage] = useState(1);

  const auth = useSelector((state) => get(state, 'auth.login.result', {}));
  const companyCode = get(auth, 'companyCode');
  const isAdmin = get(auth, 'role') === constants.ROLES.admin;

  const {
    data: users = [],
    loading: getUserLoading,
    canLoadMore,
  } = useSelector((state) => get(state, 'user.users', {}));

  /**
   * Get users
   * @param {*} formValues
   */
  const getUserData = (formValues) => {
    let email, name;
    let cpnCode = companyCode;
    const { searchby, term, companyCode: CpnSlection } = formValues;
    name = searchby === 'name' ? term : null;
    email = searchby === 'email' ? term : null;
    cpnCode = CpnSlection || companyCode;
    dispatch(getUsers({ page, cpnCode, name, email }));
  };

  /**
   * Delete selected users
   */
  const ondeleteUsers = () => {
    dispatch(blockUI());
    deleteUsers(selectedRowKeys, selectAll)
      .then(() => {
        setSelectedRows([]);
        toast.success('ユーザーが削除されました。');
        form.submit();
      })
      .catch((err) => {
        toast.error(err);
        console.log(err);
      })
      .finally(() => dispatch(unblockUI()));
  };

  /**
   * Show confirm delete modal
   */
  const confirmDelete = () => {
    Modal.confirm({
      centered: true,
      title: constants.MODAL.CONFIRM,
      icon: <ExclamationCircleOutlined />,
      content: 'ユーザーを削除してもよろしいですか？',
      okText: constants.YES,
      cancelText: constants.CANCEL,
      onOk: ondeleteUsers,
    });
  };

  /**
   * Excute search when page has changed
   */
  useEffect(() => {
    form.submit();
  }, [page]);

  useEffect(() => {
    if (users.length <= 0 && !canLoadMore) setSelectAll(false);

    const selected = selectedRowKeys;
    const uids = users.map((u) => u.id);

    if (selectAll) {
      const selectedRowKeys = uniq([...selected, ...uids]);
      setSelectedRows(selectedRowKeys);
    }
  }, [users]);

  /**
   * Custom select all
   * @param {*} e
   */
  const onSelectAllChanged = (e) => {
    const checked = e.target.checked;
    const rows = users.map((u) => u.id);

    setSelectAll(checked);
    if (checked) {
      setSelectedRows(rows); // Set checked all current records
      setExportData([]); // Reset to export all data
    } else setSelectedRows([]);
  };

  const exportCSV = async () => {
    let email, name;
    let cpnCode = companyCode;
    const { companyCode: CpnSlection, term, searchby } = form.getFieldsValue([
      'companyCode',
      'term',
      'searchby',
    ]);
    name = searchby === 'name' ? term : null;
    email = searchby === 'email' ? term : null;
    cpnCode = CpnSlection || companyCode;

    if (users.total <= 0) return; // No data to export

    if (selectAll) {
      // Export all
      setFetchedExportData(false);
      dispatch(blockUI());
      getAllUserByCondition({companyCode: cpnCode, name, email, uid: auth.uid})
        .then((res) => {
          setExportData(res);
          setFetchedExportData(true);
        })
        .finally(() => dispatch(unblockUI()));
    }

    // otherwise will be export by selected users "exportData"
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (rows) => {
      const exportSelectedUsers = users.filter((x) => rows.includes(x.id));
      setExportData(exportSelectedUsers);
      setSelectedRows(rows);
    },
    hideSelectAll: true,
    onSelect: (_, selected) => {
      if (!selected && selectAll) setSelectAll(false);
    },
    preserveSelectedRowKeys: true,
    columnTitle: <Checkbox checked={selectAll} onChange={onSelectAllChanged} />,
  };

  return (
    <div style={{ background: '#fff' }}>
      <div className="admin-container">
        <h1>ユーザー一覧</h1>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 0]}>
          <Col xs={24} md={24} sm={24} lg={4}>
            <div className="left-container">
              <div className="left-header">
                <Space>
                  <Button
                    onClick={() => editModalRef.current.show()}
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    size={40}
                  />

                  <CSVImport
                    headerMappings={[
                      { key: '表示名', label: 'displayUserName' },
                      { key: '電子メール', label: 'email' },
                      { key: '役割', label: 'role' },
                      { key: '会社コード', label: 'companyCode' },
                    ]}
                    onUpload={(data) => addUserFromCSV(data)}
                    onUploadFinish={() => form.submit()}
                  />

                  {selectedRowKeys.length > 0 && (
                    <Button
                      type="primary"
                      shape="circle"
                      danger
                      icon={<DeleteFilled />}
                      size={40}
                      onClick={confirmDelete}
                    />
                  )}
                </Space>
              </div>
              <Form
                form={form}
                layout="vertical"
                style={{
                  marginTop: 15,
                }}
                name="s-form"
                initialValues={{
                  searchby: 'name',
                  companyCode: '',
                }}
                onFinish={getUserData}
              >
                {isAdmin && (
                  <Form.Item name="companyCode" label="会社を選ぶ">
                    <Input placeholder="会社を選択してください" />
                  </Form.Item>
                )}

                <Form.Item name="searchby" label="で検索">
                  <Radio.Group>
                    <Radio value="name">Name</Radio>
                    <Radio value="email">Email</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item name="term">
                  <Input placeholder="電子メール" />
                </Form.Item>

                <Form.Item>
                  <div className="search-btn">
                    <Space direction="vertical" align="center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SearchOutlined />}
                        size={40}
                        onClick={() => setPage(1)}
                      >
                        検索する
                      </Button>
                      <CSVExportButton
                        data={exportData}
                        headers={[
                          { label: 'ID', key: 'id' },
                          { label: '表示名', key: 'displayUserName' },
                          { label: '電子メール', key: 'email' },
                          { label: '役割', key: 'role' },
                          { label: '会社コード', key: 'companyCode' },
                        ]}
                        fileName={`users_${today}.csv`}
                        fetchCSVData={exportCSV}
                        fetched={fetchedExportData}
                      />
                    </Space>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Col>

          <Col xs={24} md={24} sm={24} lg={20}>
            <Table
              rowKey="id"
              className="table-sticky-header"
              rowSelection={rowSelection}
              dataSource={users}
              pagination={false}
              loading={getUserLoading}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="データなし"
                  />
                ),
              }}
            >
              <Table.Column
                title="表示名"
                dataIndex="displayUserName"
                key="displayUserName"
                render={(displayUserName, row) => (
                  <a onClick={() => editModalRef.current.show(row)}>
                    <Space>
                      <Avatar src={row.avatar || ic_default_user} />
                      <span>{displayUserName}</span>
                    </Space>
                  </a>
                )}
              />

              <Table.Column title="電子メール" key="email" dataIndex="email" />
              <Table.Column title="役割" key="roleName" dataIndex="roleName" />
              <Table.Column title="LayerID" dataIndex="layerId"/>
              <Table.Column
                title="会社コード"
                key="companyCode"
                dataIndex="companyCode"
              />
            </Table>
            <Pagination
              page={page}
              isBackDisabled={page === 1 || getUserLoading}
              onClickBack={() => setPage(page - 1)}
              isNextDisabled={!canLoadMore || getUserLoading}
              onClickNext={() => setPage(page + 1)}
            />
          </Col>
        </Row>
      </div>

      <UserAddOrEditModal ref={editModalRef} onUpdated={() => form.submit()} />
    </div>
  );
}

export default UserManagement;
