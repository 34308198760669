import React from 'react';
import { useDispatch } from 'react-redux';
import { VerticalAlignTopOutlined } from '@ant-design/icons';
import { get, head } from 'lodash';
import CSVReader from 'react-csv-reader';
import { blockUI, unblockUI } from 'redux-store/blocking-ui/action';
import { toast } from 'react-toastify';
import './style.scss';

const CSVImport = (props) => {
  const dispatch = useDispatch();
  const headerMappings = props.headerMappings || [];

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => {
      const fieldMap = headerMappings.find((h) => h.key === header);
      const label = get(fieldMap, 'label');
      return label;
    },
  };

  const handleForce = (data) => {
    const dataObj = head(data);
    const { onUpload, onUploadFinish } = props;

    checkValidateFields(dataObj)
      .then(() => {
        dispatch(blockUI());
        return onUpload(data);
      })
      .then(() => {
        toast.success('CSVのインポートに成功しました。');
        onUploadFinish && onUploadFinish();
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(unblockUI());
        var element = document.getElementById('csvFile');
        element.value = '';
      });
  };

  const checkValidateFields = (dataObj) => {
    const objKeys = Object.keys(dataObj);
    const validKeys = headerMappings.map((h) => h.label);
    for (const v of validKeys) {
      if (objKeys.indexOf(v) < 0) {
        return Promise.reject({
          message: '無効なCSV形式、または必須フィールドがありません。',
        });
      }
    }
    return Promise.resolve();
  };

  return (
    <div>
      <CSVReader
        cssClass="react-csv-import"
        inputId="csvFile"
        label={
          <div className="label">
            <VerticalAlignTopOutlined /> CSVインポート
          </div>
        }
        onFileLoaded={handleForce}
        parserOptions={papaparseOptions}
      />
    </div>
  );
};

export default CSVImport;
