import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import { Modal, Form, Input, InputNumber } from 'antd';
import constants from 'commons/constants';
import { addOrUpdateCompany } from 'services';
import { toast } from 'react-toastify';
import useStateCallback from 'use-state-with-callback';
import { ImageUpload } from 'components';
import { get } from 'lodash';

const CompanyAddOrEditModal = forwardRef((props, ref) => {
  const inputRef = useRef();
  const uploadRef = useRef();
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const { onUpdated } = props;
  const [visible, setVisible] = useStateCallback(false, (visible) => {
    if (!visible) setCompany(null);
  });

  const [company, setCompany] = useStateCallback(null, (company) => {
    const { id, companyName, companyCode, chargeLimit, initialBalance } = company || {};
    form.setFieldsValue({
      id,
      companyCode,
      companyName,
      chargeLimit,
      initialBalance
    });
  });

  const [loading, setLoading] = useState(false);
  const isAddNew = get(company, 'id', null) === null;

  const show = (company) => {
    setCompany(company);
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
    form.resetFields(); // reset form
    uploadRef.current.resetStates();
  };

  const onOK = async () => {
    const { id, companyName, chargeLimit, companyCode, initialBalance } = form.getFieldsValue([
      'companyName',
      'chargeLimit',
      'companyCode',
      'id',
      'initialBalance'
    ]);

    form
      .validateFields()
      .then(() => uploadRef.current.upload(constants.UPLOAD_PATH.COMPANY))
      .then((url) => {
        setLoading(true);
        return addOrUpdateCompany({
          id,
          companyName,
          companyCode,
          chargeLimit,
          companyLogo: url,
          initialBalance
        });
      })
      .then(() => {
        let msg = id
          ? '会社が無事に更新されました!'
          : '無事に会社が追加されました!';

        toast.success(msg);
        setLoading(false);
        onUpdated && onUpdated();
        hide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
        hide();
      });
  };

  return (
    <div ref={inputRef}>
      <Modal
        forceRender={true}
        title={isAddNew ? '会社を追加' : 'エディットカンパニー'}
        centered
        visible={visible}
        okText={constants.YES}
        cancelText={constants.CANCEL}
        okButtonProps={{ form: 'cpn-form', key: 'submit', htmlType: 'submit' }}
        confirmLoading={loading}
        onCancel={hide}
      >
        <Form
          form={form}
          name="cpn-form"
          onFinish={onOK}
          labelCol={{ span: 7 }}
          labelAlign="left"
        >
          <ImageUpload
            ref={uploadRef}
            url={company?.companyLogo}
            wraperStyle={{
              borderRadius: 0,
            }}
          />
          <Form.Item
            name="companyCode"
            label="会社コード"
            rules={[{ required: true, message: '会社コードが必要です。' }]}
          >
            <Input disabled={!isAddNew} />
          </Form.Item>

          <Form.Item
            name="companyName"
            label="会社名"
            rules={[{ required: true, message: '会社名は必須です。' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="chargeLimit"
            label="チャージリミット"
            rules={[
              {
                validator: (_, v) => {
                  if (v >= 1000) return Promise.resolve();
                  else
                    return Promise.reject(
                      'チャージの上限は1000以上でなければならない!'
                    );
                },
              },
            ]}
          >
            <InputNumber type="number" />
          </Form.Item>

          <Form.Item
            name="initialBalance"
            label="初期バランス"
            rules={[
              { required: true, message: '初期残高が必要です。' },
              {
                validator: (_, v) => {
                  if (v >= 0) return Promise.resolve();
                  else return Promise.reject('0以上でなければなりません。!');
                },
              },
            ]}
          >
            <InputNumber type="number" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default CompanyAddOrEditModal;
