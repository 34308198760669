import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Form,
  Input,
  Empty,
  Checkbox
} from 'antd';
import {
  DeleteFilled,
  ExclamationCircleOutlined,
  SearchOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { get, uniq } from 'lodash';
import { CSVExportButton, Pagination, CSVImport } from 'components';
import { getRoles } from 'redux-store/roles/action';
import constants from 'commons/constants';
import RoleAddOrEditModal from './RoleAddOrEditModal';
import { toast } from 'react-toastify';
import { deleteRoles, getAllRoleByCondition, addRoleFromCSV } from 'services';
import moment from 'moment';

function RoleManagement() {
  const today = moment(new Date()).format(constants.DATE_FILE_FORMAT);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const editModalRef = useRef();

  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowKeys, setSelectedRows] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [fetchedExportData, setFetchedExportData] = useState(false);
  const [page, setPage] = useState(1);

  const {
    data: roleData,
    loading: getRoleLoading,
    canLoadMore,
  } = useSelector((state) => get(state, 'role.roles', {}));

  /**
   * Get users
   * @param {*} formValues
   */

  const getRoleData = (formValues) => {
    const { term = '' } = formValues;
    dispatch(getRoles({ page, roleName: term.trim() }));
  };

  useEffect(() => {
    form.submit();
  }, [page]);

  useEffect(() => {
    if (roleData.length <= 0 && !canLoadMore) setSelectAll(false);

    const selected = selectedRowKeys;
    const uids = roleData.map((u) => u.id);

    if (selectAll) {
      const selectedRowKeys = uniq([...selected, ...uids]);
      setSelectedRows(selectedRowKeys);
    }
    setSelectedRows([]);
  }, [roleData]);

  const onDeleteRole = () => {
    deleteRoles(selectedRowKeys, selectAll)
      .then(() => {
        toast.success('会社は削除されました。');
        form.submit();
      })
      .catch((err) => {
        toast.error(err);
        console.log(err);
      });
  };

  const confirmDelete = () =>
    Modal.confirm({
      centered: true,
      title: constants.MODAL.CONFIRM,
      icon: <ExclamationCircleOutlined />,
      content: '役割を削除してもよろしいですか？',
      okText: constants.YES,
      cancelText: constants.CANCEL,
      onOk: onDeleteRole,
    });

    

  const onSelectAllChanged = (e) => {
    const checked = e.target.checked;
    const rows = roleData.map((u) => u.id);

    setSelectAll(checked);
    if (checked) {
      setSelectedRows(rows); // Set checked all current records
      setExportData([]); // Reset to export all data
    } else setSelectedRows([]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (rows) => {
      const exportSelectedRoles = roleData.filter((x) => rows.includes(x.id));
      setExportData(exportSelectedRoles);
      setSelectedRows(rows);
    },
    hideSelectAll: true,
    onSelect: (_, selected) => {
      if (!selected && selectAll) setSelectAll(false);
    },
    preserveSelectedRowKeys: true,
    columnTitle: <Checkbox checked={selectAll} onChange={onSelectAllChanged} />,
    getCheckboxProps: record => {
      const role = get(record, 'role', 1);
      const disabled = constants.DISABLED_ROLES.indexOf(role) > -1;
      return { disabled }
    }
  };

  const exportCSV = async () => {
    const { term = '' } = form.getFieldsValue(['term']);
    let roleName;
    roleName = term.trim();
    if (roleData.length <= 0) return; // No data to export
    if (selectAll || (selectedRowKeys.length === 0 && roleData.length >= 0)) {
      // Export all
      setFetchedExportData(false);
      getAllRoleByCondition({ roleName }).then((res) => {
        setExportData(res);
        setFetchedExportData(true);
      });
    }
  };

  return (
    <div style={{ background: '#FFF' }}>
      <div className='admin-container'>
        <h1>役割リスト</h1>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 0]}>
          <Col xs={24} md={24} sm={24} lg={4}>
            <div className='left-container'>
              <div className='left-header'>
                <Space>
                  <Button
                    onClick={() => editModalRef.current.show()}
                    type='primary'
                    shape='circle'
                    icon={<PlusOutlined />}
                    size={40}
                  />

                  <CSVImport
                    headerMappings={[
                      { key: '名前', label: 'name' },
                      { key: '役割', label: 'role' },
                      { key: 'テキスト', label: 'text' },
                    ]}
                    onUpload={(data) => addRoleFromCSV(data)}
                    onUploadFinish={() => form.submit()}
                  />

                  {selectedRowKeys.length > 0 && (
                    <Button
                      type='primary'
                      shape='circle'
                      danger
                      icon={<DeleteFilled />}
                      size={40}
                      onClick={confirmDelete}
                    />
                  )}
                </Space>
              </div>
              <Form
                form={form}
                layout='vertical'
                style={{
                  marginTop: 15,
                }}
                name='s-form'
                onFinish={getRoleData}
              >
                <Form.Item name='term'>
                  <Input placeholder='テーマテキスト' />
                </Form.Item>

                <Form.Item>
                  <div className='search-btn'>
                    <Space direction='vertical' align='center'>
                      <Button
                        type='primary'
                        htmlType='submit'
                        icon={<SearchOutlined />}
                        size={40}
                        onClick={() => setPage(1)}
                      >
                        検索する
                      </Button>
                      <CSVExportButton
                        data={exportData}
                        headers={[
                          { label: '名前', key: 'name' },
                          { label: '役割', key: 'role' },
                          { label: 'テキスト', key: 'text' },
                        ]}
                        fileName={`roles${today}.csv`}
                        fetchCSVData={exportCSV}
                        fetched={fetchedExportData}
                      />
                    </Space>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Col>

          <Col xs={24} md={24} sm={24} lg={20}>
            <Table
              rowKey='id'
              className='table-sticky-header'
              rowSelection={rowSelection}
              dataSource={roleData}
              pagination={false}
              loading={getRoleLoading}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description='データなし'
                  />
                ),
              }}
            >
              <Table.Column
                title='名前'
                dataIndex='name'
                key='name'
                render={(name, row) => (
                  <a onClick={() => editModalRef.current.show(row)}>
                    <Space>
                      <span>{name}</span>
                    </Space>
                  </a>
                )}
              />

              <Table.Column title='役割' key='role' dataIndex='role' />
              <Table.Column title='テキスト' key='text' dataIndex='text' />
            </Table>
            <Pagination
              page={page}
              isBackDisabled={page === 1 || getRoleLoading}
              onClickBack={() => setPage(page - 1)}
              isNextDisabled={!canLoadMore || getRoleLoading}
              onClickNext={() => setPage(page + 1)}
            />
          </Col>
        </Row>
      </div>

      <RoleAddOrEditModal ref={editModalRef} onUpdated={() => form.submit()} />
    </div>
  );
}

export default RoleManagement;
