import { firebase } from 'commons/configs';
import constants from 'commons/constants';
import { last, merge } from 'lodash';

const firestore = firebase.firestore();

export const getGroupById = async (id) => {
  const doc = await firestore
    .collection(constants.COLLECTION_GROUP)
    .doc(id)
    .get();
  return {
    ...doc.data(),
    id: doc.id,
  };
};

export const getGroup = async ({ searchText, companyCode, lastDoc } = {}) => {
  try {
    let query = firestore
      .collection(constants.COLLECTION_GROUP)
      .where('isDM', '==', false)
      .where('isDeleted', '==', false);

    if (companyCode) query = query.where('companyCode', '==', companyCode);
    if (searchText) query = query.where('groupName', '==', searchText);

    query = query.orderBy('createdAt', 'desc');

    if (lastDoc) query = query.startAfter(lastDoc);

    const { docs } = await query.limit(constants.PAGE_LIMIT).get();
    const data = docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    return {
      data,
      lastDoc: last(docs),
      canLoadMore: docs.length === constants.PAGE_LIMIT,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAllGroupByCondition = async ({
  searchText,
  companyCode,
} = {}) => {
  try {
    let query = firestore
      .collection(constants.COLLECTION_GROUP)
      .where('isDM', '==', false)
      .where('isDeleted', '==', true);

    if (companyCode) query = query.where('companyCode', '==', companyCode);
    if (searchText) query = query.where('groupName', '==', searchText);

    query = query.orderBy('createdAt', 'desc');

    const { docs } = await query.get();
    const data = docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteGroup = async (ids, isDeleteAll, companyCode) => {
  const groupCollection = firestore.collection(constants.COLLECTION_GROUP);
  if (isDeleteAll) {
    let query = groupCollection
      .where('isDM', '==', false)
      .where('isDeleted', '==', true);

    if (companyCode) query = query.where('companyCode', '==', companyCode);

    const { docs } = await query.get();

    await Promise.all(
      docs.map(async (doc) => {
        await doc.ref.update({
          isDeleted: true,
          lastInteracted: firebase.firestore.FieldValue.serverTimestamp(),
        });
      })
    );
  } else {
    await Promise.all(
      ids.map(async (id) => {
        await groupCollection
          .doc(id)
          .update({
            isDeleted: true,
            lastInteracted: firebase.firestore.FieldValue.serverTimestamp(),
          });
      })
    );
  }
};

export const saveGroup = async (params) => {
  try {
    const { id, groupName, groupIcon, userNotificationSettings, usersGroup } = params;
    const ref = firestore.collection(constants.COLLECTION_GROUP).doc(id);

    await ref.set(
      {
        groupName,
        groupIcon,
        userNotificationSettings,
        usersGroup,
        lastInteracted: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addGroup = async (params) => {
  const ref = firestore.collection(constants.COLLECTION_GROUP).doc();
  return ref.set({
    ...params,
    isDeleted: false,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    order: 0,
    emocaCount: 0,
    isDM: false,
    url: `https://emolca.invitation.com/${ref.id}/`,
    lastInteracted: firebase.firestore.FieldValue.serverTimestamp(),
    careTakerFlag: '',
    userStatus: '',
  });
};
