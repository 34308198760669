import { get, pickBy, divide } from 'lodash';
import moment from 'moment';

export const isImageLinkUrl = (path = '') => path.startsWith('http');
export const rmUndefinedProperties = (obj) =>
  pickBy(obj, (v, key) => !(v === undefined || key === 'undefined'));
  
export const divideWithZero = (divider, value) => {
  if (divider === 0 || value === 0) return 0;
  else return Math.round(divide(divider, value) * 100) / 100;
};

export const flatEmocaDataForExport = (data = []) => {
  return data.map((emoca) => ({
    ...emoca,
    toUserIds: get(emoca, 'toUsers', []).map((user) => user.id),
    emocaImageId: get(emoca, 'emocaImage.id'),
    senderName: get(
      emoca,
      'fromUser.displayUserName',
      get(emoca, 'fromUser.email')
    ),
    receiverNames: get(emoca, 'toUsers', []).map((user) =>
      get(user, 'displayUserName', get(user, 'email'))
    ),
    groupName: get(emoca, 'group.groupName'),
    attachedFileNames: get(emoca, 'attachedFiles', []).map((file) => file.name),
    attachedFileUrls: get(emoca, 'attachedFiles', []).map((file) => file.url),
    createdAtFormated: moment(get(emoca, 'createdAt').toDate()).format(
      'YYYY/MM/DD HH:mm'
    ),
  }));
};

export const flatGiveawayHistoryDataForExport = (data = []) => {
  return data.map((giveawayHistory) => ({
    ...giveawayHistory,
    text: get(giveawayHistory, 'giveaway.text'),
    username: get(
      giveawayHistory,
      'user.displayUserName',
      get(giveawayHistory, 'user.email')
    ),
  }));
};

export const flatMedalTransmissionHistoryDataForExport = (data = []) => {
  return data.map((history) => ({
    ...history,
    username: get(history, 'user.displayUserName', get(history, 'user.email')),
  }));
};
