import React, { Suspense } from 'react';
import { Layout } from 'antd';
import Header from './header';
import AuthenticatedHeader from './authenticated-header';
import { Route, Switch } from 'react-router-dom';
import { adminRoutes, companyRotes, applicationRoutes } from 'commons/configs';
import './default.scss';

const DefaultLayout = (props) => {
  const routes = [...adminRoutes, ...companyRotes, ...applicationRoutes];

  const loading = () => (
    <div className="animated fadeIn pt-3 text-center"></div>
  );

  return (
    <Layout>
      <Header {...props}/>

      <Layout.Content className="df-layout-content">
        <AuthenticatedHeader {...props}/>
        <Suspense fallback={loading()}>
          <Switch>
            {routes.map((route) => (
              <Route
                path={route.path}
                component={route.component}
                exact={route.exact}
                key={route.path}
              />
            ))}
            <Route path="*" />
          </Switch>
        </Suspense>
      </Layout.Content>

      <Layout.Footer style={{ textAlign: 'center' }}>
        Emolca Portal ©2020 Created by Orient Software
      </Layout.Footer>
    </Layout>
  );
};

export default DefaultLayout;
