import * as Actions from '../actions';
import {
  getRoleData as getRoleService,
  deleteRoles as deleteRolessService,
} from 'services';
import { get } from 'lodash';

export const getRoles = ({ page, roleName } = {}) => (dispatch, getState) => {
  dispatch({ type: Actions.GET_ROLES });
  const state = getState();
  const lastDocs = get(state, 'role.roles.lastDocs', []);
  const nextPage = page + 1;
  const lastDoc = lastDocs.find((item) => item.page === page)?.lastDoc;
  getRoleService(roleName, lastDoc)
    .then(({ data, lastDoc, canLoadMore }) => {
      dispatch({
        type: Actions.GET_ROLES_SUCCESS,
        payload: {
          data,
          lastDocs: [
            ...lastDocs.filter((item) => item.page !== nextPage),
            { page: nextPage, lastDoc },
          ],
          canLoadMore,
          page,
        },
      });
    })
    .catch((error) => dispatch({ type: Actions.GET_ROLES_FAIL, error }));
};
