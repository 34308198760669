import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import { Modal, Form, Input, InputNumber, Select } from 'antd';
import constants from 'commons/constants';
import { addOrUpdateEventCode } from 'services';
import { toast } from 'react-toastify';
import useStateCallback from 'use-state-with-callback';
import { get } from 'lodash';

const EVENT_PRODUCTION = [
  { value: 'many_firework', label: 'Many Firework' },
  { value: 'nocut_firework', label: 'Nocut Firework' },
  { value: 'single_firework', label: 'Single Firework' },
  { value: 'confetti', label: 'Confetti' },
  { value: 'confetti_cannons', label: 'Confetti Cannons' },
];

const EventCodeManagementModal = forwardRef((props, ref) => {
  const inputRef = useRef();
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const { onUpdated } = props;

  const [eventCode, setEventCode] = useStateCallback(null, (data) => {
    const {
      id,
      eventCode,
      eventOccurrenceConditions,
      medalCount,
      additionalMedalCount,
      eventProduction,
    } = data || {};
    form.setFieldsValue({
      id,
      eventCode,
      medalCount,
      eventOccurrenceConditions,
      eventProduction,
      additionalMedalCount
    });
  });

  const [visible, setVisible] = useStateCallback(false, (visible) => {
    if (!visible) setEventCode(null);
  });

  const [loading, setLoading] = useState(false);
  const isAddNew = get(eventCode, 'id', null) === null;

  const handleChangeProduction = (eventProduction) => {
    form.setFieldsValue({ eventProduction })
  }

  const show = (eventCode) => {
    setEventCode(eventCode);
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
    form.resetFields();
  };

  const disabledEventProduction = () => {
    const eventUniqCode = get(eventCode, 'eventCode');
    if(!eventUniqCode) return false;
    return constants.DISABLED_EVENTPRODUCTION.indexOf(eventUniqCode) > -1;
  }

  const onOK = async () => {
    const {
      id,
      medalCount,
      eventOccurrenceConditions,
      eventProduction,
      eventCode,
      additionalMedalCount
    } = form.getFieldsValue([
      'additionalMedalCount',
      'medalCount',
      'eventOccurrenceConditions',
      'eventProduction',
      'eventCode',
      'id',
    ]);

    form
      .validateFields()
      .then(() => {
        setLoading(true);
        return addOrUpdateEventCode({
          id,
          medalCount,
          eventOccurrenceConditions,
          eventProduction,
          eventCode,
          additionalMedalCount
        });
      })
      .then(() => {
        let msg = id
          ? 'イベントコードの作成に成功しました'
          : 'イベントコードの更新に成功しました';

        toast.success(msg);
        setLoading(false);
        onUpdated && onUpdated();
        hide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
        hide();
      });
  };

  return (
    <div ref={inputRef}>
      <Modal
        forceRender={true}
        title={isAddNew ? 'イベントコードの追加' : 'イベントコードの編集'}
        centered
        visible={visible}
        okText={constants.YES}
        cancelText={constants.CANCEL}
        okButtonProps={{ form: 'cpn-form', key: 'submit', htmlType: 'submit' }}
        confirmLoading={loading}
        onCancel={hide}
      >
        <Form
          form={form}
          name="cpn-form"
          onFinish={onOK}
          labelCol={{ span: 7 }}
          labelAlign="left"
        >
          <Form.Item
            name="eventCode"
            label="イベントコード"
            rules={[{ required: true, message: 'イベントコードが必要です。' }]}
          >
            <InputNumber disabled={!isAddNew} />
          </Form.Item>

          <Form.Item
            name="eventOccurrenceConditions"
            label="イベント発生条件"
            rules={[{ required: true, message: 'コンディションが必要です。' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="medalCount"
            label="メダル数"
          >
            <InputNumber min="0" />
          </Form.Item>

          <Form.Item
            name="additionalMedalCount"
            label="追加メダル"
          >
            <InputNumber min="0" />
          </Form.Item>

          <Form.Item
            name="eventProduction"
            label="イベント演出"
            rules={[
              {
                validator: (_, v) => {
                  const disabled = disabledEventProduction();
                  if(disabled) return Promise.resolve();
                  const duplicateConfetti = v.filter(x => x.includes('confetti')).length >= 2;
                  const duplicateFireworks = v.filter(x => x.includes('firework')).length >= 2;

                  if(duplicateConfetti || duplicateFireworks)
                    return Promise.reject(
                      'タイプの違うイベント演出にしか対応できない!'
                    );
                  
                  if(v.length > 2) return Promise.reject('最大2つのイベント演出にしか対応できません。')

                  return Promise.resolve();
                },
              }
            ]}
          >
            <Select
              mode="multiple"
              onChange={handleChangeProduction}
              style={{ width: '100%' }}
              disabled={disabledEventProduction()}
            >
              {EVENT_PRODUCTION.map(item => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default EventCodeManagementModal;
