import React from 'react';
import { Modal, Form, Input, Switch } from 'antd';
import constants from 'commons/constants';
import { ImageUpload } from 'components';
import { useDispatch } from 'react-redux';
import { updateProfile } from 'services';
import useStateCallback from 'use-state-with-callback';
import { updateDisplayName } from 'redux-store/auth/actions';
import { toast } from 'react-toastify';

const ModalUpdateProfile = React.forwardRef((props, ref) => {
  const modalRef = React.useRef();
  const uploadRef = React.useRef();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [visible, setVisible] = React.useState(false);

  const [profile, setProfile] = useStateCallback(null, (profile) => {
    const { displayUserName, notification, message } = profile || {};
    form.setFieldsValue({
      displayUserName,
      notification,
      message,
    });
  });

  const [loading, setLoading] = React.useState(false);

  const show = (profile) => {
    setProfile(profile);
    setVisible(true);
  };

  const hide = () => setVisible(false);

  React.useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const saveProfile = (formValues) => {
    setLoading(true);
    uploadRef.current
      .upload(constants.UPLOAD_PATH.USER_AVATAR)
      .then((avatar) =>
        updateProfile({ ...formValues, avatar, id: profile.id })
      )
      .then(() => {
        setLoading(false);
        hide();
        dispatch(updateDisplayName(formValues.displayUserName));
        toast.success('プロフィールが更新されました。');
        uploadRef.current.resetStates();
        props.onUpdateFinish && props.onUpdateFinish();
      })
      .catch(() => {
        setLoading(false);
        toast.success('プロファイルの更新に失敗しました。');
      });
  };

  return (
    <div ref={modalRef}>
      <Modal
        forceRender={true}
        title={'プロフィールの更新'}
        centered
        visible={visible}
        okText={constants.YES}
        cancelText={constants.CANCEL}
        okButtonProps={{
          form: 'fupdate-profile',
          key: 'submit',
          htmlType: 'submit',
        }}
        confirmLoading={loading}
        onCancel={hide}
      >
        <Form form={form} onFinish={saveProfile} name="fupdate-profile">
          <ImageUpload
            ref={uploadRef}
            url={profile?.avatar}
            imageStyle={{
              width: 'auto',
            }}
          />
          <Form.Item
            name="displayUserName"
            label="表示名"
            rules={[{ required: true, message: '表示名は必須です。' }]}
          >
            <Input placeholder="表示名" />
          </Form.Item>

          <Form.Item
            name="message"
            label="サンキューメッセージ"
            rules={[{ required: true, message: 'パスワードが必要です。' }]}
          >
            <Input placeholder="サンキューメッセージ" />
          </Form.Item>

          <Form.Item
            name="notification"
            label="お知らせ"
            valuePropName="checked"
          >
            <Switch rules={[{ required: false }]} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default ModalUpdateProfile;
