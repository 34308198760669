import * as Actions from '../actions';

import { getCompanies as getCompaniesService, getDLCompany } from 'services';
import { get } from 'lodash';

export const getCompanies = ({ page, name, code } = {}) => (
  dispatch,
  getState
) => {
  dispatch({ type: Actions.GET_COMPANIES });
  const state = getState();
  const lastDocs = get(state, 'company.companies.lastDocs', []);
  const nextPage = page + 1;
  const lastDoc = lastDocs.find((item) => item.page === page).lastDoc;

  getCompaniesService(name, code, lastDoc)
    .then(({ data, lastDoc, canLoadMore }) => {
      dispatch({
        type: Actions.GET_COMPANIES_SUCCESS,
        payload: {
          data,
          lastDocs: [
            ...lastDocs.filter((item) => item.page !== nextPage),
            { page: nextPage, lastDoc },
          ],
          canLoadMore,
          page,
        },
      });
    })
    .catch((error) => dispatch({ type: Actions.GET_COMPANIES_FAIL, error }));
};

export const getCompanyDropDownList = () => (dispatch) => {
  dispatch({ type: Actions.GET_DROPDOWNLIST_COMPANIES });
  getDLCompany()
    .then((data) =>
      dispatch({ type: Actions.GET_DROPDOWNLIST_COMPANIES_SUCCESS, data })
    )
    .catch((error) =>
      dispatch({ type: Actions.GET_DROPDOWNLIST_COMPANIES_FAIL, error })
    );
};
