import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Form,
  DatePicker,
  Empty,
  Checkbox,
  Input,
} from 'antd';
import {
  DeleteFilled,
  ExclamationCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { actGetThemeHistories } from 'redux-store/theme-history/action';
import { blockUI, unblockUI } from 'redux-store/blocking-ui/action';
import { get, uniq } from 'lodash';
import constants from 'commons/constants';
import { CSVExportButton, Pagination } from 'components';
import numeral from 'numeral';
import locale from 'antd/lib/date-picker/locale/ja_JP';
import moment from 'moment';
import { deleteThemeHistories, getThemeHistoriesExport } from 'services';
import EditModal from './EditModal';
import { toast } from 'react-toastify';
const { RangePicker } = DatePicker;

function CompleteThemeHistory() {
  const today = moment(new Date()).format(constants.DATE_FILE_FORMAT);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const editModalRef = useRef();

  const [selectedRowKeys, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [fetchedExportData, setFetchedExportData] = useState(false);
  const [page, setPage] = useState(1);
  const auth = useSelector((state) => get(state, 'auth.login.result', {}));
  const cpnCode = get(auth, 'companyCode');
  const isAdmin = get(auth, 'role') === constants.ROLES.admin;

  const {
    data: themes = [],
    loading: getCpnLoading,
    canLoadMore,
  } = useSelector((state) => get(state, 'themeHistory.themes', {}));

  /**
   * Get users
   * @param {*} formValues
   */
  const getThemeData = (formValues) => {
    const {
      companyCode = cpnCode,
      eventCode = '',
      themeCode = '',
      userId = '',
      rangePicker = '',
    } = formValues;

    const [startDate, endDate] = rangePicker || [];

    dispatch(
      actGetThemeHistories({
        page,
        companyCode,
        eventCode,
        themeCode,
        userId,
        startDate,
        endDate,
      })
    );
  };

  /**
   * Delete selected themes
   */
  const onDeleteThemes = () => {
    dispatch(blockUI());
    deleteThemeHistories(selectedRowKeys, selectAll)
      .then(() => {
        dispatch(unblockUI());
        setSelectedRows([]);
        toast.success('テーマの履歴を削除しました。');
        form.submit();
      })
      .catch((err) => {
        dispatch(unblockUI());
        toast.error(err);
      });
  };

  /**
   * Show confirm delete modal
   */
  const confirmDelete = () => {
    Modal.confirm({
      centered: true,
      title: constants.MODAL.CONFIRM,
      icon: <ExclamationCircleOutlined />,
      content: 'テーマの履歴を削除しますか？',
      okText: constants.YES,
      cancelText: constants.CANCEL,
      onOk: onDeleteThemes,
    });
  };

  /**
   * Excute search when page has changed
   */
  useEffect(() => {
    form.submit();
  }, [page]);

  useEffect(() => {
    if (themes.length <= 0 && !canLoadMore) setSelectAll(false);

    const selected = selectedRowKeys;
    const uids = themes.map((u) => u.id);

    if (selectAll) {
      const selectedRowKeys = uniq([...selected, ...uids]);
      setSelectedRows(selectedRowKeys);
    }
  }, [themes]);

  /**
   * Custom select all
   * @param {*} e
   */
  const onSelectAllChanged = (e) => {
    const checked = e.target.checked;
    const rows = themes.map((u) => u.id);

    setSelectAll(checked);
    if (checked) {
      setSelectedRows(rows); // Set checked all current records
      setExportData([]); // Reset to export all data
    } else setSelectedRows([]);
  };

  /**
   * Export CSV file
   */
  const exportCSV = async () => {
    const {
      companyCode = cpnCode,
      eventCode = '',
      themeCode = '',
      userId = '',
      rangePicker: [startDate, endDate] = [],
    } = form.getFieldsValue();

    if (themes.length <= 0) return; // No data to export

    if (selectAll || (selectedRowKeys.length === 0 && themes.length >= 0)) {
      // Export all
      setFetchedExportData(false);
      dispatch(blockUI());
      getThemeHistoriesExport({
        companyCode,
        eventCode,
        themeCode,
        userId,
        startDate,
        endDate,
      })
        .then((res = []) => {
          setExportData(res);
          setFetchedExportData(true);
          dispatch(unblockUI());
        })
        .catch(() => dispatch(unblockUI()));
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (rows) => {
      const exportSelectedThemes = themes.filter((x) => rows.includes(x.id));
      setExportData(exportSelectedThemes);
      setSelectedRows(rows);
    },
    hideSelectAll: true,
    onSelect: (_, selected) => {
      if (!selected && selectAll) setSelectAll(false);
    },
    preserveSelectedRowKeys: true,
    columnTitle: <Checkbox checked={selectAll} onChange={onSelectAllChanged} />,
  };

  return (
    <div style={{ background: '#fff' }}>
      <div className="admin-container">
        <h1>テーマの完了</h1>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 0]}>
          <Col xs={24} md={24} sm={24} lg={4}>
            <div className="left-container">
              <div className="left-header">
                <Space>
                  <Button
                    type="primary"
                    danger
                    disabled={selectedRowKeys.length <= 0}
                    icon={<DeleteFilled />}
                    size={40}
                    onClick={confirmDelete}
                  >
                    Delete
                  </Button>
                </Space>
              </div>
              <Form
                form={form}
                layout="vertical"
                style={{
                  marginTop: 15,
                }}
                name="s-form"
                onFinish={getThemeData}
              >
                {isAdmin && (
                  <Form.Item name="companyCode" label="会社を選ぶ">
                    <Input placeholder="会社を選ぶ" />
                  </Form.Item>
                )}

                <Form.Item name="eventCode" label="イベントコード">
                  <Input placeholder="イベントコード" />
                </Form.Item>

                <Form.Item name="themeCode" label="テーマコード">
                  <Input placeholder="テーマコード" />
                </Form.Item>

                <Form.Item name="userId" label="ユーザーID">
                  <Input placeholder="ユーザーID" />
                </Form.Item>

                <Form.Item
                  name="rangePicker"
                  label="日付の範囲を選択します。"
                  rules={[{ type: 'array' }]}
                >
                  <RangePicker locale={locale} />
                </Form.Item>

                <Form.Item>
                  <div className="search-btn">
                    <Space direction="vertical" align="center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SearchOutlined />}
                        size={40}
                        onClick={() => setPage(1)}
                      >
                        検索する
                      </Button>

                      <CSVExportButton
                        data={exportData}
                        headers={[
                          { label: 'ID', key: 'id' },
                          { label: 'イベントコード', key: 'eventCode' },
                          { label: '会社コード', key: 'companyCode' },
                          { label: 'テーマコード', key: 'themeCode' },
                          { label: 'ユーザーID', key: 'userId' },
                          { label: '作成時間', key: 'createdAt' },
                        ]}
                        fileName={`CompleteThemeHistories_${today}.csv`}
                        fetchCSVData={exportCSV}
                        fetched={fetchedExportData}
                      />
                    </Space>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Col>

          <Col xs={24} md={24} sm={24} lg={20}>
            <Table
              rowKey="id"
              rowClassName="cursor-pointer"
              className="table-sticky-header"
              rowSelection={rowSelection}
              dataSource={themes}
              pagination={false}
              loading={getCpnLoading}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="データなし"
                  />
                ),
              }}
            >
              <Table.Column
                title="イベントコード"
                dataIndex="eventCode"
                key="eventCode"
                render={(eventCode, row) => (
                  <a onClick={() => editModalRef.current.show(row)}>
                    <Space>
                      <span>{eventCode}</span>
                    </Space>
                  </a>
                )}
              />

              <Table.Column
                title="会社コード"
                key="companyCode"
                dataIndex="companyCode"
              />

              <Table.Column
                title="テーマコード"
                key="themeCode"
                dataIndex="themeCode"
              />

              <Table.Column
                title="ユーザーID"
                key="userId"
                dataIndex="userId"
              />

              <Table.Column
                title="作成時間"
                key="createdAt"
                dataIndex="createdAt"
              />
            </Table>

            <Pagination
              page={page}
              isBackDisabled={page === 1 || getCpnLoading}
              onClickBack={() => setPage(page - 1)}
              isNextDisabled={!canLoadMore || getCpnLoading}
              onClickNext={() => setPage(page + 1)}
            />
          </Col>
        </Row>
      </div>

      <EditModal ref={editModalRef} onUpdated={() => form.submit()} />
    </div>
  );
}

export default CompleteThemeHistory;
