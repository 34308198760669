import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';
import { Modal, Form, Input, Select, Avatar, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import constants from 'commons/constants';
import { get } from 'lodash';
import { ImageUpload } from 'components';
import { getCompanyDropDownList } from 'redux-store/company/actions';
import { getUserDropdownList } from 'redux-store/users/action';
import { addGroup } from 'services';

function GroupAddModal({ onUpdated }, ref) {
  const [form] = Form.useForm();
  const imageUploadRef = useRef();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const auth = useSelector((state) => get(state, 'auth.login.result', {}));
  const companyCode = get(auth, 'companyCode');
  const isAdmin = get(auth, 'role') === constants.ROLES.admin;
  const dlCompanies = useSelector((state) =>
    get(state, 'company.dropdownList.data', [])
  );
  const dlUsers = useSelector((state) =>
    get(state, 'user.dropdownList.data', [])
  );

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const hide = () => {
    setModalVisible(false);
    imageUploadRef.current.resetStates();
    form.resetFields();
  };

  const show = () => {
    setModalVisible(true);
  };

  const onAddGroup = () => {
    const { companyCode, groupName, users } = form.getFieldsValue();
    form
      .validateFields()
      .then(() => imageUploadRef.current.upload(constants.UPLOAD_PATH.GROUP))
      .then((groupIcon) => {
        let params = {
          companyCode,
          groupName,
          usersGroup: users,
          userNotificationSettings: users.map((u) => ({
            userId: u,
            enable: true,
          })),
          groupIcon: groupIcon || '',
          userId: auth.id
        };
        return addGroup(params);
      })
      .then(() => {
        toast.success('グループの追加が成功しました。');
        setLoading(false);
        onUpdated && onUpdated();
        hide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
        hide();
      });
  };

  const getUsersByCompany = (companyCode) => {
    if (!companyCode) return;
    form.setFieldsValue({ users: [] });
    dispatch(getUserDropdownList({ companyCode }));
  };

  useEffect(() => {
    if (isAdmin) dispatch(getCompanyDropDownList());
    if (companyCode) getUsersByCompany(companyCode);
  }, []);

  return (
    <Modal
      title={'しんグループをつける'}
      centered
      width={600}
      visible={modalVisible}
      forceRender={true}
      okText={constants.YES}
      cancelText={constants.CANCEL}
      onOk={onAddGroup}
      okButtonProps={{
        form: 'group-form',
        key: 'submit',
        htmlType: 'submit',
        disabled: loading,
      }}
      cancelButtonProps={{
        disabled: loading,
      }}
      onCancel={hide}
    >
      <Form
        name="group-form"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        labelAlign="left"
      >
        <ImageUpload ref={imageUploadRef} wraperStyle={{ borderRadius: 0 }} />

        <Form.Item
          name="groupName"
          label="グループの名前"
          rules={[{ required: true, message: 'グループの名前は必須です。' }]}
        >
          <Input />
        </Form.Item>

        {isAdmin && (
          <Form.Item
            name="companyCode"
            label="会社コード"
            rules={[{ required: true, message: '会社を選択してください。' }]}
          >
            <Select
              placeholder="役割を選択する"
              style={{ width: '100%' }}
              onChange={getUsersByCompany}
            >
              {dlCompanies.length > 0 &&
                dlCompanies.map((cpn) => (
                  <Select.Option key={cpn.companyCode}>
                    {cpn.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}

        {dlUsers.length > 0 && (
          <Form.Item
            name="users"
            label="ユーザーグループ"
            rules={[
              { required: true, message: 'ユーザーを選択してください。' },
              {
                validator: (_, v) => {
                  if (v && v.length >= 3) return Promise.resolve();
                  else
                    return Promise.reject(
                      'グループは3人以上のユーザーが必要です。'
                    );
                },
              },
            ]}
          >
            <Select
              placeholder="役割を選択する"
              style={{ width: '100%' }}
              mode="multiple"
            >
              {dlUsers.map((u) => (
                <Select.Option key={u.id}>
                  <Space>
                    <Avatar src={u.avatar} size="small"></Avatar>
                    <span>
                      {u.displayUserName || '表示名なし'} - {u.email}
                    </span>
                  </Space>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}

export default forwardRef(GroupAddModal);
