import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Form,
  Input,
  Empty,
  Checkbox,
  Select,
} from 'antd';
import {
  DeleteFilled,
  ExclamationCircleOutlined,
  SearchOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getLayers } from 'redux-store/layer-master/action';
import { blockUI, unblockUI } from 'redux-store/blocking-ui/action';
import { get, uniq } from 'lodash';
import constants from 'commons/constants';
import { CSVExportButton, Pagination, CSVImport } from 'components';
import moment from 'moment';
import { deleteLayers, getLayerDataExport, importLayersCSV } from 'services';
import { toast } from 'react-toastify';
import AddOrEditDepartmentModal from './AddOrEditDepartmentModal';
import { getCompanyDropDownList } from 'redux-store/company/actions';

function DepartmentLayer() {
  const today = moment(new Date()).format(constants.DATE_FILE_FORMAT);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const editModalRef = useRef();

  const [selectedRowKeys, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [fetchedExportData, setFetchedExportData] = useState(false);
  const [page, setPage] = useState(1);
  const companies = useSelector((state) =>
    get(state, 'company.dropdownList.data', [])
  );
  const auth = useSelector((state) => get(state, 'auth.login.result', {}));
  const companyCode = get(auth, 'companyCode', '');
  const isAdmin = get(auth, 'role') === constants.ROLES.admin;

  const {
    data: layers = [],
    loading: fetchLoading,
    canLoadMore,
  } = useSelector((state) => get(state, 'layer.layers', {}));

  /**
   * Get users
   * @param {*} formValues
   */
  const getLayerData = (formValues) => {
    const {
      layer1 = '',
      layer2 = '',
      layer3 = '',
      companyCode: code = '',
    } = formValues;
    const cpnCode = code || companyCode;
    dispatch(getLayers({ page, layer1, layer2, layer3, companyCode: cpnCode }));
  };

  /**
   * Delete selected companies
   */
  const onDeleteLayers = () => {
    dispatch(blockUI());
    deleteLayers(selectedRowKeys, selectAll)
      .then(() => {
        setSelectedRows([]);
        toast.success('削除成功');
        form.submit();
      })
      .catch((err) => {
        toast.error(err);
        console.log(err);
      })
      .finally(() => dispatch(unblockUI()));
  };

  /**
   * Show confirm delete modal
   */
  const confirmDelete = () => {
    Modal.confirm({
      centered: true,
      title: constants.MODAL.CONFIRM,
      icon: <ExclamationCircleOutlined />,
      content: '削除しても大丈夫ですか？',
      okText: constants.YES,
      cancelText: constants.CANCEL,
      onOk: onDeleteLayers,
    });
  };

  useEffect(() => dispatch(getCompanyDropDownList()));

  /**
   * Excute search when page has changed
   */
  useEffect(() => {
    form.submit();
  }, [page]);

  useEffect(() => {
    if (layers.length <= 0 && !canLoadMore) setSelectAll(false);

    const selected = selectedRowKeys;
    const uids = layers.map((u) => u.id);

    if (selectAll) {
      const selectedRowKeys = uniq([...selected, ...uids]);
      setSelectedRows(selectedRowKeys);
    }
  }, [layers]);

  /**
   * Custom select all
   * @param {*} e
   */
  const onSelectAllChanged = (e) => {
    const checked = e.target.checked;
    const rows = layers.map((u) => u.id);

    setSelectAll(checked);
    if (checked) {
      setSelectedRows(rows); // Set checked all current records
      setExportData([]); // Reset to export all data
    } else setSelectedRows([]);
  };

  /**
   * Export CSV file
   */
  const exportCSV = async () => {
    const {
      layer1 = '',
      layer2 = '',
      layer3 = '',
      companyCode: code = '',
    } = form.getFieldsValue();
    const cpnCode = code || companyCode;

    if (layers.length <= 0) return; // No data to export

    if (selectAll || (selectedRowKeys.length === 0 && layers.length >= 0)) {
      // Export all
      setFetchedExportData(false);
      dispatch(blockUI());
      getLayerDataExport({ layer1, layer2, layer3, companyCode: cpnCode })
        .then((res) => {
          setExportData(res);
          setFetchedExportData(true);
        })
        .finally(() => dispatch(unblockUI()));
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (rows) => {
      const exportSelectedLayers = layers.filter((x) => rows.includes(x.id));
      setExportData(exportSelectedLayers);
      setSelectedRows(rows);
    },
    hideSelectAll: true,
    onSelect: (_, selected) => {
      if (!selected && selectAll) setSelectAll(false);
    },
    preserveSelectedRowKeys: true,
    columnTitle: <Checkbox checked={selectAll} onChange={onSelectAllChanged} />,
  };

  return (
    <div style={{ background: '#fff' }}>
      <div className="admin-container">
        <h1>マスター層</h1>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 0]}>
          <Col xs={24} md={24} sm={24} lg={4}>
            <div className="left-container">
              <div className="left-header">
                <Space>
                  <Button
                    onClick={() => editModalRef.current.show()}
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    size={40}
                  />

                  <CSVImport
                    headerMappings={[
                      { key: 'Layer1', label: 'layer1' },
                      { key: 'Layer2', label: 'layer2' },
                      { key: 'Layer3', label: 'layer3' },
                      { key: '会社コード', label: 'companyCode' },
                    ]}
                    onUpload={(data) => importLayersCSV(data)}
                    onUploadFinish={() => form.submit()}
                  />

                  {selectedRowKeys.length > 0 && (
                    <Button
                      type="primary"
                      shape="circle"
                      danger
                      icon={<DeleteFilled />}
                      size={40}
                      onClick={confirmDelete}
                    />
                  )}
                </Space>
              </div>
              <Form
                form={form}
                layout="vertical"
                style={{
                  marginTop: 15,
                }}
                name="s-form"
                onFinish={getLayerData}
                initialValues={{
                  companyCode: '',
                }}
              >
                {isAdmin && (
                  <Form.Item name="companyCode" label="会社コード">
                    <Select placeholder="会社コード" style={{ width: '100%' }}>
                      <Select.Option key="">すべて</Select.Option>
                      {companies.length > 0 &&
                        companies.map((cpn) => (
                          <Select.Option key={cpn.companyCode}>
                            {cpn.name} ({cpn.companyCode})
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}

                <Form.Item name="layer1" label="レイヤ1">
                  <Input placeholder="レイヤ1" />
                </Form.Item>

                <Form.Item name="layer2" label="レイヤ2">
                  <Input placeholder="レイヤ2" />
                </Form.Item>

                <Form.Item name="layer3" label="レイヤ3">
                  <Input placeholder="レイヤ3" />
                </Form.Item>

                <Form.Item>
                  <div className="search-btn">
                    <Space direction="vertical" align="center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SearchOutlined />}
                        size={40}
                        onClick={() => setPage(1)}
                      >
                        検索する
                      </Button>

                      <CSVExportButton
                        data={exportData}
                        headers={[
                          { label: 'ID', key: 'id' },
                          { label: 'Layer1', key: 'layer1' },
                          { label: 'Layer2', key: 'layer2' },
                          { label: 'Layer3', key: 'layer3' },
                          { label: '会社コード', key: 'companyCode' },
                          { label: '作成時間', key: 'createdAt' },
                        ]}
                        fileName={`LayerMaster_${today}.csv`}
                        fetchCSVData={exportCSV}
                        fetched={fetchedExportData}
                      />
                    </Space>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Col>

          <Col xs={24} md={24} sm={24} lg={20}>
            <Table
              rowKey="id"
              className="table-sticky-header"
              rowSelection={rowSelection}
              dataSource={layers}
              pagination={false}
              loading={fetchLoading}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="データなし"
                  />
                ),
              }}
            >
              <Table.Column
                title="ID"
                dataIndex="id"
                render={(id, d) => (
                  <a onClick={() => editModalRef.current.show(d)}>{id}</a>
                )}
              />
              <Table.Column
                title="レイヤレベル"
                render={(_, d) => {
                  let levels = [d.layer1, d.layer2, d.layer3]
                    .filter((x) => x)
                    .join(' / ');
                  return <span>{levels}</span>;
                }}
              />

              <Table.Column
                title="会社コード"
                key="companyCode"
                dataIndex="companyCode"
              />

              <Table.Column
                title="作成時間"
                key="createdAt"
                dataIndex="createdAt"
              />
            </Table>

            <Pagination
              page={page}
              isBackDisabled={page === 1 || fetchLoading}
              onClickBack={() => setPage(page - 1)}
              isNextDisabled={!canLoadMore || fetchLoading}
              onClickNext={() => setPage(page + 1)}
            />
          </Col>
        </Row>
      </div>
      <AddOrEditDepartmentModal
        ref={editModalRef}
        onUpdated={() => form.submit()}
        companies={companies}
      />
    </div>
  );
}

export default DepartmentLayer;
