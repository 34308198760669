import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  Space,
  Avatar,
  Modal,
  Form,
  Radio,
  Input,
  Empty,
  Checkbox,
} from 'antd';
import {
  DeleteFilled,
  ExclamationCircleOutlined,
  SearchOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanies } from 'redux-store/company/actions';
import { blockUI, unblockUI } from 'redux-store/blocking-ui/action';
import { get, uniq } from 'lodash';
import constants from 'commons/constants';
import { CSVExportButton, Pagination, CSVImport } from 'components';
import { ic_default_user } from 'assets/images';
import numeral from 'numeral';
import moment from 'moment';
import {
  deleteCompanies,
  getAllCompanyByCondition,
  addCompanyFromCSV,
} from 'services';
import CompanyAddOrEditModal from './CompanyAddOrEditModal';
import { toast } from 'react-toastify';

function AdminCompany() {
  const today = moment(new Date()).format(constants.DATE_FILE_FORMAT);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const editModalRef = useRef();

  const [selectedRowKeys, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [fetchedExportData, setFetchedExportData] = useState(false);
  const [page, setPage] = useState(1);

  const {
    data: companies,
    loading: getCpnLoading,
    canLoadMore,
  } = useSelector((state) => get(state, 'company.companies', {}));

  /**
   * Get users
   * @param {*} formValues
   */
  const getCompanyData = (formValues) => {
    let name, code;
    const { searchby, term = '' } = formValues;
    name = searchby === 'companyName' ? term.trim() : null;
    code = searchby === 'companyCode' ? term.trim() : null;

    dispatch(getCompanies({ page, name, code }));
  };

  /**
   * Delete selected companies
   */
  const onDeleteCompanies = () => {
    dispatch(blockUI());
    deleteCompanies(selectedRowKeys, selectAll)
      .then(() => {
        setSelectedRows([]);
        toast.success('会社は削除されました。');
        form.submit();
      })
      .catch((err) => {
        toast.error(err);
        console.log(err);
      })
      .finally(() => dispatch(unblockUI()));
  };

  /**
   * Show confirm delete modal
   */
  const confirmDelete = () => {
    Modal.confirm({
      centered: true,
      title: constants.MODAL.CONFIRM,
      icon: <ExclamationCircleOutlined />,
      content: '会社を削除してよろしいですか？',
      okText: constants.YES,
      cancelText: constants.CANCEL,
      onOk: onDeleteCompanies,
    });
  };

  /**
   * Excute search when page has changed
   */
  useEffect(() => {
    form.submit();
  }, [page]);

  useEffect(() => {
    if (companies.length <= 0 && !canLoadMore) setSelectAll(false);

    const selected = selectedRowKeys;
    const uids = companies.map((u) => u.id);

    if (selectAll) {
      const selectedRowKeys = uniq([...selected, ...uids]);
      setSelectedRows(selectedRowKeys);
    }
  }, [companies]);

  /**
   * Custom select all
   * @param {*} e
   */
  const onSelectAllChanged = (e) => {
    const checked = e.target.checked;
    const rows = companies.map((u) => u.id);

    setSelectAll(checked);
    if (checked) {
      setSelectedRows(rows); // Set checked all current records
      setExportData([]); // Reset to export all data
    } else setSelectedRows([]);
  };

  /**
   * Export CSV file
   */
  const exportCSV = async () => {
    const { searchby, term = '' } = form.getFieldsValue(['searchby', 'term']);
    let name, code;
    name = searchby === 'companyName' ? term.trim() : null;
    code = searchby === 'companyCode' ? term.trim() : null;

    if (companies.length <= 0) return; // No data to export

    if (selectAll || (selectedRowKeys.length === 0 && companies.length >= 0)) {
      // Export all
      setFetchedExportData(false);
      dispatch(blockUI());
      getAllCompanyByCondition({ name, code })
        .then((res) => {
          setExportData(res);
          setFetchedExportData(true);
        })
        .finally(() => dispatch(unblockUI()));
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (rows) => {
      const exportSelectedCompanies = companies.filter((x) =>
        rows.includes(x.id)
      );
      setExportData(exportSelectedCompanies);
      setSelectedRows(rows);
    },
    hideSelectAll: true,
    onSelect: (_, selected) => {
      if (!selected && selectAll) setSelectAll(false);
    },
    preserveSelectedRowKeys: true,
    columnTitle: <Checkbox checked={selectAll} onChange={onSelectAllChanged} />,
  };

  return (
    <div style={{ background: '#fff' }}>
      <div className="admin-container">
        <h1>企業設定</h1>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 0]}>
          <Col xs={24} md={24} sm={24} lg={4}>
            <div className="left-container">
              <div className="left-header">
                <Space>
                  <Button
                    onClick={() => editModalRef.current.show()}
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    size={40}
                  />

                  <CSVImport
                    headerMappings={[
                      { key: '会社名', label: 'companyName' },
                      { key: '会社コード', label: 'companyCode' },
                      { key: 'チャージリミット', label: 'chargeLimit' },
                      { key: '初期バランス', label: 'initialBalance' },
                    ]}
                    onUpload={(data) => addCompanyFromCSV(data)}
                    onUploadFinish={() => form.submit()}
                  />

                  {selectedRowKeys.length > 0 && (
                    <Button
                      type="primary"
                      shape="circle"
                      danger
                      icon={<DeleteFilled />}
                      size={40}
                      onClick={confirmDelete}
                    />
                  )}
                </Space>
              </div>
              <Form
                form={form}
                layout="vertical"
                style={{
                  marginTop: 15,
                }}
                name="s-form"
                initialValues={{
                  searchby: 'companyName',
                }}
                onFinish={getCompanyData}
              >
                <Form.Item name="searchby" label="で検索">
                  <Radio.Group>
                    <Radio value="companyName">会社名</Radio>
                    <Radio value="companyCode">会社コード</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item name="term">
                  <Input placeholder="電子メール" />
                </Form.Item>

                <Form.Item>
                  <div className="search-btn">
                    <Space direction="vertical" align="center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SearchOutlined />}
                        size={40}
                        onClick={() => setPage(1)}
                      >
                        検索する
                      </Button>

                      <CSVExportButton
                        data={exportData}
                        headers={[
                          { label: '会社名', key: 'companyName' },
                          { label: '会社コード', key: 'companyCode' },
                          { label: 'チャージリミット', key: 'chargeLimit' },
                          { label: '初期バランス', key: 'initialBalance' },
                          { label: '作成時間', key: 'createdAt' },
                        ]}
                        fileName={`Companies_${today}.csv`}
                        fetchCSVData={exportCSV}
                        fetched={fetchedExportData}
                      />
                    </Space>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Col>

          <Col xs={24} md={24} sm={24} lg={20}>
            <Table
              rowKey="id"
              className="table-sticky-header"
              rowSelection={rowSelection}
              dataSource={companies}
              pagination={false}
              loading={getCpnLoading}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="データなし"
                  />
                ),
              }}
            >
              <Table.Column
                title="会社名"
                dataIndex="companyName"
                key="companyName"
                render={(companyName, row) => (
                  <a onClick={() => editModalRef.current.show(row)}>
                    <Space>
                      <Avatar src={row.companyLogo || ic_default_user} />
                      <span>{companyName}</span>
                    </Space>
                  </a>
                )}
              />

              <Table.Column
                title="会社コード"
                key="companyCode"
                dataIndex="companyCode"
              />

              <Table.Column
                title="チャージリミット"
                key="chargeLimit"
                dataIndex="chargeLimit"
                render={(value) => numeral(value).format('0,0')}
              />

              <Table.Column
                title="初期バランス"
                key="initialBalance"
                dataIndex="initialBalance"
                render={(value) => numeral(value).format('0,0')}
              />

              <Table.Column
                title="作成時間"
                key="createdAt"
                dataIndex="createdAt"
              />
            </Table>

            <Pagination
              page={page}
              isBackDisabled={page === 1 || getCpnLoading}
              onClickBack={() => setPage(page - 1)}
              isNextDisabled={!canLoadMore || getCpnLoading}
              onClickNext={() => setPage(page + 1)}
            />
          </Col>
        </Row>
      </div>

      <CompanyAddOrEditModal
        ref={editModalRef}
        onUpdated={() => form.submit()}
      />
    </div>
  );
}

export default AdminCompany;
