import { firebase } from 'commons/configs';
import constants from 'commons/constants';
import { get, last, omit } from 'lodash';
import moment from 'moment';
import { rmUndefinedProperties } from 'utils/common';

const firestore = firebase.firestore();
const layerRef = firestore.collection(constants.COLLECTION_LAYER_MASTER);

export const getLayerMasterData = async ({
  layer1,
  layer2,
  layer3,
  lastDoc,
  companyCode,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let query = layerRef;
      if (layer1) query = query.where('layer1', '==', layer1);
      if (layer2) query = query.where('layer2', '==', layer2);
      if (layer3) query = query.where('layer3', '==', layer3);

      if (companyCode) query = query.where('companyCode', '==', companyCode);

      query = query
        .where('isDeleted', '==', false)
        .orderBy('createdAt', 'desc');

      if (lastDoc) query = query.startAfter(lastDoc);
      const { docs } = await query.limit(constants.PAGE_LIMIT).get();
      const data = docs.map((layer) => ({
        id: layer.id,
        ...layer.data(),
        createdAt: moment(layer.data().createdAt.toDate()).format(
          constants.DATE_TIME_FORMAT
        ),
      }));

      resolve({
        data,
        lastDoc: last(docs),
        canLoadMore: docs.length === constants.PAGE_LIMIT,
      });
    } catch (error) {
      console.log(error)
      reject(error);
    }
  });
};

export const getLayerDataExport = async ({
  layer1 = '',
  layer2 = '',
  layer3 = '',
  companyCode
} = {}) => {
  try {
    let query = layerRef;
    if (layer1) query = query.where('layer1', '==', layer1);
    if (layer2) query = query.where('layer2', '==', layer2);
    if (layer3) query = query.where('layer3', '==', layer3);
    if (companyCode) query = query.where('companyCode', '==', companyCode);

    query = query.where('isDeleted', '==', false).orderBy('createdAt', 'desc');

    const { docs } = await query.get();
    const data = docs.map((layer) => ({
      id: layer.id,
      ...layer.data(),
      createdAt: moment(layer.data().createdAt.toDate()).format(
        constants.DATE_TIME_FORMAT
      ),
    }));

    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const checkDuplicated = async (layer = {}) => {
  try {
    const { id, layer1, layer2, layer3, companyCode } = layer;
    const { docs } = await layerRef
      .where('layer1', '==', layer1)
      .where('layer2', '==', layer2)
      .where('layer3', '==', layer3)
      .where('companyCode', '==', companyCode)
      .where('isDeleted', '==', false)
      .limit(1)
      .get();
    return docs.length > 0 && docs[0].id !== id;
  } catch (err) {
    return false;
  }
};

export const addOrUpdateLayer = async (layer) => {
  const id = get(layer, 'id');

  const duplicated = await checkDuplicated(layer);
  if (duplicated)
    return Promise.reject({
      message: 'レイヤーはすでに存在しています。aaaa',
    });

  /**
   * Otherwise add new theme
   */
  if (!id) {
    return layerRef.add(
      omit(
        rmUndefinedProperties({
          ...layer,
          isDeleted: false,
          createdAt: new Date(),
        }),
        ['id']
      )
    );
  }

  /**
   * Update if id has existed
   */
  return layerRef.doc(id).update(omit(layer, ['id']));
};

export const importLayersCSV = async (data) => {
  const promises = data.map((layer) => addOrUpdateLayer(layer));
  return Promise.all(promises);
};

export const deleteLayers = async (ids, isDeleteAll) => {
  if (isDeleteAll) {
    const { docs } = await layerRef.where('isDeleted', '==', false).get();
    const promises = docs.map((doc) => doc.ref.update({ isDeleted: true }));
    return Promise.all(promises);
  } else {
    const promises = ids.map((id) =>
      layerRef.doc(id).update({ isDeleted: true })
    );
    return Promise.all(promises);
  }
};
