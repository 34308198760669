import { firebase } from 'commons/configs';
import Constants from 'commons/constants';
import moment from 'moment';
import numeral from 'numeral';
import { get } from 'lodash';

const Firestore = firebase.firestore();
const reportRef = Firestore.collection(Constants.COLLECTION_REPORT);
const dailyReportRef = Firestore.collection(Constants.COLLECTION_DAILY_REPORT);

const getAverageDashboardFn = firebase
  .functions()
  .httpsCallable('getAverageDashboard');

export const getReportByDates = async ({ startTime, endTime, companyCode }) => {
  try {
    const startDate = moment(startTime).startOf('d').toDate();
    const endDate = moment(endTime).startOf('d').toDate();

    let query = dailyReportRef
      .orderBy('reportDate', 'desc')
      .where('reportDate', '>=', startDate)
      .where('reportDate', '<=', endDate);

    if (companyCode) query = query.where('companyCode', '==', companyCode);
    else query = query.where('isALL', '==', true);

	const { docs } = await query.get();

    const emocaBydates = docs.map((d) => {
      const { totalMedalSent, totalEmoca, totalEvent } = d.data();
      return {
        ...d.data(),
        totalEmoca: numeral(totalEmoca).format('0,0'),
        totalMedalSent: numeral(totalMedalSent).format('0,0'),
        totalEvent: numeral(totalEvent).format('0,0'),
      };
    });

    return emocaBydates;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getGeneralReport = async (params) => {
  try {
    let report = {};
    const { companyCode } = params;
    if (!companyCode) {
      const { docs } = await reportRef
        .where('isALL', '==', true)
        .limit(1)
        .get();

      if (docs.length) report = docs[0].data();
    } else {
      const data = await reportRef.doc(companyCode).get();
      report = data.data();
    }
    return report;
  } catch (error) {
    throw error;
  }
};

export const getAverageDashboard = async ({ companyCode = 'all' }) => {
  try {
    const { data } = await getAverageDashboardFn({ companyCode });
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
