import { firebase } from 'commons/configs';
import constants from 'commons/constants';

const firestore = firebase.firestore();
const settingRef = firestore.collection(constants.COLLECTION_ADMIN_SETTING);

export const getAdminSetting = async () => {
  try {
    const { docs } = await settingRef.limit(1).get();
    if (docs.length > 0) return { id: docs[0].id, ...docs[0].data() };
    return {};
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const saveAdminSetting = (id, setting = {}) => {
  if (!id) return settingRef.add(setting);
  else if (id) return settingRef.doc(id).set(setting, { merge: true });
};
