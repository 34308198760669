import * as Actions from '../actions';

let initState = {
  group: {
    requesting: false,
    result: {
      data: [],
      lastDocs: [
        {
          page: 1,
          lastDoc: null,
        },
      ],
    },
    error: null,
    status: '',
  },
  savingGroup: {
    requesting: false,
    result: null,
    error: null,
    status: '',
  },
};

export default function userReducers(state = initState, action) {
  switch (action.type) {
    case Actions.GET_GROUP_REQUEST:
      return {
        ...state,
        group: {
          ...state.group,
          requesting: true,
          status: '',
        },
      };

    case Actions.GET_GROUP_SUCCESS:
      return {
        ...state,
        group: {
          ...state.group,
          requesting: false,
          result: action.payload,
          status: 'success',
        },
      };

    case Actions.GET_GROUP_FAIL:
      return {
        ...state,
        group: {
          ...state.group,
          requesting: false,
          error: action.error,
          status: 'error',
        },
      };

    case Actions.SAVE_GROUP_REQUEST:
      return {
        ...state,
        savingGroup: {
          ...state.savingGroup,
          requesting: true,
          status: '',
        },
      };

    case Actions.SAVE_GROUP_SUCCESS:
      const groupData = state.group.result.data;

      return {
        ...state,
        savingGroup: {
          ...state.savingGroup,
          requesting: false,
          result: action.payload,
          status: 'success',
        },
        group: {
          ...state.group,
          result: {
            ...state.group.result,
            data: groupData.map((item) => {
              if (item.id === action.payload.id) {
                return { ...item, ...action.payload };
              }
              return item;
            }),
          },
        },
      };

    case Actions.SAVE_GROUP_FAIL:
      return {
        ...state,
        savingGroup: {
          ...state.savingGroup,
          requesting: false,
          error: action.error,
          status: 'error',
        },
      };

    default:
      return state;
  }
}
