import * as Actions from '../actions';

import {
  getUsers as getUsersService,
  getRoles as getRolesService,
  getDLUsers,
} from 'services';
import { get } from 'lodash';

export const getUsers = ({ page, cpnCode, name, email } = {}) => (
  dispatch,
  getState
) => {
  dispatch({ type: Actions.GET_USERS });
  const state = getState();
  const lastDocs = get(state, 'user.users.lastDocs', []);
  const nextPage = page + 1;
  const lastDoc = lastDocs.find((item) => item.page === page)?.lastDoc;
  const currentUserId = get(state, 'auth.login.result.id');
  getUsersService(cpnCode, name, email, lastDoc, currentUserId)
    .then(({ data, lastDoc, canLoadMore }) => {
      dispatch({
        type: Actions.GET_USERS_SUCCESS,
        payload: {
          data,
          lastDocs: [
            ...lastDocs.filter((item) => item.page !== nextPage),
            { page: nextPage, lastDoc },
          ],
          canLoadMore,
          page,
        },
      });
    })
    .catch((error) => {
      dispatch({ type: Actions.GET_USERS_FAIL, error });
    });
};

export const getRoles = ({isAdmin}) => (dispatch, getState) => {
  dispatch({ type: Actions.GET_ROLES });
  getRolesService({isAdmin})
    .then((data) => {
      dispatch({
        type: Actions.GET_ROLES_SUCCESS,
        payload: {
          data,
        },
      });
    })
    .catch((error) => {
      dispatch({ type: Actions.GET_ROLES_FAIL, error });
    });
};

export const getUserDropdownList = ({ companyCode } = {}) => (dispatch) => {
  dispatch({ type: Actions.GET_DROPDOWNLIST_USERS });
  getDLUsers({ companyCode })
    .then((data) =>
      dispatch({ type: Actions.GET_DROPDOWNLIST_USERS_SUCCESS, data })
    )
    .catch((error) =>
      dispatch({ type: Actions.GET_DROPDOWNLIST_USERS_FAIL, error })
    );
};
