import { firebase } from 'commons/configs';
import Constants from 'commons/constants';
import moment from 'moment';
import { get, first } from 'lodash';

const Firestore = firebase.firestore();

export const getSystemInitTime = async () => {
  try {
    const { docs } = await Firestore.collection(Constants.COLLECTION_DAILY_REPORT)
      .orderBy('reportDate', 'asc')
      .limit(1)
      .get();

    const report = first(docs);
    if (report) {
      return get(report.data(), 'reportDate', moment()).toDate();
    }
  } catch (error) {
    throw error;
  }
};
