import React from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Dropdown } from "antd";
import { logo, ic_history, ic_settings } from "assets/images";
import "./header.scss";

const menuItemStyles = {
	hidden: {
		display: "none",
		visible: "hidden",
	},
};

const DefaultHeader = (props) => {
	const isApplicationUser = props.rolePath == "/application";
	const hidden = isApplicationUser && menuItemStyles.hidden;

	const settingsMenu = (
		<Menu>
			<Menu.Item style={hidden} key="マスター層">
				<Link to={`${props.rolePath}/department-layers`}>マスター層</Link>
			</Menu.Item>
			<Menu.Item style={hidden} key="企業設定">
				<Link to={`${props.rolePath}/company`}>企業設定</Link>
			</Menu.Item>
			<Menu.Item style={hidden} key="user-management">
				<Link to={`${props.rolePath}/users`}>ユーザー一覧</Link>
			</Menu.Item>
			<Menu.Item style={hidden} key="group-management">
				<Link to={`${props.rolePath}/groups`}>グループ一覧</Link>
			</Menu.Item>
			<Menu.Item style={hidden} key="role-management">
				<Link to={`${props.rolePath}/roles`}>役割リスト</Link>
			</Menu.Item>

			<Menu.Item key="emolca-settings">
				<Link to={`${props.rolePath}/emolcas`}>emolcaの設定</Link>
			</Menu.Item>
			<Menu.Item style={hidden} key="events">
				<Link to={`${props.rolePath}/events`}>イベントの設定</Link>
			</Menu.Item>
			<Menu.Item style={hidden} key="themes">
				<Link to={`${props.rolePath}/themes`}>テーマの設定</Link>
			</Menu.Item>
			<Menu.Item key="giveaway">
				<Link to={`${props.rolePath}/giveaways`}>景品の設定</Link>
			</Menu.Item>
			<Menu.Item style={hidden} key="navisapo-settings">
				<Link to={`${props.rolePath}/navisapo-settings`}>
					ナビサポの設定
				</Link>
			</Menu.Item>
		</Menu>
	);

	const historyMenu = (
		<Menu>
			<Menu.Item key="emolcaの送信履歴">
				<Link to={`${props.rolePath}/emolca-history`}>
					emolcaの送信履歴
				</Link>
			</Menu.Item>
			<Menu.Item key="景品の交換履歴">
				<Link to={`${props.rolePath}/giveaway-history`}>
					景品の交換履歴
				</Link>
			</Menu.Item>
			<Menu.Item key="テーマの完了">
				<Link to={`${props.rolePath}/theme-history`}>テーマの完了</Link>
			</Menu.Item>
			<Menu.Item key="イベントの発動履歴">
				<Link to={`${props.rolePath}/event-history`}>
					イベントの発動履歴
				</Link>
			</Menu.Item>
			<Menu.Item key="メダルの送信履歴">
				<Link to={`${props.rolePath}/medal-transmission-history`}>
					メダルの送信履歴
				</Link>
			</Menu.Item>
		</Menu>
	);

	return (
		<Layout.Header className="default-header header-container">
			<div className="logo">
				<Link to={props.rolePath}>
					<img src={logo} />
				</Link>
			</div>
			<div className="r-menu">
				{!isApplicationUser && (
					<Dropdown overlay={historyMenu} placement="bottomRight">
						<div className="menu-item">
							<img src={ic_history} />
							<span className="title">設定</span>
						</div>
					</Dropdown>
				)}

				<Dropdown overlay={settingsMenu} placement="bottomRight">
					<div className="menu-item">
						<img src={ic_settings} />
						<span className="title">設定</span>
					</div>
				</Dropdown>
			</div>
		</Layout.Header>
	);
};
export default DefaultHeader;
