import { firebase } from 'commons/configs';
import constants from 'commons/constants';
import { get, last, random } from 'lodash';
import moment from 'moment';
import { getUserById, getGroupById } from 'services';
import { rmUndefinedProperties } from 'utils/common';

const firestore = firebase.firestore();

export const getEmocaImages = (introductoryText, lastDoc) => {
  return new Promise(async (resolve, reject) => {
    try {
      let query = firestore.collection(constants.COLLECTION_EMOCA_IMAGE);
      console.log(introductoryText);
      if (introductoryText)
        query = query.where('introductoryText', '==', introductoryText);

      query = query
        .where('isDeleted', '==', false)
        .orderBy('createdAt', 'desc');

      if (lastDoc) query = query.startAfter(lastDoc);

      const { docs } = await query.limit(constants.PAGE_LIMIT).get();

      const data = docs.map((cpn) => ({
        id: cpn.id,
        ...cpn.data(),
        createdAt: moment(cpn.data().createdAt.toDate()).format(
          constants.DATE_TIME_FORMAT
        ),
      }));

      resolve({
        data,
        lastDoc: last(docs),
        canLoadMore: docs.length === constants.PAGE_LIMIT,
      });
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};

export const getAllEmocaImageByCondition = async ({
  introductoryText,
} = {}) => {
  try {
    let query = firestore.collection(constants.COLLECTION_EMOCA_IMAGE);
    if (introductoryText)
      query = query.where('introductoryText', '==', introductoryText);

    const { docs } = await query
      .where('isDeleted', '==', false)
      .orderBy('createdAt', 'desc')
      .get();

    return docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      createdAt: moment(doc.data().createdAt.toDate()).format(
        constants.DATE_TIME_FORMAT
      ),
    }));
  } catch (error) {
    console.log(error);
  }
};

export const addOrUpdateEmocaImage = async (emocaImage) => {
  const id = get(emocaImage, 'id');
  const { image, emocaIcon, introductoryText } = emocaImage;
  const ref = firestore.collection(constants.COLLECTION_EMOCA_IMAGE);

  /**
   * Update if id has existed
   */
  if (!id) {
    // pickBy funtion used to remove underfine properties
    return ref.add(
      rmUndefinedProperties({
        ...emocaImage,
        createdAt: new Date(),
        isDeleted: false,
        displayOrder: random(1, 100)
      })
    );
  }

  /**
   * Otherwise add new emoca image
   */
  return ref.doc(id).update({
    image,
    emocaIcon,
    introductoryText,
  });
};

export const deleteEmocaImages = async (emocaImageIds, isDeleteAll) => {
  const ref = firestore.collection(constants.COLLECTION_EMOCA_IMAGE);
  if (isDeleteAll) {
    const { docs } = await ref.where('isDeleted', '==', false).get();
    return Promise.all(
      docs.map((d) => ref.doc(d.id).update({ isDeleted: true }))
    );
  } else {
    return Promise.all(
      emocaImageIds.map((id) => ref.doc(id).update({ isDeleted: true }))
    );
  }
};

export const importEmocaImages = (emocaImages) => {
  return Promise.all(
    emocaImages.map((emcImg) => addOrUpdateEmocaImage(emcImg))
  );
};

export const getEmoca = async ({ searchText, companyCode, lastDoc } = {}) => {
  try {
    let query = firestore
      .collection(constants.COLLECTION_EMOCA)
      .where('isDeleted', '==', false)
      .where('isFromBot', '==', false);

    if (companyCode) query = query.where('companyCode', '==', companyCode);
    if (searchText) query = query.where('emocaMessage', '==', searchText);

    query = query.orderBy('createdAt', 'desc');

    if (lastDoc) query = query.startAfter(lastDoc);

    const { docs } = await query.limit(constants.PAGE_LIMIT).get();
    const data = await getEmocaSideData(docs);

    return {
      data,
      lastDoc: last(docs),
      canLoadMore: docs.length === constants.PAGE_LIMIT,
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const saveEmoca = async ({ id, empathyClap, numberOfAttachedMedal, emocaMessage, emocaImage = {} } = {}) => {
  try {
    await firestore.collection(constants.COLLECTION_EMOCA).doc(id).update({
      emocaMessage,
      emocaImage,
      empathyClap,
      numberOfAttachedMedal
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getEmocaSideData = async (emocaDocs) => {
  return await Promise.all(
    emocaDocs.map(async (doc) => {
      const emoca = { ...doc.data(), id: doc.id };
      const { fromUserId, groupId, toUserIds: receiverIds } = emoca;
      const fromUser = await getUserById(fromUserId);
      const group = await getGroupById(groupId);
      const { isDM, usersGroup } = group;

      const toUserIds = !isDM
        ? receiverIds
        : usersGroup.filter((id) => id !== fromUserId);
      const toUsers = await Promise.all(
        toUserIds.map(async (id) => await getUserById(id))
      );
      return {
        ...emoca,
        fromUser,
        group,
        toUsers,
      };
    })
  );
};

export const getAllEmocaByCondition = async ({ searchText, companyCode }) => {
  try {
    let query = firestore
      .collection(constants.COLLECTION_EMOCA)
      .where('isDeleted', '==', false)
      .where('isFromBot', '==', false);

    if (companyCode) query = query.where('companyCode', '==', companyCode);
    if (searchText) query = query.where('emocaMessage', '==', searchText);

    const { docs } = await query.get();
    const data = await getEmocaSideData(docs);

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteEmocas = async (ids, isDeleteAll, companyCode) => {
  const emocaCollection = firestore.collection(constants.COLLECTION_EMOCA);
  if (isDeleteAll) {
    let query = emocaCollection
      .where('isDeleted', '==', false)
      .where('isFromBot', '==', false);

    if (companyCode) query = query.where('companyCode', '==', companyCode);

    const { docs } = await query.get();

    await Promise.all(docs.map((doc) => doc.ref.update({
      isDeleted: true,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    })));
  } else {
    await Promise.all(
      ids.map((id) => emocaCollection.doc(id).update({
        isDeleted: true,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      }))
    );
  }
};

export const getEmocaImageList = async (emocaId) => {
  try {
    const { docs: emocaImgs } = await firestore
      .collection(constants.COLLECTION_EMOCA_IMAGE)
      // .where('isDeleted', '==', false)
      .get();

    return emocaImgs.map((emcImg) => {
      const emoca = { id: emcImg.id, ...emcImg.data() };
      const selected = emoca.id === emocaId;
      return {
        ...emoca,
        selected,
      };
    });
  } catch (err) {
    console.log(err);
    return [];
  }
};
