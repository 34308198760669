import React from 'react';
import { Modal, Form, Input } from 'antd';
import constants from 'commons/constants';
import { changePassword } from 'services';
import { logout } from 'redux-store/auth/actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const ModalChangePassword = React.forwardRef((_, ref) => {
  const dispatch = useDispatch();
  const modalRef = React.useRef();
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const [loading, setLoading] = React.useState(false);

  const updatePassword = () => {
    const { password, newPassword } = form.getFieldsValue([
      'password',
      'newPassword',
    ]);
    setLoading(true);
    changePassword(password, newPassword)
      .then(() => {
        setLoading(false);
        hide();
        toast.success('パスワードが更新されました。再度ログインしてください。');
        setTimeout(() => dispatch(logout()), 1000);
      })
      .catch((err) => {
        setLoading(false);
        switch (err.code) {
          case 'auth/wrong-password':
            toast.error('現在のパスワードが間違っています。');
            break;
          default:
            toast.error('パスワードの更新に失敗しました。');
            break;
        }
      });
  };

  React.useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  return (
    <div ref={modalRef}>
      <Modal
        forceRender={true}
        title={'パスワードの変更'}
        centered
        visible={visible}
        okText={constants.YES}
        cancelText={constants.CANCEL}
        okButtonProps={{
          form: 'fchange-password',
          key: 'submit',
          htmlType: 'submit',
        }}
        confirmLoading={loading}
        onCancel={hide}
      >
        <Form form={form} onFinish={updatePassword} name="fchange-password">
          <Form.Item
            name="password"
            label="現在のパスワード"
            rules={[{ required: true, message: 'パスワードが必要です。' }]}
          >
            <Input.Password placeholder="000000" />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="新しいパスワード"
            rules={[
              { required: true, message: 'パスワードが必要です。' },
              {
                min: 6,
                message: 'パスワードは6文字以上でなければなりません。',
              },
            ]}
          >
            <Input.Password placeholder="000000" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default ModalChangePassword;
