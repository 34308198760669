import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Avatar,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Form,
  Input,
  Empty,
  Checkbox,
  Typography,
} from 'antd';
import {
  DeleteFilled,
  ExclamationCircleOutlined,
  SearchOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getGroup } from 'redux-store/group/action';
import { get, uniq } from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import { blockUI, unblockUI } from 'redux-store/blocking-ui/action';
import constants from 'commons/constants';
import { ic_default_group_avatar } from 'assets/images';
import { CSVExportButton, Pagination } from 'components';
import { getAllGroupByCondition, deleteGroup } from 'services';
import GroupEditorModal from './GroupEditorModal';
import GroupAddModal from './GroupAddModal';
import './style.scss';

function GroupManagement() {
  const today = moment().format(constants.DATE_FILE_FORMAT);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const editorModalRef = useRef();
  const addModal = useRef();

  /**
   * States
   */
  const manager = useSelector((state) => get(state, 'auth.login.result'));
  const companyCode = get(manager, 'companyCode');
  const isAdmin = get(manager, 'role', 1) === constants.ROLES.admin;

  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowKeys, setSelectedRows] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [fetchedExportData, setFetchedExportData] = useState(false);
  const [page, setPage] = useState(1);
  const group = useSelector((state) => get(state, 'group.group'));

  const requesting = get(group, 'requesting', false);
  const canLoadMore = get(group, 'result.canLoadMore', false);

  const data = get(group, 'result.data', []);

  const getGroupData = ({ searchText, companyCode: cpnCode }) => {
    let code = companyCode;
    if (cpnCode) code = cpnCode;

    dispatch(getGroup({ page, searchText, companyCode: code }));
  };

  const handleDeleteGroup = () => {
    dispatch(blockUI());
    deleteGroup(selectedRowKeys, selectAll)
      .then(() => {
        setSelectedRows([]);
        toast.success('グループは削除されました。');
        form.submit();
      })
      .catch((err) => {
        toast.error('何かがうまくいかなかった。');
        console.log(err);
      })
      .finally(() => {
        dispatch(unblockUI());
      });
  };

  const exportCSV = async () => {
    let code = companyCode;
    const { searchText, companyCode: cpnCode } = form.getFieldsValue([
      'searchText',
      'companyCode',
    ]);

    if (cpnCode) code = cpnCode;
    if (data.length <= 0) return; // No data to export

    if (selectAll || (selectedRowKeys.length === 0 && data.length >= 0)) {
      // Export all
      setFetchedExportData(false);
      getAllGroupByCondition({ searchText, companyCode: code }).then((res) => {
        setExportData(res);
        setFetchedExportData(true);
      });
    }
  };

  /**
   * Show confirm delete modal
   */
  const confirmDelete = () => {
    Modal.confirm({
      centered: true,
      title: constants.MODAL.CONFIRM,
      icon: <ExclamationCircleOutlined />,
      content: 'グループを削除してもよろしいですか？',
      okText: constants.YES,
      cancelText: constants.CANCEL,
      onOk: handleDeleteGroup,
    });
  };

  /**
   * Custom select all
   * @param {*} e
   */
  const onSelectAllChanged = (e) => {
    const checked = e.target.checked;
    const rows = data.map((u) => u.id);

    setSelectAll(checked);
    if (checked) {
      setSelectedRows(rows); // Set checked all current records
      setExportData([]); // Reset to export all data
    } else setSelectedRows([]);
  };

  /**
   * Excute search when page has changed
   */
  useEffect(() => {
    form.submit();
  }, [page]);

  /**
   * Selected all when giveaway appended
   */
  useEffect(() => {
    const selected = selectedRowKeys;
    const uids = data.map((u) => u.id);

    if (selectAll) {
      const selectedRowKeys = uniq([...selected, ...uids]);
      setSelectedRows(selectedRowKeys);
    }
  }, [data]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (rows) => {
      const exportSelecteGiveaways = data.filter((x) => rows.includes(x.id));
      setExportData(exportSelecteGiveaways);
      setSelectedRows(rows);
    },
    hideSelectAll: true,
    onSelect: (_, selected) => {
      if (!selected && selectAll) setSelectAll(false);
    },
    preserveSelectedRowKeys: true,
    columnTitle: <Checkbox checked={selectAll} onChange={onSelectAllChanged} />,
  };

  return (
    <div style={{ background: '#fff' }}>
      <div className="admin-container">
        <h1>グループ一覧</h1>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 0]}>
          <Col xs={24} md={24} sm={24} lg={4}>
            <div className="left-container">
              <div className="left-header">
                <Space>
                  <Button
                    onClick={() => addModal.current.show()}
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    size={40}
                  />

                  <Button
                    type="primary"
                    danger
                    disabled={selectedRowKeys.length <= 0}
                    icon={<DeleteFilled />}
                    size={40}
                    onClick={confirmDelete}
                  >
                    Delete
                  </Button>
                </Space>
              </div>
              <Form
                form={form}
                layout="vertical"
                style={{
                  marginTop: 15,
                }}
                name="s-form"
                initialValues={{ searchText: '' }}
                onFinish={getGroupData}
              >
                {isAdmin && (
                  <Form.Item name="companyCode" label="会社コードで検索">
                    <Input placeholder="会社コードで検索" />
                  </Form.Item>
                )}

                <Form.Item name="searchText" label="グループの名前で検索">
                  <Input placeholder="グループの名前" />
                </Form.Item>
                <Form.Item>
                  <div className="search-btn">
                    <Space direction="vertical" align="center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SearchOutlined />}
                        size={40}
                        onClick={() => setPage(1)}
                      >
                        検索する
                      </Button>

                      <CSVExportButton
                        data={exportData}
                        headers={[
                          { label: 'id', key: 'id' },
                          { label: 'グループアイコン', key: 'groupIcon' },
                          { label: 'グループの名前', key: 'groupName' },
                          { label: 'グループのユーザー', key: 'usersGroup' },
                          { label: '会社コード', key: 'companyCode' },
                        ]}
                        fileName={`Group_${today}.csv`}
                        fetchCSVData={exportCSV}
                        fetched={fetchedExportData}
                      />
                    </Space>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Col>

          <Col xs={24} md={24} sm={24} lg={20}>
            <Table
              rowKey="id"
              className="table-sticky-header"
              rowSelection={rowSelection}
              dataSource={data}
              pagination={false}
              loading={requesting}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="データなし"
                  />
                ),
              }}
            >
              <Table.Column
                title="グループの名前"
                dataIndex="groupName"
                key="groupName"
                render={(_, row) => (
                  <a onClick={() => editorModalRef.current.show(row)}>
                    <Space>
                      <Avatar src={row.groupIcon || ic_default_group_avatar} />
                      <span>{row.groupName}</span>
                    </Space>
                  </a>
                )}
              />

              <Table.Column
                title="ユーザー数"
                key="usersGroup"
                dataIndex="usersGroup"
                render={(text, row) => (
                  <Typography.Text>
                    {get(row, 'usersGroup', []).length}
                  </Typography.Text>
                )}
              />
              <Table.Column
                title="会社コード"
                key="companyCode"
                dataIndex="companyCode"
              />
            </Table>
            <Pagination
              page={page}
              isBackDisabled={page === 1 || requesting}
              onClickBack={() => setPage(page - 1)}
              isNextDisabled={!canLoadMore || requesting}
              onClickNext={() => setPage(page + 1)}
            />
          </Col>
        </Row>
      </div>
      <GroupEditorModal
        ref={editorModalRef}
        onSaved={(isEdit) => {
          if (!isEdit) form.submit();
        }}
      />

      <GroupAddModal ref={addModal} onUpdated={() => form.submit()} />
    </div>
  );
}

export default GroupManagement;
