import * as Actions from '../actions';
import * as Services from 'services';
import { getEmocaImages as getEmocaImagesService } from 'services';
import { get } from 'lodash';

export const getEmocaImages = ({ page, introductoryText } = {}) => (dispatch, getState) => {
  dispatch({ type: Actions.GET_EMOCA_IMAGES });
  const state = getState();
  const lastDocs = get(state, 'emoca.emocaImages.lastDocs', []);
  const nextPage = page + 1;
  const lastDoc = lastDocs.find((item) => item.page === page).lastDoc;

  getEmocaImagesService(introductoryText, lastDoc)
    .then(({ data, lastDoc, canLoadMore }) => {
      console.log(data);
      dispatch({
        type: Actions.GET_EMOCA_IMAGES_SUCCESS,
        payload: {
          data,
          lastDocs: [
            ...lastDocs.filter((item) => item.page !== nextPage),
            { page: nextPage, lastDoc },
          ],
          canLoadMore,
          page,
        },
      });
    })
    .catch((error) => dispatch({ type: Actions.GET_EMOCA_IMAGES_FAIL, error }));
};

export const getEmoca = ({ page, searchText, companyCode }) => async (
  dispatch,
  getState
) => {
  dispatch({ type: Actions.GET_EMOCA_REQUEST });
  try {
    const state = getState();
    const lastDocs = get(state, 'emoca.emoca.result.lastDocs', []);
    const nextPage = page + 1;

    const cursor = lastDocs.find((item) => item.page === page).lastDoc;

    const result = await Services.getEmoca({
      searchText,
      lastDoc: cursor,
      companyCode,
    });
    const { data, lastDoc, canLoadMore } = result;

    dispatch({
      type: Actions.GET_EMOCA_SUCCESS,
      payload: {
        data,
        lastDocs: [
          ...lastDocs.filter((item) => item.page !== nextPage),
          { page: nextPage, lastDoc },
        ],
        canLoadMore,
        page,
      },
    });
  } catch (error) {
    dispatch({ type: Actions.GET_EMOCA_FAIL, error });
  }
};

export const saveEmoca = (params) => async (dispatch) => {
  dispatch({ type: Actions.SAVE_EMOCA_REQUEST });
  try {
    await Services.saveEmoca(params);

    dispatch({
      type: Actions.SAVE_EMOCA_SUCCESS,
      payload: params,
    });
  } catch (error) {
    dispatch({ type: Actions.SAVE_EMOCA_FAIL, error });
  }
};
