import React, { useState, useEffect, useRef } from 'react';
import { Table, Row, Col, Button, Space, Modal, Form, Input, Empty, Checkbox, Typography } from 'antd';
import {
  DeleteFilled,
  VerticalAlignTopOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getGiveaway } from 'redux-store/giveaways/action';
import { get, uniq } from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import numeral from 'numeral';

import constants from 'commons/constants';
import { CSVExportButton, Pagination, CSVImport } from 'components';
import { getAllGiveawayByCondition, deleteGiveaways, saveGiveawayFromCSV } from 'services';
import GiveawayEditorModal from './GiveawayEditorModal';
import './style.scss';

function GiveawayManagement() {
  const today = moment().format(constants.DATE_FILE_FORMAT);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const editorModalRef = useRef();
  /**
   * States
   */
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowKeys, setSelectedRows] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [fetchedExportData, setFetchedExportData] = useState(false);
  const [page, setPage] = useState(1);
  const giveaway = useSelector((state) => get(state, 'giveaway.giveaway'));

  const requesting = get(giveaway, 'requesting', false);
  const canLoadMore = get(giveaway, 'result.canLoadMore', false);

  const data = get(giveaway, 'result.data', []);

  const getGiveawayData = ({ searchText }) => {
    dispatch(getGiveaway({ page, searchText }));
  };

  const handleDeleteGiveaways = () => {
    deleteGiveaways(selectedRowKeys, selectAll)
      .then(() => {
        setSelectedRows([]);
        toast.success('景品は削除されました。');
        form.submit();
      })
      .catch((err) => {
        toast.error('何かがうまくいかなかった。');
        console.log(err);
      });
  };

  const exportCSV = async () => {
    const { searchText } = form.getFieldsValue(['searchText']);

    if (data.length <= 0) return; // No data to export

    if (selectAll || (selectedRowKeys.length === 0 && data.length >= 0)) {
      // Export all
      setFetchedExportData(false);
      getAllGiveawayByCondition({ searchText }).then((res) => {
        setExportData(res);
        setFetchedExportData(true);
      });
    }
  };

  /**
   * Show confirm delete modal
   */
  const confirmDelete = () => {
    Modal.confirm({
      centered: true,
      title: constants.MODAL.CONFIRM,
      icon: <ExclamationCircleOutlined />,
      content: '景品を削除してもよろしいですか？',
      okText: constants.YES,
      cancelText: constants.CANCEL,
      onOk: handleDeleteGiveaways,
    });
  };

  /**
   * Custom select all
   * @param {*} e
   */
  const onSelectAllChanged = (e) => {
    const checked = e.target.checked;
    const rows = data.map((u) => u.id);

    setSelectAll(checked);
    if (checked) {
      setSelectedRows(rows); // Set checked all current records
      setExportData([]); // Reset to export all data
    } else setSelectedRows([]);
  };

  /**
   * Excute search when page has changed
   */
  useEffect(() => {
    form.submit();
  }, [page]);

  /**
   * Selected all when giveaway appended
   */
  useEffect(() => {
    const selected = selectedRowKeys;
    const uids = data.map((u) => u.id);

    if (selectAll) {
      const selectedRowKeys = uniq([...selected, ...uids]);
      setSelectedRows(selectedRowKeys);
    }
  }, [data]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (rows) => {
      const exportSelecteGiveaways = data.filter((x) => rows.includes(x.id));
      setExportData(exportSelecteGiveaways);
      setSelectedRows(rows);
    },
    hideSelectAll: true,
    onSelect: (_, selected) => {
      if (!selected && selectAll) setSelectAll(false);
    },
    preserveSelectedRowKeys: true,
    columnTitle: <Checkbox checked={selectAll} onChange={onSelectAllChanged} />,
  };

  return (
    <div style={{ background: '#fff' }}>
      <div className="admin-container">
        <h1>景品の設定</h1>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 0]}>
          <Col xs={24} md={24} sm={24} lg={4}>
            <div className="left-container">
              <div className="left-header">
                <Space>
                  <Button
                    onClick={() => editorModalRef.current.show()}
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    size={40}
                  />

                  <CSVImport
                    headerMappings={[
                      { key: '項目名', label: 'text' },
                      { key: '解説', label: 'explanatoryText' },
                      { key: '画像', label: 'image' },
                      { key: '量', label: 'numberOfStock' },
                      { key: '価格', label: 'price' },
                    ]}
                    onUpload={(data) => saveGiveawayFromCSV(data)}
                    onUploadFinish={() => form.submit()}
                  />

                  {selectedRowKeys.length > 0 && (
                    <Button
                      type="primary"
                      shape="circle"
                      danger
                      icon={<DeleteFilled />}
                      size={40}
                      onClick={confirmDelete}
                    />
                  )}
                </Space>
              </div>
              <Form
                form={form}
                layout="vertical"
                style={{
                  marginTop: 15,
                }}
                name="s-form"
                initialValues={{ searchText: '' }}
                onFinish={getGiveawayData}
              >
                <Form.Item name="searchText" label="項目名で検索">
                  <Input placeholder="項目名" />
                </Form.Item>
                <Form.Item>
                  <div className="search-btn">
                    <Space direction="vertical" align="center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SearchOutlined />}
                        size={40}
                        onClick={() => setPage(1)}
                      >
                        検索する
                      </Button>

                      <CSVExportButton
                        data={exportData}
                        headers={[
                          { label: 'id', key: 'id' },
                          { label: '項目名', key: 'text' },
                          { label: '解説', key: 'explanatoryText' },
                          { label: '画像', key: 'image' },
                          { label: '量', key: 'numberOfStock' },
                          { label: '価格', key: 'price' },
                        ]}
                        fileName={`Giveaway_${today}.csv`}
                        fetchCSVData={exportCSV}
                        fetched={fetchedExportData}
                      />
                    </Space>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Col>

          <Col xs={24} md={24} sm={24} lg={20}>
            <Table
              rowKey="id"
              className="table-sticky-header"
              rowSelection={rowSelection}
              dataSource={data}
              pagination={false}
              loading={requesting}
              locale={{
                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="データなし" />,
              }}
              // onRow={(data) => ({
              //   onClick: () => openEditModal(data),
              // })}
            >
              <Table.Column
                title="画像"
                dataIndex="image"
                key="image"
                render={(image, row) => (
                  <img src={row.image} className="item-img" alt="" onClick={() => editorModalRef.current.show(row)} />
                )}
              />

              <Table.Column
                title="項目名"
                key="text"
                dataIndex="text"
                render={(image, row) => (
                  <a href onClick={() => editorModalRef.current.show(row)}>
                    {row.text}
                  </a>
                )}
              />
              <Table.Column title="解説" key="explanatoryText" dataIndex="explanatoryText" />
              <Table.Column title="量" key="numberOfStock" dataIndex="numberOfStock" />
              <Table.Column
                title="価格"
                key="price"
                dataIndex="price"
                render={(image, row) => (
                  <Typography.Text>
                    {numeral(get(row, 'price', 0)).format('0,0')}
                  </Typography.Text>
                )}
              />
            </Table>
            <Pagination
              page={page}
              isBackDisabled={page === 1 || requesting}
              onClickBack={() => setPage(page - 1)}
              isNextDisabled={!canLoadMore || requesting}
              onClickNext={() => setPage(page + 1)}
            />
          </Col>
        </Row>
      </div>
      <GiveawayEditorModal
        ref={editorModalRef}
        onSaved={(isEdit) => {
          if (!isEdit) form.submit();
        }}
      />
    </div>
  );
}

export default GiveawayManagement;
