import { firebase } from 'commons/configs';
import constants from 'commons/constants';
import { last, sortBy, get, omit } from 'lodash';
import { rmUndefinedProperties } from 'utils/common';
const firestore = firebase.firestore();

export const getAllEventCodes = (condition, lastDoc) => {
  return new Promise(async (resolve, reject) => {
    try {
      let query = firestore.collection(constants.COLLECTION_EVENT_CODE);
      if (condition)
        query = query.where('eventOccurrenceConditions', '==', condition);

      query = query.where('isDeleted', '==', false);

      if (lastDoc) query = query.startAfter(lastDoc);

      const { docs } = await query.limit(constants.PAGE_LIMIT).get();

      const data = docs.map((cpn) => ({
        id: cpn.id,
        ...cpn.data(),
      }));

      resolve({
        data: sortBy(data, [
          function (e) {
            return Number(e.eventCode);
          },
        ]),
        lastDoc: last(docs),
        canLoadMore: docs.length === constants.PAGE_LIMIT,
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getAllEventCodesByCondition = (condition) => {
  return new Promise(async (resolve, reject) => {
    try {
      let query = firestore.collection(constants.COLLECTION_EVENT_CODE);
      if (condition)
        query = query.where('eventOccurrenceConditions', '==', condition);

      query = query.where('isDeleted', '==', false);

      const { docs } = await query.get();

      const data = docs.map((cpn) => ({
        id: cpn.id,
        ...cpn.data(),
      }));

      resolve(
        sortBy(data, [
          function (e) {
            return Number(e.eventCode);
          },
        ])
      );
    } catch (err) {
      reject(err);
    }
  });
};

export const addOrUpdateEventCode = async (data) => {
  const id = get(data, 'id');
  const { eventCode } = data;
  const values = rmUndefinedProperties({...data, eventCode: ''+ eventCode});

  /**
   * Create event code
   */
  if (!id) {
    const duplicateEventCode = await checkDuplicateByEventCode(eventCode);
    if (duplicateEventCode) {
      return Promise.reject({ message: 'EvenCodeは既に存在しています。' });
    }

    return firestore.collection(constants.COLLECTION_EVENT_CODE).add({
      ...values,
      isDeleted: false,
      createdAt: new Date(),
    });
  }

  /**
   * Otherwise update event code
   */
  const updatedData = omit(values, ['id']);
  return firestore
    .collection(constants.COLLECTION_EVENT_CODE)
    .doc(id)
    .update(updatedData);
};

export const checkDuplicateByEventCode = async (eventCode) => {
  const { docs } = await firestore
    .collection(constants.COLLECTION_EVENT_CODE)
    .where('eventCode', '==', `${eventCode}`)
    .where('isDeleted', '==', false)
    .limit(1)
    .get();

  return docs.length > 0;
};

export const deleteEventCodes = async (eventCodeIds, isDeleteAll) => {
  const eventCodeRef = firestore.collection(constants.COLLECTION_EVENT_CODE);
  if (isDeleteAll) {
    const { docs } = await eventCodeRef.where('isDeleted', '==', false).get();
    const promises = docs.map((doc) => doc.ref.update({ isDeleted: true }));
    return Promise.all(promises);
  } else {
    const promises = eventCodeIds.map((id) =>
      eventCodeRef.doc(id).update({ isDeleted: true })
    );
    return Promise.all(promises);
  }
};

export const addEventCodeFromCSV = (eventCodes) => {
  const eventCodesData = eventCodes.map(evc => ({
    ...evc,
    eventProduction: get(evc, 'eventProduction', '').split(',').map(d => d.trim())
  }));

  const promises = eventCodesData.map((eventCode) => addOrUpdateEventCode(eventCode));
  return Promise.all(promises);
};
