import * as Actions from '../actions';
import { getLayerMasterData, getLayerDataExport } from 'services';
import { get } from 'lodash';

export const getLayers = ({ page, layer1, layer2, layer3, companyCode } = {}) => (
  dispatch,
  getState
) => {
  dispatch({ type: Actions.GET_LAYERS });
  const state = getState();
  const lastDocs = get(state, 'layer.layers.lastDocs', []);
  const nextPage = page + 1;
  const lastDoc = lastDocs.find((item) => item.page === page)?.lastDoc;

  getLayerMasterData({ layer1, layer2, layer3, companyCode, lastDoc })
    .then(({ data, lastDoc, canLoadMore }) => {
      dispatch({
        type: Actions.GET_LAYERS_SUCCESS,
        payload: {
          data,
          lastDocs: [
            ...lastDocs.filter((item) => item.page !== nextPage),
            { page: nextPage, lastDoc },
          ],
          canLoadMore,
          page,
        },
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: Actions.GET_LAYERS_FAIL, error });
    });
};

export const getLayerDropdown = () => (dispatch) => {
  dispatch({ type: Actions.GET_ALL_LAYERS });
  getLayerDataExport()
    .then((res = []) => {
      const data = res.map((d) => ({
        id: d.id,
        label: [d.layer1, d.layer2, d.layer3].filter((x) => x).join('/'),
      }));
      dispatch({ type: Actions.GET_ALL_LAYERS_SUCCESS, data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: Actions.GET_ALL_LAYERS_SUCCESS });
    });
};
