import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyCIk25V3nERHsWzmSWaj4-8Ihnf0w-4E5k",
  authDomain: "emoca-jpn.firebaseapp.com",
  databaseURL: "https://emoca-jpn.firebaseio.com",
  projectId: "emoca-jpn",
  storageBucket: "emoca-jpn.appspot.com",
  messagingSenderId: "1069687412615",
  appId: "1:1069687412615:web:2f4a56fc56ca866c226522",
  measurementId: "G-PMGYBJZBZV"
};

firebase.initializeApp(firebaseConfig)

export default firebase;
