import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useState,
} from 'react';
import { Modal, Form, Input } from 'antd';
import useStateCallback from 'use-state-with-callback';
import constants from 'commons/constants';
import { toast } from 'react-toastify';
import { updateThemeHistory } from 'services';

const EditModal = forwardRef((props, ref) => {
  const modalRef = useRef();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [visible, setVisible] = useStateCallback(false, (visible) => {
    if (!visible) setThemeHistory(null);
  });

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const [themeHistory, setThemeHistory] = useStateCallback(
    null,
    (themeHistory) => {
      const { id, eventCode, companyCode, themeCode, userId } =
        themeHistory || {};

      form.setFieldsValue({
        id,
        eventCode,
        companyCode,
        themeCode,
        userId,
      });
    }
  );

  const show = (themeHistory) => {
    setThemeHistory(themeHistory);
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
    form.resetFields(); // reset form
  };

  const onOK = async () => {
    const { id } = themeHistory || {};
    const {
      userId = '',
      eventCode = '',
      themeCode = '',
      companyCode = '',
    } = form.getFieldsValue();

    setLoading(true);
    updateThemeHistory({
      id,
      userId: userId.trim(),
      eventCode: ("" + eventCode).trim(),
      themeCode: ("" + themeCode).trim(),
      companyCode: ("" + companyCode).trim(),
    })
      .then(() => {
        setLoading(false);
        toast.success('テーマの完全な履歴を更新しました。');
        props.onUpdated && props.onUpdated();
        hide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  return (
    <div ref={modalRef}>
      <Modal
        forceRender={true}
        title={'テーマの歴史を編集する'}
        centered
        visible={visible}
        okText={constants.YES}
        cancelText={constants.CANCEL}
        okButtonProps={{
          form: 'ftheme-history',
          key: 'submit',
          htmlType: 'submit',
        }}
        confirmLoading={loading}
        onCancel={hide}
      >
        <Form
          form={form}
          name="ftheme-history"
          onFinish={onOK}
          labelCol={{ span: 7 }}
          labelAlign="left"
        >
          <Form.Item label="イベントコード" name="eventCode">
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="会社コード"
            name="companyCode"
            rules={[{ required: true, message: '会社コードが必要です。' }]}
          >
            <Input placeholder="会社コード" />
          </Form.Item>

          <Form.Item
            label="テーマコード"
            name="themeCode"
            rules={[{ required: true, message: 'テーマコードは必須です。' }]}
          >
            <Input placeholder="テーマコード" />
          </Form.Item>

          <Form.Item
            label="ユーザーID"
            name="userId"
            rules={[{ required: true, message: 'ユーザーIDが必要です。' }]}
          >
            <Input placeholder="ユーザーID" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default EditModal;
