import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import { Modal, Form, Input, InputNumber } from 'antd';
import useStateCallback from 'use-state-with-callback';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { saveMedalTransmissionHistory } from 'redux-store/medal-transmission/action';
import constants from 'commons/constants';
import { has, get } from 'lodash';

const MedalTransmissionEditorModal = ({ onSaved }, ref) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useStateCallback(false, (val) => {
    if (!val) {
      setMedalTransmissionHistory(null);
    }
  });

  const setFormValues = (medalTransmissionHistory) => {
    if (medalTransmissionHistory) {
      const { id, amount, description, type } = medalTransmissionHistory;
      form.setFieldsValue({ id, amount, description, type });
    } else {
      form.resetFields();
    }
  };

  const [medalTransmissionHistory, setMedalTransmissionHistory] = useStateCallback(null, setFormValues);
  const [requesting, setRequesting] = useState();
  const savingMedalTransmission = useSelector((state) => get(state, 'medalTransmission.savingMedalTransmission'));

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const hide = () => {
    setModalVisible(false);
  };

  const show = (medalTransmissionHistory) => {
    setModalVisible(true);
    setMedalTransmissionHistory(medalTransmissionHistory);
  };

  useEffect(() => {
    const { status } = savingMedalTransmission;
    if (status === 'success') {
      toast.success('メダルの送信を正常に保存します。');
    } else if (status === 'error') {
      toast.error('何かがうまくいかなかった。');
    }
  }, [savingMedalTransmission]);

  const submitEmocaForm = async () => {
    try {
      setRequesting(true);
      const values = form.getFieldsValue(['id', 'amount', 'description', 'type']);
      await form.validateFields();
      await dispatch(saveMedalTransmissionHistory(values));
      hide();
    } catch (error) {
      toast.error('何かがうまくいかなかった。');
    } finally {
      setRequesting(false);
    }
  };

  const isEmocaType = get(medalTransmissionHistory, 'type') === constants.WALLET_HISTORY_TYPES.emoca;

  return (
    <Modal
      title={'メダル送信履歴を編集する'}
      centered
      visible={modalVisible}
      forceRender={true}
      okText={constants.YES}
      cancelText={constants.CANCEL}
      onOk={submitEmocaForm}
      okButtonProps={{
        disabled: requesting,
      }}
      cancelButtonProps={{
        disabled: requesting,
      }}
      // confirmLoading={confirmLoading}
      onCancel={hide}
    >
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} form={form} labelAlign="left">
        <Form.Item name="amount" label="量" rules={[{ required: true, message: '量は必須です。' }]}>
          <InputNumber min={isEmocaType ? Number.NEGATIVE_INFINITY : 0} />
        </Form.Item>
        <Form.Item name="description" label="解説" rules={[{ required: true, message: '解説は必須です。' }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default forwardRef(MedalTransmissionEditorModal);
