import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import { Modal, Form, Input, InputNumber } from 'antd';
import constants from 'commons/constants';
import { addOrUpdateRole } from 'services';
import { toast } from 'react-toastify';
import useStateCallback from 'use-state-with-callback';
import { get } from 'lodash';

const RoleAddOrEditModal = forwardRef((props, ref) => {
  const inputRef = useRef();
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const { onUpdated } = props;
  const [visible, setVisible] = useStateCallback(false, (visible) => {
    if (!visible) setRole(null);
  });

  const [roles, setRole] = useStateCallback(null, (roles) => {
    const { id, name, role, text } = roles || {};
    form.setFieldsValue({
      id,
      name,
      role,
      text,
    });
  });

  const [loading, setLoading] = useState(false);
  const isAddNew = get(roles, 'id', null) === null;

  const show = (roles) => {
    setRole(roles);
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
    form.resetFields(); // reset form
  };

  const onOK = async () => {
    const { id, name, role, text } = form.getFieldsValue([
      'id',
      'name',
      'role',
      'text',
      'isDeleted',
    ]);
    form
      .validateFields()
      .then(() => {
        setLoading(true);
        return addOrUpdateRole({
          id,
          name,
          role,
          text,
          isDeleted: false,
        });
      })
      .then(() => {
        let msg = id ? 'テーマが更新されました！' : 'テーマが追加されました！';

        toast.success(msg);
        setLoading(false);
        onUpdated && onUpdated();
        hide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
        hide();
      });
  };

  const disabledAction = () => {
    if(isAddNew) return false;
    const role = get(roles, 'role', 1);
    return constants.DISABLED_ROLES.indexOf(role) > -1;
  }

  return (
    <div ref={inputRef}>
      <Modal
        forceRender={true}
        title={isAddNew ? 'テーマを追加' : 'テーマを編集する'}
        centered
        visible={visible}
        okText={constants.YES}
        cancelText={constants.CANCEL}
        okButtonProps={{ form: 'cpn-form', key: 'submit', htmlType: 'submit' }}
        confirmLoading={loading}
        onCancel={hide}
      >
        <Form
          form={form}
          name='cpn-form'
          onFinish={onOK}
          labelCol={{ span: 7 }}
          labelAlign='left'
        >
          <Form.Item
            name='name'
            label='名前'
            rules={[{ required: true, message: '名前は必須です。' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name='role'
            label='役割'
            rules={[{ required: true, message: '役割が必要です。' }, 
            {
              validator: (_, v) => {
                if (v >= 0) return Promise.resolve();
                else
                  return Promise.reject(
                    '役割の値は0以上でなければなりません。!'
                  );
              },
            },]}
          >
            <InputNumber disabled={disabledAction()} type="number"/>
          </Form.Item>
          <Form.Item
            name='text'
            label='テキスト'
            rules={[{ required: true, message: 'テキストが必要です' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default RoleAddOrEditModal;
