import * as Actions from '../actions';

let initState = {
  medalTransmission: {
    requesting: false,
    result: {
      data: [],
      lastDocs: [
        {
          page: 1,
          lastDoc: null,
        }
      ]
    },
    error: null,
    status: '',
  },
  savingMedalTransmission: {
    requesting: false,
    result: null,
    error: null,
    status: '',
  },
};

export default function userReducers(state = initState, action) {
  switch (action.type) {
    case Actions.GET_MEDAL_TRANSMISSION_HISTORY_REQUEST:
      return {
        ...state,
        medalTransmission: {
          ...state.medalTransmission,
          requesting: true,
          status: '',
        },
      };

    case Actions.GET_MEDAL_TRANSMISSION_HISTORY_SUCCESS:
      return {
        ...state,
        medalTransmission: {
          ...state.medalTransmission,
          requesting: false,
          result: action.payload,
          status: 'success',
        },
      };

    case Actions.GET_MEDAL_TRANSMISSION_HISTORY_FAIL:
      return {
        ...state,
        medalTransmission: {
          ...state.medalTransmission,
          requesting: false,
          error: action.error,
          status: 'error',
        },
      };
    case Actions.SAVE_MEDAL_TRANSMISSION_HISTORY_REQUEST:
      return {
        ...state,
        savingMedalTransmission: {
          ...state.savingMedalTransmission,
          requesting: true,
          status: '',
        },
      };

    case Actions.SAVE_MEDAL_TRANSMISSION_HISTORY_SUCCESS:
      const medalTransmissionData = state.medalTransmission.result.data;

      return {
        ...state,
        savingMedalTransmission: {
          ...state.savingMedalTransmission,
          requesting: false,
          result: action.payload,
          status: 'success',
        },
        medalTransmission: {
          ...state.medalTransmission,
          result: {
            ...state.medalTransmission.result,
            data: medalTransmissionData.map(item => {
                if(item.id === action.payload.id) {
                  return {...item, ...action.payload}
                }
                return item
              })
          }
        },
      };

    case Actions.SAVE_MEDAL_TRANSMISSION_HISTORY_FAIL:
      return {
        ...state,
        savingMedalTransmission: {
          ...state.savingMedalTransmission,
          requesting: false,
          error: action.error,
          status: 'error',
        },
      };
    default:
      return state;
  }
}
