import { firebase } from 'commons/configs';
import constants from 'commons/constants';
import { get, last } from 'lodash';
import moment from 'moment';
import { rmUndefinedProperties } from 'utils/common';
const firestore = firebase.firestore();

export const getNavisapoData = async (name, lastDoc) => {
  return new Promise(async (resolve, reject) => {
    try {
      let navisapoRef = firestore.collection(
        constants.COLLECTION_NAVISAPO_IMAGE
      );
      if (name) {
        navisapoRef = firestore
          .collection(constants.COLLECTION_NAVISAPO_IMAGE)
          .where('name', '==', name);
      }

      navisapoRef = navisapoRef
        .where('isDeleted', '==', false)
        .orderBy('createdAt', 'desc');

      if (lastDoc) navisapoRef = navisapoRef.startAfter(lastDoc);

      const { docs } = await navisapoRef.limit(constants.PAGE_LIMIT).get();
      const data = docs.map((navisapo) => ({
        id: navisapo.id,
        ...navisapo.data(),
      }));
      resolve({
        data,
        lastDoc: last(docs),
        canLoadMore: docs.length === constants.PAGE_LIMIT,
      });
    } catch (err) {
      console.log(err)
      reject(err);
    }
  });
};

export const getAllNavisapoByCondition = async ({ name } = {}) => {
  try {
    let query = firestore.collection(constants.COLLECTION_NAVISAPO_IMAGE);

    if (name) query = query.where('name', '==', name);

    const { docs } = await query
      .where('isDeleted', '==', false)
      .orderBy('createdAt', 'desc')
      .get();

    const data = docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
      createdAt: moment(doc.data().createdAt.toDate()).format(
        constants.DATE_TIME_FORMAT
      ),
    }));

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteNavisapo = async (navisapoIds, isDeleteAll) => {
  const navisapoRef = firestore.collection(constants.COLLECTION_NAVISAPO_IMAGE);
  if (isDeleteAll) {
    const { docs } = await navisapoRef.where('isDeleted', '==', false).get();
    const promises = docs.map((doc) => doc.ref.update({ isDeleted: true }));
    return Promise.all(promises);
  } else {
    const promises = navisapoIds.map((id) =>
      navisapoRef.doc(id).update({ isDeleted: true })
    );
    return Promise.all(promises);
  }
};

export const addOrUpdateNavisapo = async (navisapo) => {
  const id = get(navisapo, 'id');
  const { name, image } = navisapo;
  /**
   * Update if id has existed
   */

  if (!id) {
    const duplicateNavisapo = await checkDuplicateByName(name);
    if (duplicateNavisapo)
      return Promise.reject({ message: 'Navisapoは既に存在しています。' });

    // pickBy funtion used to remove underfine properties
    return firestore.collection(constants.COLLECTION_NAVISAPO_IMAGE).add(
      rmUndefinedProperties({
        ...navisapo,
        isDeleted: false,
        createdAt: new Date(),
      })
    );
  }

  /**
   * Otherwise add new theme
   */
  return firestore
    .collection(constants.COLLECTION_NAVISAPO_IMAGE)
    .doc(id)
    .update({
      image,
      isDeleted: false,
      name,
    });
};

export const checkDuplicateByName = async (name) => {
  const { docs } = await firestore
    .collection(constants.COLLECTION_NAVISAPO_IMAGE)
    .where('name', '==', name)
    .where('isDeleted', '==', false)
    .limit(1)
    .get();

  return docs.length > 0;
};

export const addNavisapoFromCSV = (navisapos) => {
  const promises = navisapos.map((navisapo) => addOrUpdateNavisapo(navisapo));
  return Promise.all(promises);
};
