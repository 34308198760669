import * as Actions from '../actions';

let initState = {
  users: {
    page: 1,
    data: [],
    lastDocs: [
      {
        page: 1,
        lastDoc: null,
      },
    ],
    loading: false,
  },
  roles: {
    data: [],
    loading: false,
  },
  dropdownList: {
    data: [],
    requesting: false,
    error: '',
  },
};

export default function userReducers(state = initState, action) {
  switch (action.type) {
    case Actions.GET_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        },
      };

    case Actions.GET_USERS_SUCCESS:
      return {
        ...state,
        users: {
          loading: false,
          data: action.payload.data,
          lastDocs: action.payload.lastDocs,
          canLoadMore: action.payload.canLoadMore,
          page: action.payload.page,
        },
      };

    case Actions.GET_USERS_FAIL:
      return {
        ...state,
        users: {
          loading: false,
        },
      };
    case Actions.GET_ROLES:
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: true,
        },
      };

    case Actions.GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: {
          loading: false,
          data: action.payload.data,
        },
      };

    case Actions.GET_ROLES_FAIL:
      return {
        ...state,
        roles: {
          loading: false,
        },
      };

    case Actions.GET_DROPDOWNLIST_USERS:
      return {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          requesting: true,
        },
      };

    case Actions.GET_DROPDOWNLIST_USERS_SUCCESS:
      return {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          data: action.data,
          requesting: false,
        },
      };

    case Actions.GET_DROPDOWNLIST_USERS_FAIL:
      return {
        ...state,
        dropdownList: {
          ...state.dropdownList,
          requesting: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
}
