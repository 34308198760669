import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Form,
  Input,
  Empty,
  Checkbox,
  Typography,
  Avatar,
  Tag,
  Tooltip,
} from 'antd';

import {
  DeleteFilled,
  ExclamationCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getEmoca } from 'redux-store/emoca/actions';
import { get, uniq } from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import numeral from 'numeral';

import { blockUI, unblockUI } from 'redux-store/blocking-ui/action';
import constants from 'commons/constants';
import { CSVExportButton, Pagination, CSVImport } from 'components';
import { deleteEmocas, getAllEmocaByCondition } from 'services';
import { ic_default_user, ic_default_group_avatar } from 'assets/images';
import { flatEmocaDataForExport } from 'utils/common';
import EmocaEditorModal from './EmocaEditorModal';
import './style.scss';

function EmocaHistory() {
  const today = moment().format(constants.DATE_FILE_FORMAT);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const editorModalRef = useRef();

  const manager = useSelector((state) => get(state, 'auth.login.result'));
  const companyCode = get(manager, 'companyCode');
  /**
   * States
   */
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowKeys, setSelectedRows] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [fetchedExportData, setFetchedExportData] = useState(false);
  const [page, setPage] = useState(1);
  const emoca = useSelector((state) => get(state, 'emoca.emoca'));

  const requesting = get(emoca, 'requesting', false);
  const canLoadMore = get(emoca, 'result.canLoadMore', false);

  const data = get(emoca, 'result.data', []);

  const getEmocaData = ({ searchText }) => {
    dispatch(getEmoca({ page, searchText, companyCode }));
  };

  const handleDeleteEmocas = () => {
    dispatch(blockUI());

    deleteEmocas(selectedRowKeys, selectAll, companyCode)
      .then(() => {
        setSelectedRows([]);
        toast.success('Emolcaは削除されました。');
        form.submit();
      })
      .catch((err) => {
        toast.error('何かがうまくいかなかった。');
        console.log(err);
      })
      .finally(() => {
        dispatch(unblockUI());
      });
  };

  const exportCSV = async () => {
    const { searchText } = form.getFieldsValue(['searchText']);

    if (data.length <= 0) return; // No data to export

    if (selectAll || (selectedRowKeys.length === 0 && data.length >= 0)) {
      // Export all
      setFetchedExportData(false);
      dispatch(blockUI());
      getAllEmocaByCondition({ searchText, companyCode })
        .then((res) => {
          dispatch(unblockUI());
          setExportData(res);
          setFetchedExportData(true);
        })
        .catch(() => dispatch(unblockUI()));
    }
  };

  /**
   * Show confirm delete modal
   */
  const confirmDelete = () => {
    Modal.confirm({
      centered: true,
      title: constants.MODAL.CONFIRM,
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to delete emolca(s)?',
      okText: constants.YES,
      cancelText: constants.CANCEL,
      onOk: handleDeleteEmocas,
    });
  };

  /**
   * Custom select all
   * @param {*} e
   */
  const onSelectAllChanged = (e) => {
    const checked = e.target.checked;
    const rows = data.map((u) => u.id);

    setSelectAll(checked);
    if (checked) {
      setSelectedRows(rows); // Set checked all current records
      setExportData([]); // Reset to export all data
    } else setSelectedRows([]);
  };

  /**
   * Excute search when page has changed
   */
  useEffect(() => {
    form.submit();
  }, [page]);

  /**
   * Selected all when giveaway appended
   */
  useEffect(() => {
    const selected = selectedRowKeys;
    const uids = data.map((u) => u.id);

    if (selectAll) {
      const selectedRowKeys = uniq([...selected, ...uids]);
      setSelectedRows(selectedRowKeys);
    }
  }, [data]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (rows) => {
      const exportSelectedEmoca = data.filter((x) => rows.includes(x.id));
      setExportData(exportSelectedEmoca);
      setSelectedRows(rows);
    },
    hideSelectAll: true,
    onSelect: (_, selected) => {
      if (!selected && selectAll) setSelectAll(false);
    },
    preserveSelectedRowKeys: true,
    columnTitle: <Checkbox checked={selectAll} onChange={onSelectAllChanged} />,
  };

  return (
    <div style={{ background: '#fff' }}>
      <div className="admin-container">
        <h1>Emolcaの送信履歴</h1>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 0]}>
          <Col xs={24} md={24} sm={24} lg={3}>
            <div className="left-container">
              <div className="left-header">
              <Space>
                  <Button
                    type="primary"
                    danger
                    disabled={selectedRowKeys.length <= 0}
                    icon={<DeleteFilled />}
                    size={40}
                    onClick={confirmDelete}
                  >
                    Delete
                  </Button>
                </Space>
              </div>
              <Form
                form={form}
                layout="vertical"
                style={{
                  marginTop: 15,
                }}
                name="s-form"
                initialValues={{ searchText: '' }}
                onFinish={getEmocaData}
              >
                <Form.Item name="searchText" label="メッセージで検索">
                  <Input placeholder="メッセージ" />
                </Form.Item>
                <Form.Item>
                  <div className="search-btn">
                    <Space direction="vertical" align="center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SearchOutlined />}
                        size={40}
                        onClick={() => setPage(1)}
                      >
                        検索する
                      </Button>

                      <CSVExportButton
                        data={flatEmocaDataForExport(exportData)}
                        headers={[
                          { label: 'id', key: 'id' },
                          { label: 'メッセージ', key: 'emocaMessage' },
                          { label: '共感拍手', key: 'empathyClap' },
                          { label: 'メダル', key: 'numberOfAttachedMedal' },
                          { label: '添付ファイル名', key: 'attachedFileNames' },
                          {
                            label: '添付ファイルリンク',
                            key: 'attachedFileUrls',
                          },
                          { label: 'emolcaの画像のID', key: 'emocaImageId' },
                          { label: 'Emolcaから返信', key: 'replyEmocaId' },
                          { label: '送信者', key: 'senderName' },
                          { label: '送信者のID', key: 'fromUserId' },
                          { label: 'レシーバー', key: 'receiverNames' },
                          { label: 'レシーバーのID', key: 'toUserIds' },
                          { label: 'グループ', key: 'groupName' },
                          { label: 'グループのID', key: 'groupId' },
                          { label: '作成時間', key: 'createdAtFormated' },
                        ]}
                        fileName={`Emoca_${today}.csv`}
                        fetchCSVData={exportCSV}
                        fetched={fetchedExportData}
                      />
                    </Space>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Col>

          <Col xs={24} md={24} sm={24} lg={21}>
            <Table
              rowKey="id"
              rowClassName="cursor-pointer"
              className="table-sticky-header custom"
              rowSelection={rowSelection}
              dataSource={data}
              pagination={false}
              loading={requesting}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="データなし"
                  />
                ),
              }}
              onRow={(row) => ({
                onClick: () => editorModalRef.current.show(row),
              })}
            >
              <Table.Column
                title="テーマ"
                dataIndex="image"
                key="image"
                width={150}
                render={(image, row) => (
                  <div className="emoca-image-container">
                    <div className="emoca-image-card">
                      <img
                        src={get(row, 'emocaImage.image')}
                        className="emoca-image"
                        alt=""
                      />
                      <img
                        src={get(row, 'emocaImage.emocaIcon')}
                        className="emoca-image-icon"
                        alt=""
                      />
                    </div>
                  </div>
                )}
              />

              <Table.Column
                className="emoca-message-column"
                title="メッセージ"
                key="emocaMessage"
                dataIndex="emocaMessage"
                render={(_, row) => get(row, 'emocaMessage')}
              />

              <Table.Column
                title="共感拍手"
                key="empathyClap"
                dataIndex="empathyClap"
                render={(d) => d || 0}
              />

              <Table.Column
                title="メダル数"
                key="numberOfAttachedMedal"
                dataIndex="numberOfAttachedMedal"
                render={(image, row) => (
                  <Typography.Text>
                    {numeral(get(row, 'numberOfAttachedMedal', 0)).format(
                      '0,0'
                    )}
                  </Typography.Text>
                )}
              />
              <Table.Column
                title="愛着"
                width={100}
                key="attachedFiles"
                dataIndex="attachedFiles"
                render={(image, row) => (
                  <Space direction="vertical">
                    {get(row, 'attachedFiles', []).map((item) => (
                      <Tooltip placement="top" title={item?.name}>
                        <a
                          className="user-name"
                          download={item.name}
                          href={item.url}
                          target="_blank"
                        >
                          ビュー
                        </a>
                      </Tooltip>
                    ))}
                  </Space>
                )}
              />

              <Table.Column
                title="返信フラグ"
                key="replyEmocaId"
                dataIndex="replyEmocaId"
                render={(d) => (
                  <Tag color={d ? 'success' : 'default'} key={d}>
                    {d ? '噫' : '否'}
                  </Tag>
                )}
              />

              <Table.Column
                title="送信者"
                key="fromUser"
                width={200}
                render={(image, row) => (
                  <Space>
                    <Avatar
                      size="small"
                      src={get(row, 'fromUser.avatar') || ic_default_user}
                    />
                    <span className="user-name">
                      {get(row, 'fromUser.displayUserName')}
                    </span>
                  </Space>
                )}
              />
              <Table.Column
                title="レシーバー"
                key="toUsers"
                width={200}
                render={(image, row) => (
                  <Space direction="vertical">
                    {get(row, 'toUsers', []).map((user) => (
                      <Space>
                        <Avatar
                          size="small"
                          src={get(user, 'avatar') || ic_default_user}
                        />
                        <span className="user-name">
                          {get(user, 'displayUserName')}
                        </span>
                      </Space>
                    ))}
                  </Space>
                )}
              />
              <Table.Column
                title="グループ"
                key="group"
                width={200}
                render={(image, row) => (
                  <>
                    {!get(row, 'group.isDM') && (
                      <Space>
                        <Avatar
                          size="small"
                          src={
                            get(row, 'group.groupIcon') ||
                            ic_default_group_avatar
                          }
                        />
                        <span className="user-name">
                          {get(row, 'group.groupName')}
                        </span>
                      </Space>
                    )}
                  </>
                )}
              />

              <Table.Column
                title="作成時間"
                key="createdAt"
                dataIndex="createdAt"
                render={(image, row) => (
                  <Typography.Text>
                    {moment(get(row, 'createdAt').toDate()).format(
                      'YYYY/MM/DD HH:mm'
                    )}
                  </Typography.Text>
                )}
              />
            </Table>
            <Pagination
              page={page}
              isBackDisabled={page === 1 || requesting}
              onClickBack={() => setPage(page - 1)}
              isNextDisabled={!canLoadMore || requesting}
              onClickNext={() => setPage(page + 1)}
            />
          </Col>
        </Row>
      </div>
      <EmocaEditorModal ref={editorModalRef} />
    </div>
  );
}

export default EmocaHistory;
