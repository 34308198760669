import React from 'react';
import { Button, Space, Typography } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

const Pagination = ({ onClickBack, onClickNext, isBackDisabled, isNextDisabled, page }) => {
  return (
    <div style={styles.pagingContainer}>
      <Space>
        {page && <Typography.Text>{`ページ: ${page}`}</Typography.Text>}
        <Button disabled={isBackDisabled} onClick={onClickBack}>
          <LeftOutlined />
        </Button>
        <Button disabled={isNextDisabled} onClick={onClickNext}>
          <RightOutlined />
        </Button>
      </Space>
    </div>
  );
};

const styles = {
  pagingContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};
export default Pagination;
