import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Form,
  Input,
  Checkbox,
  Tag,
} from 'antd';
import {
  DeleteFilled,
  ExclamationCircleOutlined,
  SearchOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { get, uniq } from 'lodash';
import { getAllEventCodes } from 'redux-store/event/action';
import { blockUI, unblockUI } from 'redux-store/blocking-ui/action';
import constants from 'commons/constants';
import EventCodeManagementModal from './event-code-management-modal';
import { CSVExportButton, Pagination, CSVImport } from 'components';
import {
  deleteEventCodes,
  getAllEventCodesByCondition,
  addEventCodeFromCSV,
} from 'services';

const renderColumnTags = (productions = []) => (
  <>
    {productions.map((production) => (
      <Tag color="blue" key={production}>
        {production}
      </Tag>
    ))}
  </>
);

export default function EventCodeManagement() {
  const today = moment(new Date()).format(constants.DATE_FILE_FORMAT);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowKeys, setSelectedRows] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [fetchedExportData, setFetchedExportData] = useState(false);
  const [page, setPage] = useState(1);
  const editOrCreateModalRef = useRef();

  const {
    data: eventCodes = [],
    loading: getEvenCodeLoading,
    canLoadMore,
  } = useSelector((state) => get(state, 'event.eventCodes', {}));

  const exportCSV = async () => {
    const { eventOccurrenceConditions = '' } = form.getFieldsValue([
      'eventOccurrenceConditions',
    ]);

    if (eventCodes.length <= 0) return; // No data to export

    if (selectAll || (selectedRowKeys.length === 0 && eventCodes.length >= 0)) {
      // Export all
      setFetchedExportData(false);
      dispatch(blockUI());

      getAllEventCodesByCondition(eventOccurrenceConditions)
        .then((res = []) => {
          setExportData(res);
          setFetchedExportData(true);
        })
        .finally(() => dispatch(unblockUI()));
    }
  };

  useEffect(() => {
    if (eventCodes.length <= 0 && !canLoadMore) setSelectAll(false);

    const selected = selectedRowKeys;
    const uids = eventCodes.map((u) => u.id);

    if (selectAll) {
      const selectedRowKeys = uniq([...selected, ...uids]);
      setSelectedRows(selectedRowKeys);
    }
  }, [eventCodes]);

  /**
   * Show confirm delete modal
   */
  const confirmDelete = () => {
    Modal.confirm({
      centered: true,
      title: constants.MODAL.CONFIRM,
      icon: <ExclamationCircleOutlined />,
      content: 'イベントを削除してもよろしいですか？',
      okText: constants.YES,
      cancelText: constants.CANCEL,
      onOk: onDeleteEventCodes,
    });
  };

  const onDeleteEventCodes = () => {
    dispatch(blockUI());
    deleteEventCodes(selectedRowKeys, selectAll)
      .then(() => {
        toast.success('会社は削除されました。');
        form.submit();
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => dispatch(unblockUI()));
  };

  /**
   * Custom select all
   * @param {*} e
   */
  const onSelectAllChanged = (e) => {
    const checked = e.target.checked;
    const rows = eventCodes.map((u) => u.id);

    setSelectAll(checked);
    if (checked) {
      setSelectedRows(rows); // Set checked all current records
      setExportData([]); // Reset to export all data
    } else setSelectedRows([]);
  };

  /**
   * Config the row selection
   */
  const rowSelection = {
    selectedRowKeys,
    onChange: (rows) => {
      const exportSelectedEventCodes = eventCodes.filter((x) =>
        rows.includes(x.id)
      );
      setExportData(exportSelectedEventCodes);
      setSelectedRows(rows);
    },
    hideSelectAll: true,
    onSelect: (_, selected) => {
      if (!selected && selectAll) setSelectAll(false);
    },
    preserveSelectedRowKeys: true,
    columnTitle: <Checkbox checked={selectAll} onChange={onSelectAllChanged} />,
  };

  const getEventCodeData = (formValues) => {
    const { eventOccurrenceConditions = '' } = formValues;
    dispatch(getAllEventCodes({ page, eventOccurrenceConditions }));
  };

  useEffect(() => {
    form.submit();
  }, [page]);

  return (
    <div style={{ background: '#fff' }}>
      <div className="admin-container">
        <h1>イベントの設定</h1>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 0]}>
          {/* LEFT CONTAINER*/}
          <Col xs={24} md={24} sm={24} lg={4}>
            <div className="left-container">
              <div className="left-header">
                <Space>
                  <Button
                    onClick={() => editOrCreateModalRef.current.show()}
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    size={40}
                  />

                  <CSVImport
                    headerMappings={[
                      { key: 'イベントコード', label: 'eventCode' },
                      {
                        key: 'イベント発生条件',
                        label: 'eventOccurrenceConditions',
                      },
                      { key: 'メダル数', label: 'medalCount' },
                      { key: '追加メダル', label: 'additionalMedalCount' },
                      { key: 'イベント演出', label: 'eventProduction' },
                    ]}
                    onUpload={(data) => addEventCodeFromCSV(data)}
                    onUploadFinish={() => form.submit()}
                  />

                  {selectedRowKeys.length > 0 && (
                    <Button
                      type="primary"
                      shape="circle"
                      danger
                      icon={<DeleteFilled />}
                      size={40}
                      onClick={confirmDelete}
                    />
                  )}
                </Space>
              </div>
              <Form
                form={form}
                layout="vertical"
                style={{
                  marginTop: 15,
                }}
                name="s-form"
                onFinish={getEventCodeData}
              >
                <Form.Item name="eventOccurrenceConditions">
                  <Input placeholder="イベント発生条件" />
                </Form.Item>

                <Form.Item>
                  <div className="search-btn">
                    <Space direction="vertical" align="center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SearchOutlined />}
                        size={40}
                        onClick={() => setPage(1)}
                      >
                        検索する
                      </Button>

                      <CSVExportButton
                        data={exportData}
                        headers={[
                          { label: 'イベントコード', key: 'eventCode' },
                          {
                            label: 'イベント発生条件',
                            key: 'eventOccurrenceConditions',
                          },
                          { label: 'メダル数', key: 'medalCount' },
                          { label: '追加メダル', key: 'additionalMedalCount' },
                          { label: 'イベント演出', key: 'eventProduction' },
                        ]}
                        fileName={`EventCodes_${today}.csv`}
                        fetchCSVData={exportCSV}
                        fetched={fetchedExportData}
                      />
                    </Space>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Col>

          {/* RIGHT CONTAINER*/}
          <Col xs={24} md={24} sm={24} lg={20}>
            <Table
              rowKey="id"
              dataSource={eventCodes}
              rowSelection={rowSelection}
              className="table-sticky-header"
              pagination={false}
              onFinish={getEventCodeData}
              loading={getEvenCodeLoading}
            >
              <Table.Column
                title="イベントコード"
                dataIndex="eventCode"
                render={(eventCode, row) => (
                  <a onClick={() => editOrCreateModalRef.current.show(row)}>
                    <Space>
                      <span>{eventCode}</span>
                    </Space>
                  </a>
                )}
              />
              <Table.Column
                title="イベント発生条件"
                dataIndex="eventOccurrenceConditions"
              />
              <Table.Column title="メダル数" dataIndex="medalCount" />
              <Table.Column
                title="追加メダル"
                dataIndex="additionalMedalCount"
              />
              <Table.Column
                title="イベント演出"
                dataIndex="eventProduction"
                render={renderColumnTags}
              />
            </Table>

            <Pagination
              page={page}
              isBackDisabled={page === 1 || getEvenCodeLoading}
              onClickBack={() => setPage(page - 1)}
              isNextDisabled={!canLoadMore || getEvenCodeLoading}
              onClickNext={() => setPage(page + 1)}
            />
          </Col>
        </Row>
      </div>

      <EventCodeManagementModal
        ref={editOrCreateModalRef}
        onUpdated={() => form.submit()}
      />
    </div>
  );
}
