import * as Actions from '../actions';

import { getAllEventCodes as getAllEventCodesService  } from 'services';
import { get } from 'lodash';

export const getAllEventCodes = ({ page, eventOccurrenceConditions } = {}) => (
  dispatch,
  getState
) => {
  dispatch({ type: Actions.GET_EVENT_CODES });
  const state = getState();
  const lastDocs = get(state, 'event.eventCodes.lastDocs', []);

  const nextPage = page + 1;
  const lastDoc = lastDocs.find((item) => item.page === page).lastDoc;

  return getAllEventCodesService(eventOccurrenceConditions, lastDoc)
    .then(({ data, lastDoc, canLoadMore }) => {
      dispatch({
        type: Actions.GET_EVENT_CODES_SUCCESS,
        payload: {
          data,
          lastDocs: [
            ...lastDocs.filter((item) => item.page !== nextPage),
            { page: nextPage, lastDoc },
          ],
          canLoadMore,
          page,
        },
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: Actions.GET_EVENT_CODES_FAIL, error });
    });
};
