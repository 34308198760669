import * as Actions from '../actions';

import * as Services from 'services';
import { get, has } from 'lodash';

export const getGiveaway = ({ page, searchText }) => async (dispatch, getState) => {
  dispatch({ type: Actions.GET_GIVEAWAY_REQUEST });
  try {
    const state = getState();
    const lastDocs = get(state, 'giveaway.giveaway.result.lastDocs', []);
    const nextPage = page + 1;

    const cursor = lastDocs.find((item) => item.page === page).lastDoc;

    const result = await Services.getGiveaway({ searchText, lastDoc: cursor });
    const { data, lastDoc, canLoadMore } = result;

    dispatch({
      type: Actions.GET_GIVEAWAY_SUCCESS,
      payload: {
        data,
        lastDocs: [...lastDocs.filter((item) => item.page !== nextPage), { page: nextPage, lastDoc }],
        canLoadMore,
        page,
      },
    });
  } catch (error) {
    dispatch({ type: Actions.GET_GIVEAWAY_FAIL, error });
  }
};

export const saveGiveaway = (params) => async (dispatch, getState) => {
  dispatch({ type: Actions.SAVE_GIVEAWAY_REQUEST });
  try {
    await Services.saveGiveaway(params);

    dispatch({
      type: Actions.SAVE_GIVEAWAY_SUCCESS,
      payload: params
    });
  } catch (error) {
    dispatch({ type: Actions.SAVE_GIVEAWAY_FAIL, error });
  }
};

export const getGiveawayHistory = ({ page, searchText, companyCode }) => async (dispatch, getState) => {
  dispatch({ type: Actions.GET_GIVEAWAY_HISTORY_REQUEST });
  try {
    const state = getState();
    const lastDocs = get(state, 'giveaway.giveawayHistory.result.lastDocs');
    const nextPage = page + 1;

    const cursor = lastDocs.find((item) => item.page === page).lastDoc;

    const result = await Services.getGiveawayHistory({ searchText, lastDoc: cursor, companyCode });
    const { data, lastDoc, canLoadMore } = result;

    dispatch({
      type: Actions.GET_GIVEAWAY_HISTORY_SUCCESS,
      payload: {
        data,
        lastDocs: [...lastDocs.filter((item) => item.page !== nextPage), { page: nextPage, lastDoc }],
        canLoadMore,
        page,
      },
    });
  } catch (error) {
    dispatch({ type: Actions.GET_GIVEAWAY_HISTORY_FAIL, error });
  }
}

export const saveGiveawayHistory = (params) => async (dispatch, getState) => {
  dispatch({ type: Actions.SAVE_GIVEAWAY_HISTORY_REQUEST });
  try {
    await Services.saveGiveawayHistory(params);

    dispatch({
      type: Actions.SAVE_GIVEAWAY_HISTORY_SUCCESS,
      payload: params
    });
  } catch (error) {
    dispatch({ type: Actions.SAVE_GIVEAWAY_HISTORY_FAIL, error });
  }
};
