import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';
import { Modal, Form, Input, Select, Space, Avatar } from 'antd';
import useStateCallback from 'use-state-with-callback';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getUserDropdownList } from 'redux-store/users/action';
import { saveGroup } from 'redux-store/group/action';
import constants from 'commons/constants';
import { has, get, difference } from 'lodash';
import { ImageUpload } from 'components';

const GroupEditorModal = ({ onSaved }, ref) => {
  const [form] = Form.useForm();
  const imageUploadRef = useRef();
  const dispatch = useDispatch();
  const auth = useSelector((state) => get(state, 'auth.login.result', {}));
  const dlUsers = useSelector((state) =>
    get(state, 'user.dropdownList.data', [])
  );
  const [modalVisible, setModalVisible] = useStateCallback(false, (val) => {
    if (!val) {
      setGroup(null);
    }
  });

  const setFormValues = (group) => {
    if (group) {
      const { id, groupName, companyCode, usersGroup } = group;
      form.setFieldsValue({
        id,
        groupName,
        companyCode,
        usersGroup,
      });
    } else {
      form.resetFields();
    }
  };

  const [group, setGroup] = useStateCallback(null, setFormValues);
  const [requesting, setRequesting] = useState();
  const savingGroup = useSelector((state) => get(state, 'group.savingGroup'));
  const companyCode = get(group, 'companyCode');

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const hide = () => {
    setModalVisible(false);
    imageUploadRef.current.resetStates();
    form.resetFields();
  };

  const show = (group) => {
    setModalVisible(true);
    setGroup(group);
  };

  useEffect(() => {
    const { status } = savingGroup;
    if (status === 'success') {
      toast.success('グループを正常に保存します。');
    } else if (status === 'error') {
      toast.error('何かがうまくいかなかった。');
    }
  }, [savingGroup]);

  useEffect(() => {
    if (companyCode) dispatch(getUserDropdownList({ companyCode }));
  }, [companyCode]);

  const submitGroupForm = async () => {
    try {
      setRequesting(true);
      const values = form.getFieldsValue(['id', 'groupName', 'usersGroup']);
      let userNotificationSettings = get(group, 'userNotificationSettings', []);
      const removed = difference(
        get(group, 'usersGroup', []),
        get(values, 'usersGroup', [])
      );

      if (removed.length > 0) {
        removed.forEach((id) => {
          const index = userNotificationSettings.findIndex(
            (x) => x.userId === id
          );
          if (index > -1) userNotificationSettings.splice(index, 1);
        });
      }

      await form.validateFields();
      const groupIcon = await imageUploadRef.current.upload(
        constants.UPLOAD_PATH.GROUP
      );
      await dispatch(saveGroup({ ...values, groupIcon, userNotificationSettings }));

      if (onSaved) onSaved();
      hide();
    } catch (error) {
      toast.error('何かがうまくいかなかった。');
    } finally {
      setRequesting(false);
    }
  };

  return (
    <Modal
      title={'グループ情報を編集'}
      centered
      width={600}
      visible={modalVisible}
      forceRender={true}
      okText={constants.YES}
      cancelText={constants.CANCEL}
      onOk={submitGroupForm}
      okButtonProps={{
        disabled: requesting,
      }}
      cancelButtonProps={{
        disabled: requesting,
      }}
      // confirmLoading={confirmLoading}
      onCancel={hide}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        labelAlign="left"
      >
        <ImageUpload
          ref={imageUploadRef}
          url={group?.groupIcon}
          wraperStyle={{ borderRadius: 0 }}
        />
        <Form.Item name="companyCode" label="会社コード">
          <Input disabled={true} />
        </Form.Item>

        <Form.Item
          name="groupName"
          label="グループの名前"
          rules={[{ required: true, message: 'グループの名前は必須です。' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="usersGroup"
          label="ユーザーグループ"
          rules={[
            { required: true, message: 'ユーザーを選択してください。' },
            {
              validator: (_, v) => {
                if (v && v.length >= 3) return Promise.resolve();
                else
                  return Promise.reject(
                    'グループは3人以上のユーザーが必要です。'
                  );
              },
            },
          ]}
        >
          <Select
            placeholder="役割を選択する"
            style={{ width: '100%' }}
            mode="multiple"
          >
            {dlUsers.map((u) => (
              <Select.Option key={u.id}>
                <Space>
                  <Avatar src={u.avatar} size="small"></Avatar>
                  <span>
                    {u.displayUserName || '表示名なし'} - {u.email}
                  </span>
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default forwardRef(GroupEditorModal);
