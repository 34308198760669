import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import { Modal, Form, Input, InputNumber } from 'antd';
import useStateCallback from 'use-state-with-callback';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { saveGiveaway } from 'redux-store/giveaways/action';
import constants from 'commons/constants';
import { has, get } from 'lodash';
import { ImageUpload } from 'components';

const GiveawayEditorModal = ({ onSaved }, ref) => {
  const [form] = Form.useForm();
  const imageUploadRef = useRef();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useStateCallback(false, (val) => {
    if (!val) {
      setGiveaway(null);
    }
  });

  const setFormValues = (giveaway) => {
    if (giveaway) {
      const { id, text, explanatoryText, numberOfStock, price, displayOrder } = giveaway;
      form.setFieldsValue({
        id,
        text,
        explanatoryText,
        numberOfStock,
        displayOrder,
        price,
      });
    } else {
      form.resetFields();
    }
  };

  const [giveaway, setGiveaway] = useStateCallback(null, setFormValues);
  const [requesting, setRequesting] = useState();
  const savingGiveaway = useSelector((state) => get(state, 'giveaway.savingGiveaway'));

  const isEdit = has(giveaway, 'id');

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const hide = () => {
    setModalVisible(false);
    imageUploadRef.current.resetStates();
  };

  const show = (giveaway) => {
    setModalVisible(true);
    setGiveaway(giveaway);
  };

  useEffect(() => {
    const { status } = savingGiveaway;
    if(status === 'success') {
      toast.success('景品を正常に保存します。')
    } else if(status === 'error') {
      toast.error('何かがうまくいかなかった。')
    }
  }, [savingGiveaway])

  const submitGiveawayForm = async () => {
    try {
      setRequesting(true);
      const values = form.getFieldsValue(['id', 'text', 'explanatoryText', 'numberOfStock', 'price', 'displayOrder']);
      await form.validateFields();
      const image = await imageUploadRef.current.upload(constants.UPLOAD_PATH.GIVEAWAY);
      await dispatch(saveGiveaway({ ...values, image }));

      if (onSaved) onSaved(isEdit);
      hide();
    } catch (error) {
      toast.error('何かがうまくいかなかった。')
    } finally {
      setRequesting(false);
    }
  };

  return (
    <Modal
      title={isEdit ? '景品情報を編集' : '景品情報を作成する'}
      centered
      visible={modalVisible}
      forceRender={true}
      okText={constants.YES}
      cancelText={constants.CANCEL}
      onOk={submitGiveawayForm}
      okButtonProps={{
        disabled: requesting,
      }}
      cancelButtonProps={{
        disabled: requesting,
      }}
      // confirmLoading={confirmLoading}
      onCancel={hide}
    >
      <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} form={form} labelAlign="left">
        <ImageUpload
          ref={imageUploadRef}
          url={giveaway?.image}
          wraperStyle={{
            borderRadius: 0,
          }}
        />
        <Form.Item name="text" label="項目名" rules={[{ required: true, message: '項目名は必須です。' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="explanatoryText" label="解説" rules={[{ required: true, message: '解説は必須です。' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="numberOfStock" label="量" rules={[{ required: true, message: '量は必須です。' }]}>
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item name="price" label="価格" rules={[{ required: true, message: '価格名は必須です。' }]}>
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item name="displayOrder" label="数値順" rules={[{ required: true, message: '数値順は必須です。' }]}>
          <InputNumber min={1} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default forwardRef(GiveawayEditorModal);
