import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import { Modal, Form, Input } from 'antd';
import constants from 'commons/constants';
import { addOrUpdateEmocaImage } from 'services';
import { toast } from 'react-toastify';
import useStateCallback from 'use-state-with-callback';
import { ImageUpload } from 'components';
import { get } from 'lodash';

const EmocaImageModal = forwardRef((props, ref) => {
  const inputRef = useRef();
  const uploadImageRef = useRef();
  const uploadIconRef = useRef();
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  const { onUpdated } = props;
  const [visible, setVisible] = useStateCallback(false, (visible) => {
    if (!visible) setEmocaImageForm(null);
  });

  const [emocaImageData, setEmocaImageForm] = useStateCallback(
    null,
    (emocaImageData) => {
      const { id, image, emocaIcon, introductoryText } = emocaImageData || {};
      form.setFieldsValue({
        id,
        image,
        emocaIcon,
        introductoryText,
      });
    }
  );

  const [loading, setLoading] = useState(false);
  const isAddNew = get(emocaImageData, 'id', null) === null;

  const show = (emocaImage) => {
    setEmocaImageForm(emocaImage);
    setVisible(true);
  };

  const hide = () => {
    form.resetFields(); // reset form
    uploadImageRef.current.resetStates();
    uploadIconRef.current.resetStates();
    setVisible(false);
  };

  const onOK = async () => {
    const { id, introductoryText } = form.getFieldsValue([
      'image',
      'emocaIcon',
      'introductoryText',
      'id',
    ]);

    form
      .validateFields()
      .then(() =>
        Promise.all([
          uploadIconRef.current.upload(constants.UPLOAD_PATH.EMOCA_ICON),
          uploadImageRef.current.upload(constants.UPLOAD_PATH.EMOCA_IMAGE),
        ])
      )
      .then((urls) => {
        const [emocaIcon, image] = urls;
        setLoading(true);
        return addOrUpdateEmocaImage({
          id,
          image,
          emocaIcon,
          introductoryText,
        });
      })
      .then(() => {
        let msg = id
          ? '会社が無事に更新されました!'
          : '無事に会社が追加されました!';

        toast.success(msg);
        setLoading(false);
        onUpdated && onUpdated();
        hide();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
        hide();
      });
  };

  return (
    <div ref={inputRef}>
      <Modal
        forceRender={true}
        title={isAddNew ? 'エモカの画像を追加' : 'エモカ画像の編集'}
        centered
        visible={visible}
        okText={constants.YES}
        cancelText={constants.CANCEL}
        okButtonProps={{
          form: 'emoca-form',
          key: 'submit',
          htmlType: 'submit',
        }}
        confirmLoading={loading}
        onCancel={hide}
      >
        <Form
          form={form}
          name="emoca-form"
          onFinish={onOK}
          labelCol={{ span: 7 }}
          labelAlign="left"
        >
          <Form.Item name="emocaIcon" label="エモカアイコン">
            <ImageUpload
              ref={uploadIconRef}
              url={emocaImageData?.emocaIcon}
              imageStyle={{ width: '100%', height: '100%', objectFit: 'cover' }}
              wraperStyle={{
                width: '64px',
                height: '64px',
                margin: 0,
              }}
            />
          </Form.Item>

          <Form.Item name="emocaImage" label="イメージ">
            <ImageUpload
              ref={uploadImageRef}
              url={emocaImageData?.image}
              imageStyle={{ width: '100%', height: 'auto', objectFit: 'cover' }}
              wraperStyle={{
                borderRadius: 0,
                width: '100%',
                height: 'auto',
                margin: 0,
              }}
            />
          </Form.Item>

          <Form.Item
            name="introductoryText"
            label="表示テキスト"
            rules={[{ required: true, message: '表示テキストが必要です。' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});

export default EmocaImageModal;
