import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Form,
  Input,
  Empty,
  Checkbox,
} from 'antd';
import {
  DeleteFilled,
  SearchOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { get, uniq } from 'lodash';
import { CSVExportButton, Pagination, CSVImport } from 'components';
import { getNavisapos } from 'redux-store/navisapo/action';
import { blockUI, unblockUI } from 'redux-store/blocking-ui/action';
import constants from 'commons/constants';
import NavisapoAddOrEditModal from './NavisapoAddOrEditModal';
import {
  deleteNavisapo,
  getAllNavisapoByCondition,
  addNavisapoFromCSV,
} from 'services';
import { toast } from 'react-toastify';
import moment from 'moment';
import './style.scss';

function NavisapoManagement() {
  const today = moment(new Date()).format(constants.DATE_FILE_FORMAT);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const editModalRef = useRef();

  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowKeys, setSelectedRows] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [fetchedExportData, setFetchedExportData] = useState(false);
  const [page, setPage] = useState(1);

  const {
    data: navisapoData = [],
    loading: getNavisapoLoading,
    canLoadMore,
  } = useSelector((state) => get(state, 'navisapo.navisapos', {}));

  /**
   * Get users
   * @param {*} formValues
   */
  const getNavisapoData = (formValues) => {
    const { term = '' } = formValues;
    dispatch(getNavisapos({ page, name: term.trim() }));
  };

  useEffect(() => {
    form.submit();
  }, [page]);

  useEffect(() => {
    if (navisapoData.length <= 0 && !canLoadMore) setSelectAll(false);

    const selected = selectedRowKeys;
    const uids = navisapoData.map((u) => u.id);

    if (selectAll) {
      const selectedRowKeys = uniq([...selected, ...uids]);
      setSelectedRows(selectedRowKeys);
    }
    setSelectedRows([]);
  }, [navisapoData]);

  const onDeleteNavisapo = () => {
    dispatch(blockUI());
    deleteNavisapo(selectedRowKeys, selectAll)
      .then(() => {
        toast.success('設定を削除しました。');
        form.submit();
      })
      .catch((err) => {
        toast.error(err);
        console.log(err);
      })
      .finally(() => dispatch(unblockUI()));
  };

  const confirmDelete = () =>
    Modal.confirm({
      centered: true,
      title: constants.MODAL.CONFIRM,
      icon: <ExclamationCircleOutlined />,
      content: 'ナビサポを削除してもよろしいですか？',
      okText: constants.YES,
      cancelText: constants.CANCEL,
      onOk: onDeleteNavisapo,
    });

  const onSelectAllChanged = (e) => {
    const checked = e.target.checked;
    const rows = navisapoData.map((u) => u.id);

    setSelectAll(checked);
    if (checked) {
      setSelectedRows(rows); // Set checked all current records
      setExportData([]); // Reset to export all data
    } else setSelectedRows([]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (rows) => {
      const exportSelectedNavisapos = navisapoData.filter((x) =>
        rows.includes(x.id)
      );
      setExportData(exportSelectedNavisapos);
      setSelectedRows(rows);
    },
    hideSelectAll: true,
    onSelect: (_, selected) => {
      if (!selected && selectAll) setSelectAll(false);
    },
    preserveSelectedRowKeys: true,
    columnTitle: <Checkbox checked={selectAll} onChange={onSelectAllChanged} />,
  };

  const exportCSV = async () => {
    const { term = '' } = form.getFieldsValue(['term']);
    let name;
    name = term.trim();
    if (navisapoData.length <= 0) return; // No data to export

    if (
      selectAll ||
      (selectedRowKeys.length === 0 && navisapoData.length >= 0)
    ) {
      // Export all
      setFetchedExportData(false);
      dispatch(blockUI());
      getAllNavisapoByCondition({ name })
        .then((res) => {
          setExportData(res);
          setFetchedExportData(true);
        })
        .finally(() => dispatch(unblockUI()));
    }
  };

  return (
    <div style={{ background: '#FFF' }}>
      <div className="admin-container">
        <h1>ナビサポの設定</h1>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 16 }, 0]}>
          <Col xs={24} md={24} sm={24} lg={4}>
            <div className="left-container">
              <div className="left-header">
                <Space>
                  <Button
                    onClick={() => editModalRef.current.show()}
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    size={40}
                  />

                  <CSVImport
                    headerMappings={[
                      { key: '名前', label: 'name' },
                      { key: '画像', label: 'image' },
                    ]}
                    onUpload={(data) => addNavisapoFromCSV(data)}
                    onUploadFinish={() => form.submit()}
                  />

                  {selectedRowKeys.length > 0 && (
                    <Button
                      type="primary"
                      shape="circle"
                      danger
                      icon={<DeleteFilled />}
                      size={40}
                      onClick={confirmDelete}
                    />
                  )}
                </Space>
              </div>
              <Form
                form={form}
                layout="vertical"
                style={{
                  marginTop: 15,
                }}
                name="s-form"
                onFinish={getNavisapoData}
              >
                <Form.Item name="term">
                  <Input placeholder="名前" />
                </Form.Item>

                <Form.Item>
                  <div className="search-btn">
                    <Space direction="vertical" align="center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SearchOutlined />}
                        size={40}
                        onClick={() => setPage(1)}
                      >
                        検索する
                      </Button>
                      <CSVExportButton
                        data={exportData}
                        headers={[
                          { label: 'ID', key: 'id' },
                          { label: '名前', key: 'name' },
                          { label: '画像', key: 'image' },
                        ]}
                        fileName={`Navisapo_${today}.csv`}
                        fetchCSVData={exportCSV}
                        fetched={fetchedExportData}
                      />
                    </Space>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Col>

          <Col xs={24} md={24} sm={24} lg={20}>
            <Table
              rowKey="id"
              className="table-sticky-header"
              rowSelection={rowSelection}
              dataSource={navisapoData}
              pagination={false}
              loading={getNavisapoLoading}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="データなし"
                  />
                ),
              }}
            >
              <Table.Column
                title="名前"
                key="name"
                dataIndex="name"
                render={(name, row) => (
                  <a onClick={() => editModalRef.current.show(row)}>
                    <Space>
                      <span>{name}</span>
                    </Space>
                  </a>
                )}
              />
              <Table.Column
                title="画像"
                dataIndex="image"
                key="image"
                render={(image, row) => (
                  <a>
                    <Space>
                      <img src={row.image} className="item-img" alt="" />
                    </Space>
                  </a>
                )}
              />
            </Table>
            <Pagination
              page={page}
              isBackDisabled={page === 1 || getNavisapoLoading}
              onClickBack={() => setPage(page - 1)}
              isNextDisabled={!canLoadMore || getNavisapoLoading}
              onClickNext={() => setPage(page + 1)}
            />
          </Col>
        </Row>
      </div>

      <NavisapoAddOrEditModal
        ref={editModalRef}
        onUpdated={() => form.submit()}
      />
    </div>
  );
}

export default NavisapoManagement;
